<template lang="pug">
.hailingStatus
  PathDirectionsMap.hailingStatus__map(
    :pickUp="pickUpLocation"
    :dropOff="dropOffLocation"
    :showLastPageBtn="false"
    :showCenterMapBtn="false"
    :pathDirections="pathDirections"
  )
  HailingStatusModal.hailingStatus__modal(
    :driverImg="driverImg"
    :driverStar="driverStar"
    :driverPhone="driverPhone"
    :driverNote="driverNote"
    :carLicense="carLicense"
    :carBrand="carBrand"
    :callNumber="callNumber"
    :teamName="teamName"
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :taxiType="taxiType"
    :payment="payment"
    :price="price === '' ? '----': price"
    :extraServices="extraServices"
    :isSearching="isSearching"
    :isHailingSuccess="isHailingSuccess"
    :note="customerNote"
    :estimatedTime="estimatedTime"
    :repeated="repeated"
    @callTaxiDriver="callTaxiDriver"
    @hailingAgain="hailingAgainHandler"
    @cancelHailing="cancelHailingHandler"
    @cancelNextPage="cancelNextPage"
    @successConfirmed="successConfirmeHandler"
    @confirmRepeated="confirmRepeated"
    @cancelRepeated="cancelRepeated"
  )
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PathDirectionsMap from "@/components/common/PathDirectionsMap";
import HailingStatusModal from "@/components/HailingStatusModal";
import { carFilterList } from "@/utils/CarFilterUtils";
import {
  postNewHailing,
  getCancelBefore,
  getHailingDriverInfo
} from "@/api/hailingService";

export default {
  computed: {
    ...mapGetters({
      passenger: "hailingInfo/getPassenger",
      customerNote: "hailingInfo/getCustomerNote",
      pickUpLocation: "hailingInfo/getPickUpLocation",
      dropOffLocation: "hailingInfo/getDropOffLocation",
      pickUpName: "hailingInfo/getPickUpName",
      dropOffName: "hailingInfo/getDropOffName",
      carType: "hailingInfo/getCarType",
      taxiList: "hailingInfo/getTaxiList",
      payment: "hailingInfo/getPayment",
      extraServices: "hailingInfo/getExtraServices",
      pathDirections: "hailingInfo/getPathDirections",
      isSearching: "hailingInfo/getIsSearching",
      isHailingSuccess: "hailingInfo/getIsHailingSuccess",
      repeated: "hailingInfo/getRepeated",
      repeatedMsg: "hailingInfo/getRepeatedMsg",
      driverImg: "orderInfo/getDriverImg",
      driverStar: "orderInfo/getDriverStar",
      driverPhone: "orderInfo/getDriverPhone",
      driverNote: "orderInfo/getDriverNote",
      carLicense: "orderInfo/getCarLicense",
      carBrand: "orderInfo/getCarBrand",
      callNumber: "orderInfo/getCallNumber",
      teamName: "orderInfo/getTeamName",
      customerNote: "hailingInfo/getCustomerNote",
      estimatedTime: "orderInfo/getEstimatedTime",
      testCarTeam: "hailingInfo/getTestCarTeam"
    }),
    taxiType() {
      const { carType } = carFilterList()[this.carType];
      return carType;
    },
    price() {
      if (this.taxiList.length === 0) return "";
      const { estimate } = this.taxiList[this.carType];
      return estimate;
    }
  },
  methods: {
    cancelNextPage() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選取消回上一頁"
      });
      this.$router.back();
    },
    ...mapActions({
      setIsSearching: "hailingInfo/setIsSearching",
      setIsHailingSuccess: "hailingInfo/setIsHailingSuccess",
      setRepeated: "hailingInfo/setRepeated",
      setRepeatedMsg: "hailingInfo/setRepeatedMsg",
      setOrderID: "orderInfo/setOrderID",
      updateOrderInfo: "orderInfo/updateOrderInfo"
    }),
    toLastPage() {
      this.$router.back();
    },
    hailingAgainHandler() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選再派一次"
      });
      this.immediateHailing();
    },
    callTaxiDriver() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選打司機電話"
      });
      window.location.href = `tel:${this.driverPhone}`;
    },
    async cancelHailingHandler() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選取消叫車"
      });
      try {
        await getCancelBefore();
        this.toLastPage();
        this.setIsSearching(false);
        this.setIsHailingSuccess(false);
        this.setRepeated(0);
      } catch (err) {
        console.error(err);
      }
    },
    successConfirmeHandler() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選派車成功確認"
      });
      this.$router.replace("/TaxiStatus");
    },
    confirmRepeated() {
      this.immediateHailing();
    },
    cancelRepeated() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選取消叫車"
      });
      try {
        this.toLastPage();
        this.setIsSearching(false);
        this.setIsHailingSuccess(false);
        this.setRepeated(0);
      } catch (err) {
        console.error(err);
      }
    },
    async immediateHailing() {
      const { seatNumber, taxiSort } = carFilterList()[this.carType];
      const hailingInfo = {
        taxiSort: taxiSort,
        seatNumber: seatNumber,
        onLocation: this.pickUpName,
        onLocationLng: this.pickUpLocation.lng(),
        onLocationLat: this.pickUpLocation.lat(),
        passenNum: this.passenger,
        passenComment: "LINE-叫車|" + this.customerNote,
        payType: this.payment,
        serviceList: this.extraServices.join(","),
        repeated: this.repeated
      };
      const hailingConfig =
        this.dropOffName === ""
          ? { ...hailingInfo }
          : {
              ...hailingInfo,
              offLocation: this.dropOffName,
              offLocationLng: this.dropOffLocation.lng(),
              offLocationLat: this.dropOffLocation.lat()
            };

      try {
        this.setIsSearching(true);
        const { data } = await postNewHailing(hailingConfig);
        if (data.success) {
          this.setRepeated(0);
          if (data.result === "無車輛可派") {
            this.setIsHailingSuccess(false);
          } else {
            this.hailingSuccessHandler(data.result.orderID);
          }
        } else {
          /* console.log("HailingStatus data.success false"); */
          /* 僅針對大豐車隊開放重複叫車提示功能 */
          if (
            (document.URL.indexOf("dihon.line.gladmobile.com") > -1 ||
              this.testCarTeam === "dihon") &&
            (data.message.includes("尋找司機中，是否要再叫一輛") ||
              data.message.includes("已有司機接單，是否要再叫一輛"))
          ) {
            /* console.log("HailingStatus repeated message", data.message); */
            this.setRepeatedMsg(data.message);
            this.setRepeated(1);
          } else {
            this.setRepeated(0);
            this.setIsHailingSuccess(false);
            this.setRepeated("block-4");
          }
        }
      } catch (err) {
        console.error(err);
        this.setIsHailingSuccess(false);
      } finally {
        this.setIsSearching(false);
      }
    },
    async hailingSuccessHandler(orderID) {
      this.setOrderID(orderID);
      try {
        const { data } = await getHailingDriverInfo(orderID, "instant");
        const { result } = data;
        this.updateOrderInfo({ ...result });
        this.setIsSearching(false);
        this.setIsHailingSuccess(true);
      } catch (err) {
        console.error(err);
      }
    }
  },
  components: {
    PathDirectionsMap,
    HailingStatusModal
  }
};
</script>
<style lang="scss" scoped>
.hailingStatus {
  position: relative;
  height: 100vh;

  &__map {
    width: 100vw;
    height: 100vh;
  }

  &__modal {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
