<template lang="pug">
.taxiStatus
  TaxiStatusMap.taxiStatus(
    :task="isCancel"
    :pickUp="pickUpLocation"
    :pickOff="dropOffLocation"
    :taxiLocation="driverGPS"
    @openMenu="$emit('openMenu')"
  )
  ItineraryTable.taxiStatus__itineraryTable(
    v-if="showItinerary"
    :orderTime="orderTime"
    :rideStatus="rideStatus"
    :passengerName="passengerName"
    :onLocationName="onLocationName"
    :offLocationName="offLocationName"
    :driverName="driverName"
    :teamName="teamName"
    :teamPhone="teamPhone"
    :callNumber="callNumber"
    :driverPhone="driverPhone"
    :driverNote="driverNote"
    :carBrand="carBrand"
    :carLicense="carLicense"
    :isSerivce="isShowJoinMember"
    @closeItinerary="closeItineraryTable"
    @callTaxiTeam="callTaxiTeam"
    @callTaxiDriver="callTaxiDriver"
  )
  template(v-else)
    .taxiStatus__banner(
      @click="openItineraryTable"
    )
      p.taxiStatus__subtitle 行程狀態
      dropDownIcon.taxiStatus__icon
    a.taxiStatus__shareButton(
      :href="href"
    )
      shareLocationIcon
    a.taxiStatus__joinButton(
      v-if="isShowJoinMember"
      @click="toJoinMemberPage"
    )
      joinIcon
    //- ButtonChat.taxiStatus__chat__button(
    //-   v-if="allowChat"
    //-   @click="toChatPage"
    //- )
    ButtonPhoneCall.taxiStatus__phone__button(
      @click="callTaxiDriver"
    )
    .taxiStatus__panelWrapper
      TaxiInfoPanel(
        :driverImg="driverImg"
        :driverStar="driverStar"
        :driverNote="driverNote"
        :carLicense="carLicense"
        :carBrand="carBrand"
        :callNumber="callNumber"
        :teamName="teamName"
      )
      p.taxiStatus__text.taxiStatus__text--cancel(
        v-if="isCancel"
        @click="showCancelModal"
      ) 取消叫車
    //- v-if="showText"
  BaseModal(
    v-if="false"
    :title="'看不到訂單!'"
    :text="'1. 預約時間前20分鐘    2.訂單時間後兩個小時內即可開放，其他時間無開放查看，請在右上角打Ｘ關閉'"
  )
  BaseModal(
    v-if="isShowCanceSuccess"
    :title="'訊息'"
    :text="txtCancel"
    @confirm="navigateMain"
  )
  BaseConfirmModal(
    v-if="isShowCancel"
    :title="'確定要取消行程嗎'"
    :confirmBtnText="'確定'"
    :cancelBtnText="'取消'"
    @cancel="hideCancelModal"
    @confirm="cancelHalingHandler"
  )
  //- BaseConfirmModal.baseConfirmModal(
  //-   v-if="isShowJoinMember"
  //-   :title="'加入會員'"
  //-   :confirmBtnText="'加入會員'"
  //-   :cancelBtnText="'關閉'"
  //-   @cancel="hideJoinMemberModal"
  //-   @confirm="toJoinMemberPage"
  //- )
  //-   .baseConfirmModal__content(slot="content")
  //-     p 我們現在有提供 Line 自動叫車！
  //-     p 按以下按鈕加入會員
</template>
<script>
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';

import { mapGetters, mapActions } from 'vuex';
import TaxiStatusMap from '@/components/common/TaxiStatusMap';
import ItineraryTable from '@/components/ItineraryTable';
import ButtonPhoneCall from '@/components/common/ButtonPhoneCall';
import ButtonChat from '@/components/common/ButtonChat';
import TaxiInfoPanel from '@/components/common/TaxiInfoPanel';
import { getCancelAfter, getHailingDriverLocation, getHailingDriverInfo } from '@/api/hailingService';
import BaseModal from '@/components/BaseComponent/BaseModal';
import { use_url, line_channel_url } from '../api/default';
import * as jsCookie from 'js-cookie';

import shareLocationIcon from '@/assets/images/share_btn_yellow.svg';
import joinIcon from '@/assets/images/line_join.svg';

import dropDownIcon from '@/assets/images/arrow-drop-down-24-px.svg';

export default {
  data() {
    return {
      showItinerary: false,
      driverGPSTimeout: null,
      delay: 15000,
      isCancel: false,
      allowChat: false,
      href: null,
      showText: false,
      isShowCancel: false,
      isShowCanceSuccess:false,
      isShowJoinMember: false,
      txtCancel: ''
    };
  },
  computed: {
    ...mapGetters({
      pickUpLocation: 'hailingInfo/getPickUpLocation',
      dropOffLocation: 'hailingInfo/getDropOffLocation',
      orderID: 'orderInfo/getOrderID',
      orderTime: 'orderInfo/getOrderTime',
      rideStatus: 'orderInfo/getRideStatus',
      passengerName: 'orderInfo/getPassengerName',
      onLocationName: 'orderInfo/getOnLocation',
      offLocationName: 'orderInfo/getOffLocation',
      driverGPS: 'orderInfo/getDriverGPS',
      driverName: 'orderInfo/getDriverName',
      driverImg: 'orderInfo/getDriverImg',
      driverStar: 'orderInfo/getDriverStar',
      driverPhone: 'orderInfo/getDriverPhone',
      driverNote: 'orderInfo/getDriverNote',
      carLicense: 'orderInfo/getCarLicense',
      carBrand: 'orderInfo/getCarBrand',
      callNumber: 'orderInfo/getCallNumber',
      teamName: 'orderInfo/getTeamName',
      teamPhone: 'orderInfo/getTeamPhone',
    }),
  },
  methods: {
    ...mapActions({
      setDriverGPS: 'orderInfo/setDriverGPS',
      updateOrderInfo: 'orderInfo/updateOrderInfo',
      setHailingPickUpLocation: 'hailingInfo/setHailingPickUpLocation',
      setHailingDropOffLocation: 'hailingInfo/setHailingDropOffLocation',
      setOrderID: 'orderInfo/setOrderID'
    }),
    toHomePage() {
      this.$router.replace('/');
    },
    toChatPage() {
      this.$router.push('Chat');
    },
    callTaxiDriver() {
      window.location.href=`tel:${this.driverPhone}`;
    },
    callTaxiTeam() {
      window.location.href=`tel:${this.teamPhone}`;
    },
    closeItineraryTable() {
      this.showItinerary = false;
    },
    openItineraryTable() {
      this.showItinerary = true;
    },
    showCancelModal(){
      this.isShowCancel = true
    },
    hideCancelModal(){
      this.isShowCancel = false
    },
    showCanceMessageModal(){
      this.isShowCanceSuccess = true
    },
    hideCanceMessageModal(){
      this.isShowCanceSuccess = false
    },
    async cancelHalingHandler() {
      try {
        await getCancelAfter(this.orderID);
        clearTimeout(this.driverGPSTimeout);
        this.hideCancelModal()
        this.txtCancel = '訂單取消成功'
        this.showCanceMessageModal()
      } catch (err) {
        this.txtCancel = '訂單取消失敗，請再試一次'
        this.showCanceMessageModal()
        console.error(err);
      }
    },
    navigateMain(){
      this.toHomePage();
    },
    async fetchDriverGPS(orderID, task) {
      try {
        const { data } = await getHailingDriverLocation(orderID, task);
        const { latitude, longitude } = data.result;
        this.setDriverGPS({
          lat: latitude,
          lng: longitude,
        });
      } catch (err) {
        console.error(err);
      };

      // this.driverGPSTimeout = setTimeout(() => {
      //   this.fetchDriverGPS(orderID, task);
      // }, this.delay);
    },
    async hailingSuccessHandler(orderID, task) {
      try {
        this.setOrderID(orderID);
        const { data } = await getHailingDriverInfo(orderID, task);
        const { result } = data;
        // 判斷有沒有 token（從 line bot 或正常叫車流程進來的就有）
        // 判斷參數若是從正常叫車流程進入就顯示
        if(jsCookie.get("token") !== undefined && this.$route.query.task !== 'reservation' && !(document.URL.indexOf('other_entry') > -1)){
          console.log(Boolean, jsCookie.get("token") !== undefined&& this.$route.query.task !== 'reservation' && !this.$route.query.other_entry);
          // console.log('token', jsCookie.get("token"));
          this.isCancel = true;
          this.allowChat = true;
        }
        if(data.result.passengerGetInGps !== undefined){
          let onGPS = new google.maps.LatLng(data.result.passengerGetInGps.coordinates[1],data.result.passengerGetInGps.coordinates[0])
          this.setHailingPickUpLocation(onGPS)
        }
        if(data.result.passengerGetOffGps !== undefined){
          let offGPS = new google.maps.LatLng(data.result.passengerGetOffGps.coordinates[1],data.result.passengerGetOffGps.coordinates[0])
            this.setHailingDropOffLocation(offGPS)
        }
        this.updateOrderInfo({ ...result });
      } catch (err) {
        console.error('hailingSuccessHandler', err);
        this.showText = true
        this.isCancel = false;
        this.allowChat = false;
      }
    },
    hideJoinMemberModal() {
      this.isShowJoinMember = false;
    },
    toJoinMemberPage() {
      window.location.href = line_channel_url
      window.open()
    }
  },
  components: {
    TaxiStatusMap,
    ItineraryTable,
    ButtonPhoneCall,
    ButtonChat,
    TaxiInfoPanel,
    BaseModal,
    BaseConfirmModal,
    shareLocationIcon,
    joinIcon,
    dropDownIcon
  },
  created(){
    // 之後需要讓非會員的人使用才會有this.$route.query.id
    if(this.$route.query.id !== undefined){
      console.log('非會員查看');
      let orderID = this.$route.query.id;
      console.log('orderID', orderID);

      if(this.$route.query.task === 'reservation'){
        console.log('非會員查看預約', orderID);
        this.isCancel = false;
        this.allowChat = false;
        this.href =`http://line.naver.jp/R/msg/text/?分享位置給朋友: ${use_url}/#/TaxiStatus/?id=${orderID}&task=reservation&other_entry=true`
        this.hailingSuccessHandler(orderID, 'reservation')
        this.fetchDriverGPS(orderID, 'reservation');
        return
      }

      console.log('非會員查看立即', orderID);
      this.href =`http://line.naver.jp/R/msg/text/?分享位置給朋友: ${use_url}/#/TaxiStatus/?id=${orderID}&task=instant&other_entry=true`
      this.hailingSuccessHandler(orderID, 'instant')
      this.fetchDriverGPS(orderID, 'instant');
      return
    }

    // 會員預約叫車
    if(this.$route.query.task === 'reservation'){
      this.isCancel = false;
      this.allowChat = false;
      this.hailingSuccessHandler(this.$route.query.id, 'reservation')
      this.fetchDriverGPS(this.$route.query.id, 'reservation');
      this.href =`http://line.naver.jp/R/msg/text/?分享位置給朋友: ${use_url}/#/TaxiStatus/?id=${this.$route.query.id}&task=reservation&other_entry=true`
      return
    }

    // 會員立即叫車
    this.href =`http://line.naver.jp/R/msg/text/?分享位置給朋友: ${use_url}/#/TaxiStatus/?id=${this.orderID}&task=instant&other_entry=true`
    this.hailingSuccessHandler(this.orderID, 'instant')
    this.fetchDriverGPS(this.orderID, 'instant');

  },
  mounted() {
    // 判斷是否跳出加入會員 Modal
    if(document.URL.indexOf('open_join_member') > -1) {
      console.log('this.isShowJoinMember',this.isShowJoinMember);
      this.isShowJoinMember = true;
    }
  },
  beforeDestroy() {
    clearTimeout(this.driverGPSTimeout);
  },
};
</script>
<style lang="scss" scoped>
  .taxiStatus {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__map {
      width: 100vw;
      height: 100vh;
    }

    &__itineraryTable {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
    }

    &__banner {
      position: absolute;
      top: 30px;
      right: 10px;
      padding: 0 8px 0 16px;
      height: 40px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0 0 5px #c1c1c1;
      display: flex;
      align-items: center;
    }

    &__subtitle {
      margin: 0;
      font-size: 14px;
    }

    &__shareButton {
      position: absolute;
      top: 80px;
      right: 10px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0);
    }

    &__joinButton {
      position: absolute;
      top: 30px;
      left: 50px;
      width: 150px;
      height: 70px;
      border-radius: 80%;
      background-color: rgba(0, 0, 0, 0);
    }

    &__icon {
      margin-left: 20px;
    }

    &__chat__button {
      position: absolute;
      right: 10px;
      bottom: 280px;
    }

    &__phone__button {
      position: absolute;
      right: 10px;
      bottom: 210px;
    }

    &__panelWrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 4px;
      padding-bottom: 8px;
      box-sizing: border-box;
      background-color: #ffffff;
      border-radius: 20px 20px 0 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
    }

    &__text {
      margin: 0;
      font-size: 12px;
      height: 16px;
      line-height: 16px;

      &--cancel {
        color: #a62900;
        margin-left: 92px;
      }
    }
  }
  .baseConfirmModal__content {
    text-align: center;
    margin-bottom: 40px;
    p {
      margin: 0;
    }
  }
</style>
