import moment from 'moment';
import 'moment-lunar';

export const festivalObj = now =>  {

  const result = {festivalOneDay:[], festivalInterval: []}

  // present time
  const year = Number(now.getFullYear())

  // festival => only one day
  const festivalOneDay = [ 
    {name:'valentine', date: '1 14'}, // 2.14 
  ]

  result.festivalOneDay = festivalOneDay

  // festival => interval
  // valentine last 1 and that day
  const valentineToSolar = moment().year(year).month(7 - 1).date(7).solar().format('MM DD') 
  const valentine = new Date()
  valentine.setMonth(Number(valentineToSolar.split(' ')[0]) - 1)
  valentine.setDate(Number(valentineToSolar.split(' ')[1]))
  const valentineInterval = setTimeInterval(valentine, 'backward', 1)

  result.festivalInterval.push({name:'valentine', date:valentineInterval})

  // console.log(`valentine : ${valentineInterval.start} ${valentineInterval.end}`)

  // dragon last 6 and that day
  const dragonBoatFestivalToSolar = moment().year(year).month(5 - 1).date(5).solar().format('MM DD') 
  const dragonBoatFestival = new Date()
  dragonBoatFestival.setMonth(Number(dragonBoatFestivalToSolar.split(' ')[0]) - 1)
  dragonBoatFestival.setDate(Number(dragonBoatFestivalToSolar.split(' ')[1]))
  const dragonBoatFestivalInterval = setTimeInterval(dragonBoatFestival, 'backward', 6)

  result.festivalInterval.push({name:'dragonBoatFestival', date:dragonBoatFestivalInterval})

  // console.log(`dragon boat festival : ${dragonBoatFestivalInterval.start} ${dragonBoatFestivalInterval.end}`)

  // moon last 6 and that day
  const moonFestivalToSolar = moment().year(year).month(8 - 1).date(15).solar().format('MM DD') 
  const moonFestival = new Date()
  moonFestival.setMonth(Number(moonFestivalToSolar.split(' ')[0]) - 1)
  moonFestival.setDate(Number(moonFestivalToSolar.split(' ')[1]))
  const moonFestivalInterval = setTimeInterval(moonFestival, 'backward', 6)

  result.festivalInterval.push({name:'moonFestival', date:moonFestivalInterval})

  // console.log(`moon festival : ${moonFestivalInterval.start} ${moonFestivalInterval.end}`)

  // christmas last 1 and that day
  const christmas = new Date()
  christmas.setMonth(11)
  christmas.setDate(25)
  const christmasInterval = setTimeInterval(christmas, 'backward', 1)

  result.festivalInterval.push({name:'christmas', date:christmasInterval})

  // console.log(`christmas : ${christmasInterval.start} ${christmasInterval.end}`)

  // spring 1~5 total 5 days
  const springToSolar = moment().year(year).month(1 - 1).date(1).solar().format('MM DD')
  const spring = new Date()
  spring.setMonth(springToSolar.split(' ')[0] - 1)
  spring.setDate(springToSolar.split(' ')[1])
  const springInterval = setTimeInterval(spring, 'forward', 4)

  result.festivalInterval.push({name:'spring', date:springInterval})

  // console.log(`spring ${springInterval.start} ${springInterval.end}`)

  // new year eve last 4 and that day
  // definition of the new year eve is the previos day of the spring
  const newYearEveToSolar = moment().year(year).month(1 - 1).date(1).solar().format('MM DD')
  const newYearEve= new Date()
  newYearEve.setMonth(newYearEveToSolar.split(' ')[0] - 1)
  newYearEve.setDate(newYearEveToSolar.split(' ')[1])
  newYearEve.setDate(newYearEve.getDate() - 1)  
  const newYearEveInterval = setTimeInterval(newYearEve, 'backward', 4)

  result.festivalInterval.push({name:'newYearEve', date:newYearEveInterval})

  // console.log(`new year eve ${newYearEveInterval.start} ${newYearEveInterval.end}`)

  return result
}

const setTimeInterval = (date, direction, number) => {
  const interval = {start:null,end:null}
  if(direction === 'backward'){

    interval.end = `${date.getMonth()} ${date.getDate()}`
    date.setDate(date.getDate() - number)
    interval.start = `${date.getMonth()} ${date.getDate()}` 

  } else if(direction === 'forward') {

    interval.start =`${date.getMonth()} ${date.getDate()}`
    date.setDate(date.getDate() + number)
    interval.end = `${date.getMonth()} ${date.getDate()}`

  }

  return interval

}
