<template lang="pug">
.historyDetailTable
  .historyDetailTable__content
    .historyDetailTable__sectionWrapper
      SectionTop(
        :time="tripRecord.orderTime"
        :onLocation="tripRecord.onLocation"
        :offLocation="tripRecord.offLocation"
      )
    .historyDetailTable__sectionWrapper
      SectionBottom(
        :isDetail="true"
        :actualPrice="tripRecord.price"
      )
    .historyDetailTable__sectionWrapper
      SectionDriver(
        :driverInfo="tripRecord.driverInfo"
        :teamInfo="tripRecord.teamInfo"
      ).historyDetailTable__section--driver
</template>
<script>
import SectionTop from '@/components/HailingRecordList/HailingRecordListItem/SectionTop';
import SectionBottom from '@/components/HailingRecordList/HailingRecordListItem/SectionBottom';
import SectionDriver from '@/components/HailingRecordList/HailingRecordListItem/SectionDriver';

export default {
  props: {
    tripRecord: {
      type: Object
    }
  },
  components: {
    SectionTop,
    SectionBottom,
    SectionDriver,
  },
};
</script>
<style lang="scss" scoped>
  .historyDetailTable {
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;

    &__content {
      border-radius: 20px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &__sectionWrapper {
      &:not(:first-child) {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: 2px 0 2px rgba(0, 0, 0, 0.16)
        }

        &::after {
          content: '';
          position: absolute;
          top: -10px;
          right: -10px;
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: -2px 0 2px rgba(0, 0, 0, 0.16)
        }
      }
    }

    &__section {

      &--driver {
        background-color: #efede9;
        padding: 16px 40px;
        border-radius: 0 0 20px 20px;
      }
    }
  }
</style>
