<template lang="pug">
.timePicker
  .timePicker__panel
    h3.timePicker__subtitle
      span.timePicker__text {{ dateString }}
      span.timePicker__text {{ meridiemValue === 'am' ? '上午' : '下午' }} {{ timeString }} 
    .timePicker__picker
      scroll-picker-group.flex
        scroll-picker.vue-scroll-picker-5.align-right(
          v-model="dateValue"
          :options="dateOptions"
        )
        scroll-picker.vue-scroll-picker-2.align-right(
          v-model="meridiemValue"
          :options="meridiemOptions"
        )
        scroll-picker.vue-scroll-picker-2(
          v-model="hourValue"
          :options="hourOptions"
        )
        scroll-picker.vue-scroll-picker-2.align-left(
          v-model="minuteValue"
          :options="minuteOptions"
        )
    .timePicker__btnGroup
      BaseBtnConfirm.timePicker__btn.timePicker__btn--cancel(
        :text="'取消'"
        @click="$emit('closeTimePicker')"
      )
      BaseBtnConfirm.timePicker__btn(
        :text="'確定'"
        :disabled="isNewReservationTimeInvalid"
        @click="updateReservationTime"
      )
</template>
<script>
import debounce from 'lodash/debounce';
import "vue-scroll-picker/dist/style.css";
import { ScrollPicker, ScrollPickerGroup } from "vue-scroll-picker";
import { getDateString, getTimeString, getReserveDateOptions } from '@/utils/timeUtils';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import moment from 'moment'
export default {
  props: {
    reservationTime: {
      required: true,
    },
    initialReserveTime: {
      required: true,
    },
  },
  data() {
    return {
      dateValue: 0,
      hourValue: 0,
      minuteValue: 0,
      meridiemValue: 'am',
      meridiemOptions: [
        { value: 'am', name: '上午'},
        { value: 'pm', name: '下午'},
      ],
    };
  },
  computed: {
    dateString() {
      return getDateString(this.newReservationTime);
    },
    timeString() {
      return getTimeString(this.newReservationTime);
    },
    dateOptions() {
      return getReserveDateOptions(this.initialReserveTime);
    },
    hourOptions() {
      let array = [...Array(12).keys()]
        .map(index => index)
        .map(hour => ({
          name: `${hour === 0 ? 12 : hour}`,
          value: hour === 12 ? 0 : hour,
        }));
      return array
    },
    minuteOptions() {
      return [...Array(12).keys()]
        .map(index => ({
          name: `${("0" + index * 5).slice(-2)}`,
          value: index * 5,
        }));
    },
    newReservationTime() {
      const {year, month, date } = this.dateOptions
        .filter(dateObj => dateObj.value === this.dateValue)
        .pop();
      const hour = this.meridiemValue === 'pm'
        ? this.hourValue + 12
        : this.hourValue ;
        let changedate = ''
        let changemonth = ''
        let minuteValue = ''
        let cahngHour = ''
        if(date <= 9) {
          changedate = `0${date}`
        }else{
          changedate = date
        }
        if(month <= 9) {
          changemonth = `0${month}`
        }else{
          changemonth = month
        }
        if(hour <= 9) {
          cahngHour = `0${hour}`
        }else{
          cahngHour = hour
        }
        if(this.minuteValue <= 9) {
          minuteValue = `0${this.minuteValue}`
        }else{
          minuteValue = this.minuteValue
        }
      let newReservationTime = moment(`${year}-${changemonth}-${changedate} ${cahngHour}:${minuteValue}:00`)
      return newReservationTime;
    },
    isNewReservationTimeInvalid() {
      const timeDiff = moment(this.newReservationTime) - moment();
      return timeDiff < 15 * 60 * 1000;
    },
  },
  methods: {
    initDateValue() {
      const date = this.reservationTime.get('date');
      const currentDateObj = this.dateOptions
        .filter(dateObj => dateObj.date === date)
        .pop();

      this.dateValue = currentDateObj.value;
    },
    initHourValue() {
      const hour = this.reservationTime.get('hour');
      this.hourValue = hour < 12
        ? hour
        : hour - 12;
    },
    iniMminuteValue() {
      this.minuteValue = this.reservationTime.get('minute');
    },
    initMeridiemValue() {
      this.meridiemValue = this.reservationTime.get('hour') < 12
        ? 'am'
        : 'pm';
    },
    initPickerValues() {
      this.initDateValue();
      this.initHourValue();
      this.iniMminuteValue();
      this.initMeridiemValue();
    },
    updateReservationTime() {
      this.$emit('reservationTimeChanged', this.newReservationTime);
      this.$emit('closeTimePicker');
    },
  },
  components: {
    ScrollPicker,
    ScrollPickerGroup,
    BaseBtnConfirm,
  },
  mounted() {
    this.initPickerValues();
  },
};
</script>
<style lang="scss" scoped>
  .timePicker {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-end;

    &__panel {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 16px;
      border-radius: 20px 20px 0 0;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);
      background-color: #ffffff;
    }

    &__subtitle {
      margin: 0;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      font-weight: bolder;
      text-align: center;
      color: rgba(0, 0, 0, 0.87);
    }

    &__text {

      &:last-child {
        margin-left: 4px;
      }
    }

    &__btnGroup {
      margin-top: 12px;
      padding: 0 48px;
      display: flex;
      justify-content: space-between;
    }

    &__btn {
      width: 124px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

      &--cancel {
        background-color: #b7b7b7;
      }
    }

    & /deep/ .vue-scroll-picker {
      height: 185px;
      font-size: 20px;

      &.align-right .vue-scroll-picker-item {
        text-align: right;
      }

      &.align-left .vue-scroll-picker-item {
        text-align: left;
      }
    }

    & /deep/ .vue-scroll-picker-list-rotator {
      padding-top: 36px;
    }

    & /deep/ .vue-scroll-picker-item {
      height: 30px;
      line-height: 30px;
    }

    & /deep/ .vue-scroll-picker-layer .top {
      height: 75px;
    }

    & /deep/ .vue-scroll-picker-layer .bottom {
      height: 75px;
    }

    & /deep/ .vue-scroll-picker-layer .middle {
      top: 75px;
      bottom: 75px;
    }
  }
</style>
