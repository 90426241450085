<template lang="pug">
button.baseBtnNextStep(
  :class="{'baseBtnNextStep--disabled': disabled}"
  :disabled="disabled"
  @click="$emit('click')"
)
  span.baseBtnNextStep__text {{ text }}
</template>
<script>
export default {
  name: 'BaseBtnNextStep',
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }, 
};
</script>
<style lang="scss" scoped>
  .baseBtnNextStep {
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 20px;
    background-color: #ff9900;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    &__text {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #fff;
    }

    &--disabled {
      background: #ffd79c;
    }
  }
</style>
