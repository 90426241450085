<template lang="pug">
.hailingStatusModal
  HailingSearchingPopup(
    v-if="isSearching"
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :taxiType="taxiType"
    :payment="payment"
    :price="price"
    :extraServices="extraServices"
    :note="note"
    @cancelHailing="$emit('cancelHailing')"
  )
  HailingRepeatedPopup(
    v-else-if="repeated"
    @confirmRepeated="$emit('confirmRepeated')"
    @cancelRepeated="$emit('cancelRepeated')"
  )
  HailingSuccessPopup(
    v-else-if="isHailingSuccess"
    :driverImg="driverImg"
    :driverStar="driverStar"
    :driverNote="driverNote"
    :carLicense="carLicense"
    :carBrand="carBrand"
    :callNumber="callNumber"
    :teamName="teamName"
    :note="note"
    :estimatedTime="estimatedTime"
    @callTaxiDriver="$emit('callTaxiDriver')"
    @successConfirmed="$emit('successConfirmed')"
  )
  HailingFailPopup(
    v-else
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :taxiType="taxiType"
    :payment="payment"
    :price="price"
    :note="note"
    :extraServices="extraServices"
    @cancelNextPage="$emit('cancelNextPage')"
    @hailingAgain="$emit('hailingAgain')"
  )
</template>
<script>
import HailingSearchingPopup from './HailingSearchingPopup';
import HailingSuccessPopup from './HailingSuccessPopup';
import HailingRepeatedPopup from './HailingRepeatedPopup';
import HailingFailPopup from './HailingFailPopup';

export default {
  props: {
    pickUpName: {
      type: String,
      required: true,
    },
    dropOffName: {
      type: String,
      required: true,
    },
    taxiType: {
      type: String,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    isSearching: {
      type: Boolean,
      required: true,
    },
    isHailingSuccess: {
      type: Boolean,
      required: true,
    },
    repeated: {
      type: Number,
      required: true
    },
    price: {
      type: String,
      /* required: true, */
    },
    driverImg: {
      required: true,
    },
    driverStar: {
      type: Number,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
    note: {
      type: String,
      required: true,
    },
    estimatedTime: {
      type: [Number, String],
      /* required: true, */
    }
  },
  components: {
    HailingSearchingPopup,
    HailingSuccessPopup,
    HailingRepeatedPopup,
    HailingFailPopup,
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingStatusModal';

</style>
