<template lang="pug">
ul.hailingRecordList
  HailingRecordListItem.hailingRecordList__item(
    v-for="tripRecord in tripRecords"
    :key="tripRecord.orderID"
    :time="tripRecord.orderTime"
    :onLocation="tripRecord.onLocation"
    :offLocation="tripRecord.offLocation"
    :actualPrice="tripRecord.actualPrice"
    :isCanceled="tripRecord.rideStatus === '乘客取消' || tripRecord.rideStatus === '司機取消'"
    @checkDetailPage="$emit('checkDetailPage', tripRecord.orderID)"
  )
  //- HailingRecordListItem.hailingRecordList__item(
  //-   :tripRecords="tripRecords"
  //-   :isCanceled="true"
  //- )
</template>
<script>
import HailingRecordListItem from './HailingRecordListItem';

export default {
  props: {
    tripRecords: {
      type: Array,
      required: true,
    },
  },
  components: {
    HailingRecordListItem,
  },
};
</script>
<style lang="scss" scoped>
  .hailingRecordList {
    margin: 0;
    padding: 0 16px;

    &__item {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
