<template lang="pug">
div
  .extraServiceSelector(v-if="this.options")
    p.extraServiceSelector__title 偏好服務(金額加乘倍數，僅可選擇一個)
      span.extraServiceSelector__amount {{ this.countServiceAmount('multiple', this.extraServices)}}
    .extraServiceSelector__list
      .extraServiceSelector__item(
        v-for="{id, name} in options.optionsMultiple"
        :class="{'extraServiceSelector__item--selected': isServiceSelected(id)}"
        @click="toggleSelectExtraService(id, name)"
      ) {{ name }}  
  .extraServiceSelector(v-if="this.options")
    p.extraServiceSelector__title 偏好服務(金額無加乘倍數)
      span.extraServiceSelector__amount {{ this.countServiceAmount('noMultiple', this.extraServices) }}
    .extraServiceSelector__list
      .extraServiceSelector__item(
        v-for="{id, name} in options.optionsNoMultiple"
        :class="{'extraServiceSelector__item--selected': isServiceSelected(id)}"
        @click="toggleSelectExtraService(id)"
      ) {{ name }}
</template>
<script>

import axiosApConfig from '../../api/axiosApConfig.js'
import {getExtraServiceOptions} from '../../api/hailingService.js'
import {distinguishMultiple} from '../../utils/multipleValue.js'

export default {
  props: {
    extraServices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // structure of the options
      // options: {optionsMultiple: [{},{}], optionsNoMultiple: {[],[]}}
      options: null   
    };
  },
  mounted() {
    getExtraServiceOptions()
      .then(res => res.data.result)   
      .then(result => {
        this.options = distinguishMultiple(result)
      })
      .catch(error => console.error(error))
  },
  computed: {
    // servicesAmountMultiple() {
    //   return this.extraServices ? this.extraServices.length : 0
    // },
    // servicesAmountNoMultiple() {
    //   return this.extraServices ? this.extraServices.length : 0
    // },
  },
  methods: {
    isServiceSelected(id) {
      return this.extraServices ? this.extraServices.includes(id) : false;
    },

    toggleSelectExtraService(id, name) {
      // determine the id is multiple or non-multiple service
      const isMultiple = this.options.optionsMultiple.some(obj => obj.id === id)
      // organize the array to realize which id represent multiple service
      const multipleIdArray = this.options.optionsMultiple.map(obj => obj.id)
      const multipleNameArray = this.options.optionsMultiple.map(obj => obj.name)

      let newExtraServices
      // determine if there is already a multiple id in the extraServices array
      const isMultipleExist = this.extraServices.some(item => multipleIdArray.includes(item))

      if (isMultiple && isMultipleExist) {
        // remove the existing multiple id in the extraServices array   
        newExtraServices = this.extraServices.filter(id => !multipleIdArray.includes(id))
      } else{
        newExtraServices = this.extraServices
      } 

      const selectedServices = this.isServiceSelected(id)
        ? newExtraServices
            .filter(serviceId => serviceId !== id)
        : [...newExtraServices, id];
      this.$emit('extraServicesChanged', selectedServices);
    },  
    countServiceAmount (type, services){
      let length = 0

      if(this.extraServices){
        switch(type) {
          case 'multiple':
            // if extra service id is in the multiple category, length add 1 
            services.map(id => {
              if(this.options.optionsMultiple.some(obj => obj.id === id)){
                length++
              }
            })
            return length
          
          case 'noMultiple':
            // if extra service id is in the no multiple category, length add 1 
            services.map(id => {
              if(this.options.optionsNoMultiple.some(obj => obj.id === id)){
                length++
              }
            })
            return length
          
        }
      } else {
        return length 
      }
    },
  },

};
</script>
<style lang="scss" scoped>
  .extraServiceSelector {
    width: calc(100vw - 30px);
    overflow: hidden;

    &__title {
      margin: 0;
      color: #767676;
      font-size: 12px;
    }

    &__amount {
      margin-left: 2px;
      color: #ff9900;
    }

    &__list {
      margin-top: 4px;
      -ms-overflow-style: none;
      width: calc(100vw - 30px);
	    list-style: none;
      display: inline-flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    &__item {
      margin-left: 8px;
      display: inline-block;
      width: auto;
      padding: 0 8px 0 8px ; 
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      border: solid 2px #efeeea;
      text-align: center;
      flex-shrink: 0;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      &--selected {
        color: #ff9900;
        border: solid 2px #ff9900;
      }
    }
  }
</style>
