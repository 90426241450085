<template lang="pug">
.menu
  .menu__mask(
    @click="$emit('closeMenu')"
  )
  .menu__content
    .menu__banner
      h1.menu__title GLAD
    MenuList(
      @routeChanged="routeChangeHandler"
    )
    .menu__text.menu__text--version GLAD Taxi V1.0.1
</template>
<script>
import MenuList from '@/components/Menu/MenuList';

export default {
  methods: {
    routeChangeHandler(url) {
      this.$emit('routeChanged', url);
    },
  },
  components: {
    MenuList,
  },
};
</script>
<style lang="scss" scoped>
  .menu {

    &__mask {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    &__content {
      position: fixed;
      left: 0;
      top: 0;
      width: 304px;
      height: 100vh;
      background-color: #ffffff;
      z-index: 2;
    }

    &__banner {
      height: 148px;
      padding-top: 40px;
      background-color: #ff9900;
      box-sizing: border-box;
    }

    &__title {
      margin: 0;
      padding-left: 16px;
      color: #ffffff;
      height: 40px;
      line-height: 40px;
      font-size: 40px;
    }

    &__text {
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      color: #767676;

      &--version {
        position: absolute;
        left: 16px;
        bottom: 24px;
      }
    }
  }
</style>
