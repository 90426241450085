import axios from 'axios';
// import * as jsCookie from 'js-cookie'
import {webUrl} from './default'

// const localhostUrl = 'http://localhost:8080';
// const stagingBaseUrl = 'https://staging.ap.gladmobile.com';
// const productBaseUrl = 'https://test-fleet.gladmobile.com';


const axiosConfig = axios.create({
  baseURL: webUrl,
});

export const updateAccessToken = token => {
  //console.log('token',token)
  axiosConfig.defaults.headers.common['x-access-token'] = token
};

export default axiosConfig;
