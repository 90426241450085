<template lang="pug">
.home
  BaseBtnMenu.home__btn.home__btn--menu(
    @click="$emit('openMenu')"
  )
  GmapMap(
    ref="map"
    :center="getCurrentLatLnt"
    :zoom="17"
    map-type-id="terrain"
    style="width: 100vw; height: 100vh"
    :options="mapOptions"
  )
  BaseBtnCenterMap.home__btn.home__btn--centerMap(
    :class="{'reservation': isReserveMode }"
    v-if="isGeoEnabled"
    @click="centerMap"
  )
  TimePicker.home__timePicker(
    v-if="showTimePicker"
    :reservationTime="reservationTime"
    :initialReserveTime="initialReserveTime"
    @closeTimePicker="closeTimePicker"
    @reservationTimeChanged="reservationTimeChangeHandler"
  )
  HailingModeTable(
    v-else
    :mode="hailingMode"
    :isGeoEnabled="isGeoEnabled"
    :isReserveMode="isReserveMode"
    :geoErrorMsg="geoErrorStatus"
    :isPickupEmpty="isPickupEmpty"
    :reservationTime="reservationTime"
    @openTimePicker="openTimePicker"
    @onModeChange="modeChangeHandler"
  )
  ReservationAlertModal.home__alertModal(
    v-if="showAlertModal"
    :isChecked="isPreventAlertChecked"
    @toggleCheckPreventAlert="toggleCheckPreventAlert"
    @alertConfirmed="alertConfirmedHandler"
  )
  Loading(
    v-if="Loading"
  )
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HailingModeTable from "@/components/HailingModeTable";
import BaseBtnMenu from "@/components/BaseComponent/BaseBtnMenu";
import BaseBtnCenterMap from "@/components/BaseComponent/BaseBtnCenterMap";
import ReservationAlertModal from "@/components/common/ReservationAlertModal";
import TimePicker from "@/components/common/TimePicker";
import { setLocalStorage, getLocalStorage } from "@/utils/localStorageUtils";
import { getInitReservationTime } from "@/utils/timeUtils";
import { gmapApi } from "vue2-google-maps";
import Loading from "@/components/common/Loading";

export default {
  name: "Home",
  data() {
    return {
      // isPickupEmpty: true,
      showNotFoundModal: true,
      mapOptions: {
        disableDefaultUI: true,
        clickableIcons: false,
        styles: []
      },
      markerUrl: {
        url: require("@/assets/images/maker/location.png")
      },
      isPreventAlertChecked: false,
      isAlertPrevented: false,
      showAlertModal: false,
      showTimePicker: false,
      initialReserveTime: null,
      defaultCenter: {
        lat: 22.997195,
        lng: 120.212343
      },
      map: undefined,
      Loading: true,
      marker: null
    };
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      isGeoEnabled: "geolocation/getIsGeoEnabled",
      geoErrorStatus: "geolocation/geoErrorStatus",
      getCurrentLatLnt: "geolocation/getCurrentLatLnt",
      hailingMode: "hailingInfo/getHailingMode",
      pickUpName: "hailingInfo/getPickUpName",
      pickUpLacation: "hailingInfo/getPickUpLocation",
      reservationTime: "hailingInfo/getReservationTime"
    }),
    isPickupEmpty() {
      //console.log('isPickupEmpty', this.pickUpName === ''&& this.pickUpLacation === null)
      return this.pickUpName === "" && this.pickUpLacation === null;
    },
    isReserveMode() {
      return this.hailingMode === "reservation";
    }
  },
  methods: {
    init(lat, lng) {
      this.marker = new this.google.maps.Marker({
        position: {
          lat: lat,
          lng: lng
        },
        map: this.map,
        icon: require("@/assets/images/maker/location.png")
      });
      this.searchLocationName();
    },
    ...mapActions({
      setHailingMode: "hailingInfo/setHailingMode",
      initCurrentLatLnt: "geolocation/initCurrentLatLnt",
      setHailingPickUpName: "hailingInfo/setHailingPickUpName",
      setHailingPickUpLocation: "hailingInfo/setHailingPickUpLocation",
      setHailingPickUpPostalNumber: "hailingInfo/setHailingPickUpPostalNumber",
      setReservationTime: "hailingInfo/setReservationTime",
      setTestCarTeam: "hailingInfo/setTestCarTeam"
    }),
    modeChangeHandler(mode) {
      this.setHailingMode(mode);

      mode === "reservation" && this.initReservationMode();
    },
    initPickupPosition() {
      this.updatePickupInfo();
    },
    async updatePickupInfo() {
      this.Loading = false;
      const { lat, lng } = this.getCurrentLatLnt;
      this.init(lat, lng);
    },
    async searchLocationName() {
      const { lat, lng } = this.getCurrentLatLnt;
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status !== "OK") {
          window.alert("Geocoder failed due to: " + status);
          return;
        }
        const { formatted_address, geometry, address_components } = results[1];
        //console.log('results[0]', results[0])
        const locationName = formatted_address.replace(/^\d{3}?台灣/, "");
        const { long_name } = address_components
          .filter(({ types }) => types.includes("postal_code"))
          .pop();
        this.setHailingPickUpLocation(geometry.location);
        this.setHailingPickUpName(locationName);
        this.setHailingPickUpPostalNumber(long_name);
      });
    },
    centerMap() {
      // this.isPickupEmpty = false
      this.$refs.map.$mapPromise.then(map => {
        map.panTo(this.getCurrentLatLnt);
      });
    },
    initReservationMode() {
      this.initialReserveTime = getInitReservationTime();
      this.setReservationTime(getInitReservationTime());

      this.isAlertPrevented === false && this.openAlertModal();
    },
    openAlertModal() {
      this.showAlertModal = true;
    },
    closeAlertModal() {
      this.showAlertModal = false;
    },
    openTimePicker() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點擊設定時間"
      });
      this.initialReserveTime = getInitReservationTime();
      this.showTimePicker = true;
    },
    closeTimePicker() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點擊關閉設定時間"
      });
      this.showTimePicker = false;
    },
    toggleCheckPreventAlert() {
      this.isPreventAlertChecked = !this.isPreventAlertChecked;
    },
    initPreventReservationAlert() {
      this.isAlertPrevented =
        getLocalStorage("preventReservationAlert") === "true";
    },
    alertConfirmedHandler() {
      if (this.isPreventAlertChecked) {
        setLocalStorage("preventReservationAlert", true);
        this.isAlertPrevented = true;
      }
      this.closeAlertModal();
    },
    reservationTimeChangeHandler(time) {
      this.setReservationTime(time);
    }
  },
  watch: {
    isGeoEnabled: {
      handler(newValue, oldValue) {
        if (oldValue === false && newValue === true && this.isPickupEmpty) {
          this.initPickupPosition();
        }
      },
      immediate: false
    }
  },
  components: {
    TimePicker,
    BaseBtnMenu,
    BaseBtnCenterMap,
    HailingModeTable,
    ReservationAlertModal,
    Loading
  },
  created() {
    console.log("==== document.URL ====", document.URL);
    const params = new URL(document.URL).searchParams;
    const testTeam = params.get("team");
    this.setTestCarTeam(testTeam);
    if (document.URL.indexOf("HailingHistory") > -1) {
      this.$router.replace({ path: "HailingHistory" });
    } else if (document.URL.indexOf("LinePaySuccess") > -1) {
      this.$router.replace({ path: "LinePaySuccess" });
    } else if (document.URL.indexOf("FavoriteDrivers") > -1) {
      this.$router.replace({ path: "FavoriteDrivers" });
    } else if (document.URL.indexOf("ForbiddenDrivers") > -1) {
      this.$router.replace({ path: "ForbiddenDrivers" });
    } else if (document.URL.indexOf("ReservationRecords") > -1) {
      this.$router.replace({ path: "ReservationRecords" });
    } else if (document.URL.indexOf("TaxiStatus") > -1) {
      // 此畫面可以讓預約單與即時單共用
      // 將id解析出來
      const urlParams = new URL(document.URL);
      console.log("URL", urlParams);
      try {
        // let str = urlParams.href.replace('?',',').replace('&',',').replace('#',',').split(',')
        let id = urlParams.href.match(/&id=(\d+)/)[1];
        //console.log(urlParams)
        if (urlParams.hash.indexOf("reservation") > -1) {
          this.$router.replace({
            path: "TaxiStatus",
            query: { task: "reservation", id: id }
          });
          return;
        }
        this.$router.replace({
          path: "TaxiStatus",
          query: { task: "instant", id: id }
        });
      } catch (err) {
        console.error("TaxiStatus Error", err);
      }
    } else if (document.URL.indexOf("Reservation") > -1) {
      this.modeChangeHandler("reservation");
    } else if (document.URL.indexOf("Chat") > -1) {
      try {
        const id = document.URL.match(/&id=(\d+)/)[1];
        console.log("Home redirecton to Chat");
        this.$router.replace({ name: "Chat", query: { id: id } });
      } catch (err) {
        console.log("Chat Error", err);
      }
    }
  },
  mounted() {
    this.Loading = true;
    this.$refs.map.$mapPromise.then(map => {
      this.map = map;
    });
    this.initCurrentLatLnt();
    this.initPreventReservationAlert();
    try {
      if (this.isGeoEnabled) {
        this.saveBehavior({
          event: "顯示",
          type: 0,
          note: "已有偵測到GPS，兩秒設置marker"
        });
        setTimeout(() => {
          const { lat, lng } = this.getCurrentLatLnt;
          this.marker = null;
          this.init(lat, lng);
          this.Loading = false;
        }, 2000);
        return;
      }
      this.saveBehavior({
        event: "顯示",
        type: 0,
        note: "沒有偵測到GPS，五秒設置marker"
      });
      setTimeout(() => {
        const { lat, lng } = this.getCurrentLatLnt;
        this.marker = null;
        this.init(lat, lng);
        this.Loading = false;
        this.updatePickupInfo();
      }, 5000);
    } catch (err) {
      console.error(err);
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;

  &__btn {
    position: absolute;
    z-index: 1;

    &--menu {
      top: 8px;
      left: 8px;
    }

    &--centerMap {
      bottom: 147px;
      right: 8px;

      &.reservation {
        bottom: 203px;
      }
    }
  }

  &__alertModal,
  &__timePicker {
    z-index: 1;
  }
}
</style>
