<template lang="pug">
.paymentSetup
  PageHeader(
    :title="'付款設定'"
    @toLastPage="toLastPage"
  )
  p.paymentSetup__text.paymentSetup__text--info 選擇您的支付方式
  PaymentSetupList.paymentSetup__list(
    @addCreditCard="toNewCreditCardPage"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import PaymentSetupList from '@/components/PaymentSetupList';

export default {
  methods: {
    toLastPage() {
      this.$router.back();
    },
    toNewCreditCardPage() {
      this.$router.push('/NewCreditCard');
    },
  },
  components: {
    PageHeader,
    PaymentSetupList,
  },
};
</script>
<style lang="scss" scoped>
  .paymentSetup {
    width: 100vw;
    height: 100vh;

    &__text {
      margin: 0;
      font-size: 12px;
      color: #767676;
      line-height: 40px;
      padding-left: 24px;
    }

    &__list {
      height: calc(100vh - 96px);
      overflow-y: auto;
    }
  }
</style>
