<template lang="pug">
.reservationSuccessPanel
  .reservationSuccessPanel__row
    .reservationSuccessPanel__item(@click="$emit('cancelReservation')")
      closeIcon.reservationSuccessPanel__icon.reservationSuccessPanel__icon--close
      .reservationSuccessPanel__text 取消訂單
    .reservationSuccessPanel__item(@click="$emit('callCustomerPhone')")
      serviceIcon.reservationSuccessPanel__icon.reservationSuccessPanel__icon--service
      .reservationSuccessPanel__text 聯絡客服
    .reservationSuccessPanel__item(@click="$emit('calldriverPhone')")
      phoneIcon.reservationSuccessPanel__icon.reservationSuccessPanel__icon--phone
      .reservationSuccessPanel__text 聯絡司機
  .reservationSuccessPanel__row
    .reservationSuccessPanel__item(@click="$emit('showDriverLocation')")
      mapIcon.reservationSuccessPanel__icon.reservationSuccessPanel__icon--map
      .reservationSuccessPanel__text 司機位置
    //- .reservationSuccessPanel__item(@click="$emit('mobilePay')")
    //-   .reservationSuccessPanel__icon.reservationSuccessPanel__icon--mobile_pay
    //-   .reservationSuccessPanel__text 行動支付
</template>

<script>
import closeIcon from '@/assets/images/close.png';
import serviceIcon from '@/assets/images/service.png';
import mobilePayIcon from '@/assets/images/mobile_pay.svg';
import phoneIcon from '@/assets/images/phone.png';
import mapIcon from '@/assets/images/map.png';

export default {
  name: 'ReservationSuccessPanel',
  components: {
    closeIcon,
    serviceIcon,
    mobilePayIcon,
    phoneIcon,
    mapIcon
  }
}
</script>

<style lang="scss" scoped>
  .reservationSuccessPanel {

    &__row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 88px;
      height: 114px;
      border-radius: 6px;
      border: solid 1px #000000;
    }

    &__icon {
      margin-bottom: 10px;
      width: 50px;
      height: 50px;
    }

    &__text {
      font-size: 14px;
    }
  }
</style>
