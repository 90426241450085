<template lang="pug">
.section.section__driver
  .section__content
    .section__description
      p.section__subtitle 司機姓名
      p.section__value {{driverInfo.driverName}}
    .section__description
      p.section__subtitle 車隊名稱
      p.section__value {{teamInfo.teamName}}
    .section__description
      p.section__subtitle 司機編號/呼號
      p.section__value {{driverInfo.callNumber}}
    .section__description
      p.section__subtitle 司機備註
      p.section__value {{driverInfo.driverNote}}
    .section__description
      p.section__subtitle 車牌號碼
      p.section__value {{driverInfo.carLicense}}
    .section__description
      p.section__subtitle 車輛型號
      p.section__value {{driverInfo.carBrand}}
    .section__description
      p.section__subtitle 車門數量
      p.section__value {{driverInfo.driverName}}
    .section__description
      p.section__subtitle 客服專線
      p.section__value.section__value--phone {{teamInfo.teamPhone}}
</template>
<script>
export default {
  props: {
    driverInfo: {
      type: Object
    },
    teamInfo: {
      type: Object
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingRecordSection';
</style>
