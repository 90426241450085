<template lang="pug">
.taxiInfoPanel
  .taxiInfoPanel__wrapper
    .taxiInfoPanel__logo
      img(:src="driverImg" v-if="driverImg").taxiInfoPanel__imgView
      BaseIconStarWithValue.taxiInfoPanel__icon(
        :value="driverStar"
      )
    .taxiInfoPanel__content
      p.taxiInfoPanel__subtitle {{ callNumber }}
      p.taxiInfoPanel__text {{ teamName }}
      p.taxiInfoPanel__text.taxiInfoPanel__text--type {{ driverNote }}
      //- p.taxiInfoPanel__text.taxiInfoPanel__text--cancel 取消叫車
  .taxiInfoPanel__wrapper
    p.taxiInfoPanel__text.taxiInfoPanel__text--model {{ carBrand }}
    p.taxiInfoPanel__text.taxiInfoPanel__text--license {{ carLicense }}
</template>
<script>
import BaseIconStarWithValue from '@/components/BaseComponent/BaseIconStarWithValue';

export default {
  props: {
    driverImg: {
      required: false,
    },
    driverStar: {
      type: Number,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      default:'',
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseIconStarWithValue,
  },
};
</script>
<style lang="scss" scoped>
  .taxiInfoPanel {
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;

    &__logo {
      position: relative;
      width: 64px;
      height: 64px;
      // background-color: #777;
      background: url('../../assets/images/user.png') no-repeat 0 0 / contain;
      border-radius: 20px;
      display: inline-block;
      vertical-align: top;
    }

    &__imgView {
      width: 64px;
      height: 64px;
      border-radius: 20px;
    }

    &__icon {
      position: absolute;
      right: -12px;
      bottom: -12px;
    }

    &__content {
      display: inline-block;
      margin-left: 16px;
    }

    &__subtitle {
      margin: 0;
      color: #ff9900;
      font-size: 24px;
      height: 32px;
      line-height: 32px;
      font-weight: 500;
    }

    &__text {
      margin: 0;
      font-size: 12px;

      &--type {
        color: #767676;
        max-width:80px
      }

      &--model {
        margin-top: 15px;
        margin-bottom: 5px;
        color: #767676;
        max-width:70px
      }

      &--license {
        padding: 0 6px;
        color: rgba(0, 0, 0, 0.87);
        height: 20px;
        line-height: 18px;
        text-align: center;
        border: 1px solid #767676;
        border-radius: 4px;
        box-sizing: border-box;
        max-width:80px;
      }
    }
  }
</style>
