var lineLoginClientId;
var webUrl;
var server;
let googleMapKey;
let url = document.URL;
let sub_domain = "tak-wah";
let use_url = "";
let line_channel_url = "";
let gps = { lat: "", lng: "" };
if (url.indexOf("localhost") > -1) {
  lineLoginClientId = "1599382552-PNGyK1Ym";
  webUrl = "https://staging.ap.gladmobile.com";
  server = "https://staging.ap.gladmobile.com";
  sub_domain = "staging";
  googleMapKey = "AIzaSyDcuS4CZ6qWm3safCpzSfKLLxcQlichhk8";
  use_url = "https://staging.line.gladmobile.com";
  line_channel_url = "https://lin.ee/fRnLPDr";
  gps.lat = 22.997195;
  gps.lng = 120.212343;
} else if (url.indexOf("staging.line.gladmobile.com") > -1) {
  //測試
  lineLoginClientId = "1657754659-832Q9m2l";
  webUrl = "https://staging.ap.gladmobile.com";
  server = "https://staging.ap.gladmobile.com";
  sub_domain = "staging";
  googleMapKey = "AIzaSyDcuS4CZ6qWm3safCpzSfKLLxcQlichhk8";
  use_url = "https://staging.line.gladmobile.com";
  line_channel_url = "https://line.me/R/ti/p/%40938pogix";
  gps.lat = 22.997195;
  gps.lng = 120.212343;
} else if (url.indexOf("naver.gladmobile.com") > -1) {
  // 通用
  lineLoginClientId = 1598288697;
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://naver.gladmobile.com";
  line_channel_url = "https://lin.ee/fRnLPDr";
  gps.lat = 22.997195;
  gps.lng = 120.212343;
} else if (url.indexOf("daisuki.line.gladmobile.com") > -1) {
  // 台一
  lineLoginClientId = "1653609676-El5jwv5a";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "daisuki";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://daisuki.line.gladmobile.com";
  line_channel_url = "https://lin.ee/fRnLPDr";
  gps.lat = 22.997195;
  gps.lng = 120.212343;
} else if (url.indexOf("hello-car.line.gladmobile.com") > -1) {
  // 好客來
  lineLoginClientId = "1654928378-MrNJ63n5";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "hello-car";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://hello-car.line.gladmobile.com";
  line_channel_url = "https://lin.ee/fRnLPDr";
  gps.lat = 22.63955;
  gps.lng = 120.301888;
} else if (url.indexOf("hawii.line.gladmobile.com") > -1) {
  // 瑞龍
  lineLoginClientId = "1656396024-1O3DRRjV";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "hawii";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://hawii.line.gladmobile.com";
  line_channel_url = "https://lin.ee/fRnLPDr";
  gps.lat = 22.63955;
  gps.lng = 120.301888;
} else if (url.indexOf("takwah-e2c5e.web.app") > -1) {
  // 德華
  lineLoginClientId = "1656396024-1O3DRRjV";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "tak-wah";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://takwah-e2c5e.web.app";
  line_channel_url = "https://lin.ee/QG9LpIu";
  gps.lat = 23.479068;
  gps.lng = 120.441433;
} else if (url.indexOf("linlin-474c3.web.app") > -1) {
  // 玲玲
  lineLoginClientId = "1657502912-R434ObwN";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "linlin";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://tlinlin-474c3.web.app";
  line_channel_url = "https://lin.ee/QG9LpIu";
  gps.lat = 24.989849;
  gps.lng = 121.313328;
} else if (url.indexOf("yongbao.line.gladmobile.com") > -1) {
  // 永保
  lineLoginClientId = "1657682873-7d8e4oNk";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "yongbao";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  // use_url = "https://yongbao-215e4.web.app";
  use_url = "https://yongbao.line.gladmobile.com";
  line_channel_url = "https://line.me/R/ti/p/@976gbfgt";
  gps.lat = 24.137288;
  gps.lng = 120.6847364;
} else if (url.indexOf("dihon.line.gladmobile.com") > -1) {
  //大豐
  lineLoginClientId = "2000597348-VZZ45ekB";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "dihon";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://prod.line.gladmobile.com/";
  line_channel_url = "https://line.me/R/ti/p/@017mxnsa";
  // 花蓮火車站的gps位置
  gps.lat = 23.99336916333714;
  gps.lng = 121.60127868037549;
} else if (url.indexOf("prod.line.gladmobile.com") > -1) {
  //prod
  lineLoginClientId = "1660740912-XNn66bgM";
  webUrl = "https://test-fleet.gladmobile.com";
  server = "https://ap.gladmobile.com";
  sub_domain = "lck";
  googleMapKey = "AIzaSyB7QmtTsCblu8vh7HiTVkImd5Qgc2rP-1Y";
  use_url = "https://prod.line.gladmobile.com";
  line_channel_url = "https://line.me/R/ti/p/@947umpsx";
  gps.lat = 24.137288;
  gps.lng = 120.6847364;
}
let oauthCallbackUrl = `${webUrl}/line/api/${sub_domain}cb`;
export {
  lineLoginClientId,
  webUrl,
  oauthCallbackUrl,
  server,
  googleMapKey,
  sub_domain,
  use_url,
  line_channel_url
};
