<template lang="pug">
.hailingStatusModal__popup.hailingStatusModal__popup--search
  h3.hailingStatusModal__title
    span {{ hailingSeconds }}s 
    span 尋車中...
  .hailingStatusModal__image
  .hailingStatusModal__carType
    p.hailingStatusModal__subtitle {{ taxiType }}
    infoIcon.hailingStatusModal__iconInfo(
      @click="toggleShowHint"
    )
    .hailingStatusModal__hint(
      v-if="showHint"
    ) {{ hint }}
  HailingDetailTable.hailingStatusModal__detail(
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :taxiType="taxiType"
    :price="price"
    :payment="payment"
    :extraServices="extraServices"
    :note="note"
  )
  .hailingStatusModal__btnWrapper
    BaseBtnConfirm(
      :text="'取消叫車'"
      @click="$emit('cancelHailing')"
    )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import HailingDetailTable from '@/components/HailingStatusModal/HailingDetailTable';
import infoIcon from '@/assets/images/info.svg';

export default {
  props: {
    pickUpName: {
      type: String,
      required: true,
    },
    dropOffName: {
      type: String,
      required: true,
    },
    taxiType: {
      type: String,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    price: {
      type: String,
      /* required: true, */
    },
    note: {
      type: String,
      required: true,
    }
  },
  components: {
    BaseBtnConfirm,
    HailingDetailTable,
    infoIcon
  },
  data() {
    return {
      showHint: false,
      hint: '我們可能為您派遣 寬敞六人座、多元舒適四人座、多元寬敞六人座 車款，但不會為此額外增加費用。',
      seconds: 60,
      secondsCountDownID: null
    };
  },
  methods: {
    toggleShowHint() {
      this.showHint = !this.showHint;
    },
    countDown() {
      this.secondsCountDownID = setInterval(() => {
        if(this.seconds === 1) clearInterval(this.secondsCountDownID);
        this.seconds--;
      }, 1000);
    }
  },
  computed: {
    hailingSeconds() {
      return this.seconds;
    }
  },
  created(){
    this.countDown();
  },
  beforeDestroy() {
    clearInterval(this.secondsCountDownID);
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingStatusModal';

.hailingStatusModal {

  &__image {
    width: 150px;
    height: 60px;
    margin: 30px auto 10px auto;
    background-image: url('../../assets/images/car_move.gif');
    background-size: contain;
    background-repeat: no-repeat;
  }

  &__carType {
    padding-top: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__hint {
    position: absolute;
    bottom: 100%;
    background-color: #767676;
    color: #d1d1d1;
    border-radius: 8px;
    padding: 16px;
    font-size: 12px;
    width: 232px;
    line-height: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.13);
  }

  &__detail {
    margin: 0 auto;
    margin-top: 20px;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
  }

  &__iconInfo {
    margin-left: 8px;
    transform: scale(1.7);
  }
}
</style>
