<template lang="pug">
.carTypeSelector(
  :class="{'carTypeSelector--extend': showCarTypeList }"
)
  ul.carTypeSelector__list
    CarTypeListItem.carTypeSelector__listItem(
      v-for="(taxiInfo, index) in carDefaultCompute"
      v-if="!(passenger > 4 && index === 0)"
      :key="taxiInfo.carType"
      :taxiType="taxiInfo.carType"
      :time="setTime(taxiInfo.carType)"
      :price="setPrice(taxiInfo.carType)"
      :class="taxiInfo.isOpt ? 'opt':''"
      @click="carTypeSelectedHandler(index)"
    )
  //- )
</template>
<script>
import CarTypeListItem from './CarTypeListItem';
import { carFilterList } from '@/utils/CarFilterUtils';

export default {
  props: {
    showCarTypeList: {
      type: Boolean,
      required: true,
    },
    carType: {
      type: Number,
      required: true,
    },
    taxiList: {
      type: Array,
      required: true,
    },
    passenger: {
      type: Number,
      required: false,
      default: 1
    }
  },
  computed: {
    selectedCarType() {
      for(let i = 0; i < this.carDefault.length; i++){
        if(this.carDefault[i].isOpt){
          return this.carDefault[i];
        }
      }
      return this.carDefault[0];
    },
    carDefaultCompute(){
      return this.carDefault;
    }
  },
  data(){
    return{
      carDefault: carFilterList()
    }
  },
  methods: {
    setTime(taxiType){  
      //console.log(this.taxiList)

      for(let i = 0; i< this.taxiList.length; i++){
        //console.log('1', this.taxiList[i].carType)
        //console.log('2', taxiType)
        //console.log('6', this.taxiList[i].carType.replace('六人座', '(6人座)').replace('（六人座）', '(6人座)').search(taxiType))
        if(this.taxiList[i].carType.replace('六人座', '(6人座)').replace('（六人座）', '(6人座)').search(taxiType) !== -1){
          //console.log('3', taxiType)
          //console.log('4', this.taxiList[i].carType)

          return this.taxiList[i].minutes === null ? '--' : `${this.taxiList[i].minutes}`
        }
      }
    },
    setPrice(taxiType){
      for(let i = 0; i< this.taxiList.length; i++){
        if(this.taxiList[i].carType.search(taxiType) !== -1){
          //console.log(i, this.taxiList[i])

          return this.taxiList[i].estimate === null ? '--' : `${this.taxiList[i].estimate}`
        }
      }
    },
    carTypeSelectedHandler(idx) {
      for(let i = 0; i < this.carDefault.length; i++){
        this.carDefault[i].isOpt = false
      }
      this.carDefault[idx].isOpt = true
      this.$emit('carTypeChanged', idx);
    },
    initCarTypeStyleByPassenger() {
      if(this.passenger > 4) {
        for(let i = 0; i < this.carDefault.length; i++){
          this.carDefault[i].isOpt = false;
        }
        //console.log('車種', this.carDefault[1]);
        this.carDefault[1].isOpt = true;
      }
    }
  },
  components: {
    CarTypeListItem,
  },
  mounted() {
    this.initCarTypeStyleByPassenger();
  }
};
</script>
<style lang="scss" scoped>
  .opt{
    border: solid 2px  #ff9900;
  }
  .carTypeSelector {
    width: 100%;

    &__title {
      margin: 0;
      margin-bottom: 4px;
      color: #767676;
      font-size: 12px;
      height: 16px;
      line-height: 16px;
    }

    &__icon {
      margin-left: 4px;
    }

    &--extend {
      height: calc(90vh - 341px);
      margin-bottom: 18px;
      overflow-y: auto;
    }

    &__list {
      margin: 0;
      padding: 0;
    }

    &__listItem {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 2px;
      }
    }
  }
</style>
