<template lang="pug">
ul.paymentSetupList
  PaymentSetupListItem(
    :name="'現金'"
    :isChecked="true"
    :deletable="false"
  )
  PaymentSetupListItem(
    :name="'line pay'"
    :isChecked="false"
    :deletable="false"
  )
  PaymentSetupListItem(
    :name="'信用卡-VISA****0843'"
    :isChecked="false"
  )
  PaymentSetupListItem(
    :name="'信用卡-VISA****0521'"
    :isChecked="false"
  )
  button.paymentSetupList__btn(
    @click="$emit('addCreditCard')"
  ) 新增信用卡
</template>
<script>
import PaymentSetupListItem from './PaymentSetupListItem';

export default {
  components: {
    PaymentSetupListItem,
  },
};
</script>
<style lang="scss" scoped>
  .paymentSetupList {
    margin: 0;
    padding: 0;

    &__btn {
      outline: none;
      border: none;
      padding: 0;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      color: #ff9900;
      text-align: left;
      background-color: #ffffff;
    }
  }
</style>
