<template lang="pug">
.forbiddenDriverSelector
  .forbiddenDriverSelector__selectorWrapper
    h3.forbiddenDriverSelector__subtitle 選擇車隊
    TeamSelector.forbiddenDriverSelector__selector(
      :value="selectTeam"
      :options="options"
      @teamChanged="teamChangeHandler"
    )
  .forbiddenDriverSelector__inputWrapper
    h3.forbiddenDriverSelector__subtitle 請輸入隊編
    BaseInput(
      :placeholder="'ex:0122'"
      :value="driverNumber"
      @input="driverNumberChangeHandler"
    )
  .forbiddenDriverSelector__btnWrapper
    BaseBtnConfirm(
      :text="'新增'"
      :disabled="confirmDisabled"
      @click="$emit('driverConfirmed')"
    )
</template>
<script>
import BaseInput from '@/components/BaseComponent/BaseInput';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import TeamSelector from '@/components/ForbiddenDriverSelector/TeamSelector';

export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectTeam: {
      type: String,
      required: true,
    },
    driverNumber: {
      type: String,
      required: true,
    },
  },
  computed: {
    confirmDisabled() {
      return this.selectTeam === ''
        || this.driverNumber ==='';
    },
  },
  methods: {
    teamChangeHandler(team) {
      this.$emit('teamChanged', team);
    },
    driverNumberChangeHandler(e) {
      const { value } = e.target;
      //console.log(value)
      this.$emit('driverNumberChanged', value);
    },
  },
  components: {
    BaseInput,
    BaseBtnConfirm,
    TeamSelector,
  },
};
</script>
<style lang="scss" scoped>
  .forbiddenDriverSelector {
    width: 100%;
    padding-bottom: 16px;
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.16);

    &__selectorWrapper,
    &__inputWrapper {
      padding: 4px 16px;
    }

    &__subtitle {
      margin: 0;
      font-size: 12px;
      margin-bottom: 8px;
      height: 16px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__btnWrapper {
      margin-top: 20px;
      padding: 0 8px;
    }
  }
</style>
