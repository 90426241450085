<template lang="pug">
BaseListItem.listItemSave(
  :title="title"
  :showIcon="true"
  @click="$emit('click')"
)
  template(v-slot:logo)
    locationIcon.listItemSave__logo
</template>
<script>
import BaseListItem from '@/components/BaseComponent/BaseListItem';
import locationIcon from '@/assets/images/location_circle.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseListItem,
    locationIcon
  },
};
</script>
<style lang="scss" scoped>
</style>
