<template lang="pug">
.billModal
  .billModal__popup
    h3.billModal__title 此趟乘車帳單
    .billModal__content
      .billModal__description
        p.billModal__subtitle 司機
        p.billModal__text {{driverInfo}}
      .billModal__description
        p.billModal__subtitle 本趟車資
        p.billModal__text ${{price}}
      .billModal__description
        p.billModal__subtitle 上車地點
        p.billModal__text {{onLocation}}
      .billModal__description
        p.billModal__subtitle 下車地點
        p.billModal__text {{offLocation}}
    .billModal__btnWrapper
      BaseBtnConfirm(:text="'Line Pay'" @click="$emit('linePay')")
    .billModal__btnWrapper
      button.billModal__button
        span.billModal__btnText 取消
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
export default {
  props:{
    driverInfo: {
      type: String,
      required: false,
      default: ''
    },
    price: {
      type: String,
      required: false,
      default: ''
    },
    onLocation: {
      type: String,
      required: false,
      default: ''
    },
    offLocation: {
      type: String,
      required: false,
      default: ''
    },
  },
  components: {
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>

.billModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  &__popup {
    width: 296px;
    background-color: #fff;
    border-radius: 20px;
    padding: 8px 16px;
    box-sizing: border-box;
  }

  &__title {
    text-align: center;
    margin: 24px 0;
    font-weight: 500;
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__btnWrapper {
    margin-top: 8px;
  }

  &__button {
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    width: 100%;
    outline: none;
    border: 2px solid #a62900;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
  }

  &__btnText {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    color: #a62900;
  }

  &__content {
      margin-bottom: 40px;
    }

    &__description {
      margin: 0;
      padding: 0 8px;
    }

    &__subtitle {
      display: inline-block;
      width: 50px;
      margin: 0;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #767676;
    }

    &__text {
      display: inline-block;
      margin: 0;
      margin-left: 16px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
}
</style>
