import {
  SET_PREDICT_LOCATIONS,
  CLEAR_PREDICT_LOCATIONS,
  SET_CURRENT_INPUT,
} from './mutation-types';

export default {
  [SET_PREDICT_LOCATIONS](state, locations) {
    state.predictLocations = [...locations];
  },
  [CLEAR_PREDICT_LOCATIONS](state) {
    state.predictLocations = [];
  },
  [SET_CURRENT_INPUT](state, currentInput) {
    state.currentInput = currentInput;
  },
};
