<template lang="pug">
ul.savedLocationList
  ListItemSavedLocation(
    v-for="{ id, address } in locationItems"
    :key="id"
    :title="address"
    @click="selectLocation(id, address)"
  )
</template>
<script>
import ListItemSavedLocation from '@/components/common/ListItemSavedLocation';

export default {
  name: 'SavedLocationList',
  props: {
    locationItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    selectLocation(place_id,address) {
      //console.log(address)
      this.$emit('locationSelected', place_id);
    },
  },
  components: {
    ListItemSavedLocation,
  },
};
</script>
<style lang="scss" scoped>
  .savedLocationList {
    margin: 0;
    padding: 0;
  }
</style>;
