<template lang="pug">
#app
  router-view(
    @openMenu="openMenu"
  )
  BaseModal(
    v-if="!isLogin"
    :title="title"
    :text="text"
  )
  Menu(
    v-if="showMenu"
    @closeMenu="closeMenu"
    @routeChanged="routeChangeHandler"
  )
</template>
<script>
import Menu from "@/components/Menu";
import liff from "@line/liff";
import { loginUser } from "./api/userInfoService";
import * as jsCookie from "js-cookie";
import axiosConfig from "./api/axiosConfig";
import axiosApConfig from "./api/axiosApConfig";

import { lineLoginClientId } from "./api/default";
import BaseModal from "@/components/BaseComponent/BaseModal";

export default {
  data() {
    return {
      showMenu: false,
      isLogin: true,
      title: "訊息",
      text: "登入中...請稍候"
    };
  },
  created() {
    if (
      this.$route.name === "TaxiStatus" ||
      this.$route.name === "Chat" ||
      this.$route.name === "LinePay" ||
      this.$route.name === "LinePaySuccess" ||
      this.$route.name === "BillSigning"
    ) {
      return;
    }
    if (document.URL.indexOf("localhost") > -1) {
      jsCookie.set("lineId", "Ud34997d198fc22f9f922d6af722257d2", {
        expires: 0.1
      });
      this.login("Ud34997d198fc22f9f922d6af722257d2");
      return;
    }
    liff
      .init({
        liffId: lineLoginClientId
      })
      .then(() => {
        liff
          .getProfile()
          .then(profile => {
            const lineId = profile.userId;
            jsCookie.set("lineId", lineId, { expires: 0.1 });
            this.login(lineId);
          })
          .catch(() => {
            this.isLogin = false;
            this.title = "無法登入";
            this.text = "使用Line LIFF API時發生錯誤，請再試一次";
            console.error("使用Line LIFF API時發生錯誤");
          });
      })
      .catch(() => {
        this.isLogin = false;
        this.title = "無法登入";
        this.text = "取得Line Id時發生錯誤，請再試一次";
        console.error("取得Line Id時發生錯誤");
      });
  },
  methods: {
    async login(lineId) {
      try {
        this.isLogin = true;
        let payload = {
          lineId: lineId
        };
        let info = await loginUser(payload);
        let infoResult = info.data.result;
        jsCookie.set("token", infoResult.token, { expires: 0.1 });
        axiosConfig.defaults.headers.common["x-access-token"] =
          infoResult.token;
        axiosApConfig.defaults.headers.common["x-access-token"] =
          infoResult.token;
        /* 最愛司機與黑名單會有參數goto在url中 */
        if (this.$route.query.goto) {
          this.$router.replace({ path: this.$route.query.goto });
        } else {
          this.$router.replace({ path: "Home" });
        }
      } catch (err) {
        console.error(err);
        this.isLogin = false;
        this.title = "無法登入";
        this.text = "確認是否已加入會員，可以由Line Bot上面註冊";
        alert(err);
      }
    },
    openMenu() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    },
    routeChangeHandler(url) {
      this.closeMenu();
      this.$router.push(url);
    }
  },
  components: {
    Menu,
    BaseModal
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap");
@import "@/scss/_infoWindow";

body {
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
}
</style>
