<template lang="pug">
.baseInput
  input.baseInput__input(
    ref="input"
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    :type="inputType"
    @input="inputHandler"
    @focus="focusHandler")
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputType: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    inputHandler(event) {
      this.$emit('input', event);
    },
    focusHandler(event) {
      this.$emit('focus', event);
    },
  },
};
</script>
<style lang="scss" scoped>
  .baseInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    border: 1px solid #ebecec;
    border-radius: 12px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);

    &__input {
      outline: none;
      border: none;
      font-size: 12px;
      width: 100%;
    }
  }
</style>
