import { isEmpty } from "lodash";

import {
  SET_HAILING_MODE,
  SET_HAILING_PICK_UP_NAME,
  SET_HAILING_PICK_UP_LOCATION,
  SET_HAILING_PICK_UP_POSTAL_NUMBER,
  SET_HAILING_DROP_OFF_NAME,
  SET_HAILING_DROP_OFF_LOCATION,
  SET_HAILING_DROP_OFF_POSTAL_NUMBER,
  SET_HAILING_PAYMENT,
  SET_HAILING_PASSENGER,
  SET_EXTRA_SERVICES,
  SET_HAILING_CAR_TYPE,
  SET_PATH_DIRECTIONS,
  SET_RESERVATION_TIME,
  SET_CUSTOMER_NOTE,
  SET_IS_SEARCHING,
  SET_IS_HAILING_SUCCESS,
  SET_TAXI_LIST,
  SET_DISTANCE,
  SET_DURATION,
  SET_TEST_CAR_TEAM,
  SET_REPEATED,
  SET_REPEATED_MSG
} from "./mutation-types";

import { extractPathDirections } from "@/utils/googleUtils";

import {
  getNoDropOffTaxiList,
  getWithDropOffTaxiList
} from "@/api/hailingService";

export default {
  setHailingMode({ commit }, mode) {
    commit(SET_HAILING_MODE, mode);
  },
  setHailingPickUpName({ commit }, pickUp) {
    commit(SET_HAILING_PICK_UP_NAME, pickUp);
  },
  setHailingPickUpLocation({ commit }, pickUp) {
    commit(SET_HAILING_PICK_UP_LOCATION, pickUp);
  },
  setHailingPickUpPostalNumber({ commit }, postalNumber) {
    commit(SET_HAILING_PICK_UP_POSTAL_NUMBER, postalNumber);
  },
  setHailingDropOffName({ commit }, pickUp) {
    commit(SET_HAILING_DROP_OFF_NAME, pickUp);
  },
  setHailingDropOffLocation({ commit }, pickUp) {
    commit(SET_HAILING_DROP_OFF_LOCATION, pickUp);
  },
  setHailingDropOffPostalNumber({ commit }, postalNumber) {
    commit(SET_HAILING_DROP_OFF_POSTAL_NUMBER, postalNumber);
  },
  setHailingPayment({ commit }, payment) {
    commit(SET_HAILING_PAYMENT, payment);
  },
  setHailingPassenger({ commit }, passenger) {
    commit(SET_HAILING_PASSENGER, passenger);
  },
  setExtraServices({ commit }, extraServices) {
    commit(SET_EXTRA_SERVICES, extraServices);
  },
  setHailingCarType({ commit }, type) {
    commit(SET_HAILING_CAR_TYPE, type);
  },
  setPathDirections({ commit }, directions) {
    commit(SET_PATH_DIRECTIONS, directions);
  },
  setReservationTime({ commit }, time) {
    commit(SET_RESERVATION_TIME, time);
  },
  setCustomerNote({ commit }, note) {
    commit(SET_CUSTOMER_NOTE, note);
  },
  setIsSearching({ commit }, isSearching) {
    commit(SET_IS_SEARCHING, isSearching);
  },
  setIsHailingSuccess({ commit }, success) {
    commit(SET_IS_HAILING_SUCCESS, success);
  },
  setTaxiList({ commit }, taxiList) {
    commit(SET_TAXI_LIST, taxiList);
  },
  setDistance({ commit }, distance) {
    commit(SET_DISTANCE, distance);
  },
  setDuration({ commit }, duration) {
    commit(SET_DURATION, duration);
  },
  setTestCarTeam({ commit }, testCarTeam) {
    commit(SET_TEST_CAR_TEAM, testCarTeam);
  },
  setRepeated({ commit }, repeated) {
    commit(SET_REPEATED, repeated);
  },
  setRepeatedMsg({ commit }, repeatedMsg) {
    commit(SET_REPEATED_MSG, repeatedMsg);
  },
  async updatePathDirections({ dispatch, getters }) {
    try {
      const pickUp = getters.getPickUpLocation;
      const dropOff = getters.getDropOffLocation;
      let directionsService = new google.maps.DirectionsService();
      var origin = new google.maps.LatLng(pickUp.lat(), pickUp.lng());
      var destination = new google.maps.LatLng(dropOff.lat(), dropOff.lng());
      let request = {
        origin: origin,
        destination: destination,
        travelMode: "DRIVING"
      };
      await directionsService.route(request, async (result, status) => {
        if (status == "OK") {
          const routes = result.routes[0];
          const legs = routes.legs;
          const { distance, duration, steps } = legs[0];
          dispatch("setPathDirections", extractPathDirections(steps));
          dispatch("setDistance", distance.value);
          dispatch("setDuration", duration.value);
          let passenger_number = getters.getPassenger;
          let postal_number = getters.getPickUpPostalNumber;
          const service_list = getters.getExtraServices;
          let serviceList = null;

          // if(service_list.length !== 0){
          //   serviceList = service_list.join(',')
          // }

          const { data } = isEmpty(dropOff)
            ? await getNoDropOffTaxiList({
              on_latitude: origin.lat(),
              on_longitude: origin.lng(),
              passenger_number,
              serviceList
            })
            : await getWithDropOffTaxiList({
              on_latitude: origin.lat(),
              on_longitude: origin.lng(),
              passenger_number: passenger_number,
              postal_number: postal_number,
              distance: distance.value,
              spend_time: duration.value,
              serviceList
            });

          if (data.result === "無車輛") {
            dispatch("setTaxiList", []);
            return;
          }
          console.log(data.result);
          dispatch("setTaxiList", data.result);
        }
      });
    } catch (err) {
      console.error(err);
      dispatch("setPathDirections", []);
    }
  },

  async updateTaxiList({ dispatch, getters }) {
    try {
      const pickUp = getters.getPickUpLocation;
      const dropOff = { ...getters.getDropOffLocation };
      /* const passenger_number = getters.getPassenger;
      const postal_number = getters.getPickUpPostalNumber;
      const distance = getters.getDistance;
      const spend_time = getters.getDuration; */
      const service_list = getters.getExtraServices;

      /* let orgin = (origin = new google.maps.LatLng(pickUp.lat(), pickUp.lng())); */
      let serviceList = "";
      if (service_list.length !== 0) {
        serviceList = service_list.join(",");
      }

      const { data } = isEmpty(dropOff)
        ? await getNoDropOffTaxiList({
          on_latitude: pickUp.lat(),
          on_longitude: pickUp.lng(),
          passenger_number: getters.getPassenger,
          serviceList
        })
        : await getWithDropOffTaxiList({
          on_latitude: pickUp.lat(),
          on_longitude: pickUp.lng(),
          postal_number:getters.getPickUpPostalNumber,
          distance: getters.getDistance,
          spend_time: getters.getDuration,
          passenger_number: getters.getPassenger,
          serviceList
        });
      dispatch("setTaxiList", data.result);
    } catch (err) {
      console.error(err);
      dispatch("setTaxiList", []);
    }
  }
};
