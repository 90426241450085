<template lang="pug">
.storeLocation
  SavePanel(
    v-if="showSavePanel"
    :locationName="locationName"
    :customizedName="customizedName"
    @savePanelClosed="closeSavePanel"
    @customizedNameChanged="setCustomizedName"
    @customizedNameCleared="clearCustomizedName"
    @saveLocation="saveLocationHandler"
  )
  MapPanel(
    v-else-if="isMapMode"
    :locationLatLng="locationLatLng"
    @mapModeClosed="mapModeCloseHandler"
    @mapLocationConfirmed="mapLocationConfirmHandler"
  )
  AutoCompletePanel.storeLocation__panel.storeLocation__panel--auto(
    v-else
    :locationName="locationName"
    :predictLocations="predictLocations"
    :isLocationSelected="isLocationSelected"
    @mapModeSelected="mapModeSelectedHandler"
    @locationSelected="locationSelectHandler"
    @locationConfirmed="locationConfirmHandler"
    @autoCompleteQuery="autoCompleteQueryHandler"
    @locationNameCleared='locationNameClearHandler'
    @locationNameChanged="locationNameChangeHandler"
    @clearPredictLocations="clearPredictLocationsHandler"
  )
</template>
<script>
import AutoCompletePanel from '@/components/StoreAddress/AutoCompletePanel';
import MapPanel from '@/components/StoreAddress/MapPanel';
import SavePanel from '@/components/StoreAddress/SavePanel';
import { extractLocationsToListFormat } from '@/utils/googleUtils';
import { postNewSavedAddress } from '@/api/saveAddressService';

export default {
  name: 'StoreLocation',
  data() {
    return {
      customizedName: '',
      locationName: '',
      locationLatLng: null,
      locationPostalNumber: '',
      predictLocations: [],
      isMapMode: false,
      showSavePanel: false,
    };
  },
  computed: {
    isLocationSelected() {
      return this.locationName !== ''
        && this.locationLatLng !== null;
    },
  },
  methods: {
    mapModeSelectedHandler() {
      this.clearLocationLatLng();
      this.locationNameClearHandler();
      this.isMapMode = true;
    },
    mapModeCloseHandler() {
      this.clearLocationLatLng();
      this.locationNameClearHandler();
      this.isMapMode = false;
    },
    openSavePanel() {
      this.showSavePanel = true;
    },
    closeSavePanel() {
      this.showSavePanel = false;
    },
    setCustomizedName(name) {
      this.customizedName = name;
    },
    setLocationName(name) {
      this.locationName = name;
    },
    setLocationLatLng(LatLng) {
      this.locationLatLng = LatLng;
    },
    setLocationPostalNumber(number) {
      this.locationPostalNumber = number;
    },
    setPredictLocations(locations) {
      this.predictLocations = [...locations];
    },
    locationNameChangeHandler(name) {
      this.setLocationName(name);
    },
    locationNameClearHandler() {
      this.setLocationName('');
      this.setPredictLocations([]);
    },
    locationConfirmHandler() {
      this.openSavePanel();
    },
    clearCustomizedName() {
      this.customizedName = '';
    },
    clearLocationLatLng() {
      this.locationLatLng = null;
    },
    clearPredictLocationsHandler() {
      this.predictLocations = [];
    },
    autoCompleteQueryHandler(locations) {
      this.clearLocationLatLng();
      this.clearPredictLocationsHandler();
      this.updatePredictLocations(locations);
    },
    updatePredictLocations(locations) {
      const predictLocations = extractLocationsToListFormat(locations);
      this.setPredictLocations(predictLocations);
    },
    async locationSelectHandler(place_id) {
      const { title } = this.predictLocations
        .filter(location => location.place_id === place_id)
        .pop();

      try {
        let geocoder =  new google.maps.Geocoder()
        geocoder.geocode({ placeId: place_id }, (results, status) => {
          if (status !== "OK") {
            window.alert("Geocoder failed due to: " + status);
            return;
          }
          const { address_components, geometry } = results[0];
          const { long_name } = address_components
            .filter(({ types }) => types.includes('postal_code'))
            .pop();
            //console.log('address_components', address_components)
            //console.log('geometry', geometry)
            //console.log('long_name', long_name)
            //console.log('title', title)

            this.setLocationName(title);
            this.setLocationLatLng(geometry.location);
            this.setLocationPostalNumber(long_name);
            this.openSavePanel()
        });
      } catch(err) {
        console.error(err);
      }
    },
    mapLocationConfirmHandler({ name, location, postalNumber }) {
      //console.log(location)
      this.setLocationName(name);
      this.setLocationLatLng(location);
      this.setLocationPostalNumber(postalNumber);
      this.locationConfirmHandler();
    },
    async saveLocationHandler() {
      try {
        const LatLng = this.locationLatLng;
        await postNewSavedAddress({
          placeName: this.customizedName,
          address: this.locationName,
          latitude: LatLng.lat(),
          longitude: LatLng.lng(),
          postalCode: this.locationPostalNumber,
        });

        this.$router.replace('/SavedLocations')
      } catch(err) {
        console.error(err);
      }
    },
  },
  components: {
    MapPanel,
    AutoCompletePanel,
    SavePanel,
  },
};
</script>
<style lang="scss" scoped>
  .storeLocation {
    width: 100vw;
    height: 100vw;

  }
</style>
