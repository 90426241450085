export default {
  getHailingMode: state => state.mode,
  getPickUpName: state => state.pickUp.name,
  getPickUpLocation: state => state.pickUp.location,
  getPickUpPostalNumber: state => state.pickUp.postalNumber,
  getDropOffName: state => state.dropOff.name,
  getDropOffLocation: state => state.dropOff.location,
  getDropOffPostalNumber: state => state.dropOff.postalNumber,
  getPayment: state => state.payment,
  getPassenger: state => state.passenger,
  getExtraServices: state => state.extraServices,
  getCarType: state => state.carType,
  getCustomerNote: state => state.customerNote,
  getPathDirections: state => state.pathDirections,
  getReservationTime: state => state.reservationTime,
  getIsSearching: state => state.isSearching,
  getIsHailingSuccess: state => state.isHailingSuccess,
  getTaxiList: state => state.taxiList,
  getDuration: state => state.duration,
  getDistance: state => state.distance,
  getTestCarTeam: state => state.testCarTeam,
  getRepeated: state => state.repeated,
  getRepeatedMsg: state => state.repeatedMsg
};
