import {
  SET_ORDER_ID,
  SET_DRIVER_GPS,
  SET_PASSENGER_NAME,
  SET_RIDE_STATUS,
  SET_ON_LOCATION,
  SET_OFF_LOCATION,
  SET_DRIVER_NAME,
  SET_DRIVER_IMG,
  SET_DRIVER_PHONE,
  SET_DRIVER_STAR,
  SET_DRIVER_NOTE,
  SET_CAR_LICENSE,
  SET_CAR_BRAND,
  SET_CALL_NUMBER,
  SET_TEAM_NAME,
  SET_TEAM_PHONE,
  SET_ORDER_TIME,
  SET_ESTIMATED_TIME
} from './mutation-types';

export default {
  [SET_ORDER_ID](state, orderID) {
    state.orderID = orderID;
  },
  [SET_DRIVER_GPS](state, driverGPS) {
    state.driverGPS = driverGPS;
  },
  [SET_PASSENGER_NAME](state, passengerName) {
    state.passengerName = passengerName;
  },
  [SET_RIDE_STATUS](state, rideStatus) {
    state.rideStatus = rideStatus;
  },
  [SET_ON_LOCATION](state, onLocation) {
    state.onLocation = onLocation;
  },
  [SET_OFF_LOCATION](state, offLocation) {
    state.offLocation = offLocation;
  },
  [SET_DRIVER_NAME](state, driverName) {
    state.driverName = driverName;
  },
  [SET_DRIVER_IMG](state, driverImg) {
    state.driverImg = driverImg;
  },
  [SET_DRIVER_PHONE](state, driverStar) {
    state.driverStar = driverStar;
  },
  [SET_DRIVER_STAR](state, driverPhone) {
    state.driverPhone = driverPhone;
  },
  [SET_DRIVER_NOTE](state, driverNote) {
    state.driverNote = driverNote;
  },
  [SET_CAR_LICENSE](state, carLicense) {
    state.carLicense = carLicense;
  },
  [SET_CAR_BRAND](state, carBrand) {
    state.carBrand = carBrand;
  },
  [SET_CALL_NUMBER](state, callNumber) {
    state.callNumber = callNumber;
  },
  [SET_TEAM_NAME](state, teamName) {
    state.teamName = teamName;
  },
  [SET_TEAM_PHONE](state, teamPhone) {
    state.teamPhone = teamPhone;
  },
  [SET_ORDER_TIME](state, orderTime) {
    state.orderTime = orderTime;
  },
  [SET_ESTIMATED_TIME](state, estimatedTime) {
    state.estimatedTime = estimatedTime;
  },
};
