import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as VueGoogleMaps from 'vue2-google-maps';

import {
  googleMapKey,
} from './api/default';
import '@/mock';
import '@/font-awesome';
import VueGtm from 'vue-gtm'
import { saveBehavior } from '@/api/behavior';

Vue.use(VueGtm, {
  // container id
  id: 'GTM-MX22G35',
  // enable track or not (optional)
  // enabled: process.env.NODE_ENV === 'production',
  // display //console.logs debugs or not (optional)
  debug: true 
})
Vue.use(VueGoogleMaps, {
  load: {
    key: googleMapKey,
    libraries: 'places,geometry', // This is required if you use the Autocomplete plugin
    language: 'zh-TW'
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
});

Vue.config.productionTip = false
Vue.prototype.vue = Vue
Vue.prototype.saveBehavior = saveBehavior

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
