<template lang="pug">
.favoriteDrivers
  PageHeader(
    :title="'最愛司機列表'"
    @toLastPage="toHomePage"
  )
  p.favoriteDrivers__text 當您將該司機加入喜好司機之後，該司機將優先承接您的叫車訂單
  ForbiddenDriverSelector.favoriteDrivers__selector(
    :options="teamOptions"
    :selectTeam="selectTeam"
    :driverNumber="driverNumber"
    @teamChanged="teamChangeHandler"
    @driverNumberChanged="driverNumberChangeHandler"
    @driverConfirmed="driverConfirmHandler"
  )
  DriverSelector.favoriteDrivers__selector.favoriteDrivers__selector--driver(
    :driversList="driversList"
    :text="'移除'"
    @driverConfirmed="openBaseConfirmModal"
  )
  BaseModal.favoriteDrivers__modal(
    v-if="showNotFoundModal"
    :title="title"
    :text="text"
    @confirm="closeNotFoundModal"
  )
  ForbiddenDriverConfirmModal.favoriteDrivers__modal(
    v-if="showConfirmModal"
    :driverInfo="driverInfo"
    :modalTitle="'是否加入喜愛司機?'"
    @cancel="closeConfirmModal"
    @confirm="addFavoriteDriver"
  )
  BaseConfirmModal.favoriteDrivers__modal(
    v-if="showBaseConfirmModal"
    :title="'確定要刪除司機嗎'"
    :cancelBtnText="'取消'"
    :confirmBtnText="'確定'"
    @confirm="driverRemove"
    @cancel="closeBaseConfirmModal"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import ForbiddenDriverSelector from '@/components/ForbiddenDriverSelector';
import DriverSelector from '@/components/DriverSelector';
import BaseModal from '@/components/BaseComponent/BaseModal';
import ForbiddenDriverConfirmModal from '@/components/ForbiddenDriverConfirmModal';
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';

import { getTeamInfo, searchDriverInfo } from '@/api/teamInfoService';
import { setFavorite, getFavoriteList, removeFavorite } from '@/api/userInfoService';

export default {
  name: 'FavoriteDrivers',
  data() {
    return {
      teamOptions: [],
      title: '找不到此司機',
      text: '請確認輸入資料是否正確。',
      selectTeam: '',
      driverNumber: '',
      showBaseConfirmModal: false,
      showNotFoundModal: false,
      showConfirmModal: false,
      driversList: [],
      driverId: null,
      driverInfo: {
        callNumber: null,
        id: null,
        licence: null,
        name: null,
        note: null,
        photo: null,
        reputation: null,
        teamName: null,
      }
    };
  },
  async created() {
    try {
      const { data } = await getTeamInfo();
      this.teamOptions  =data.result
      this.getFavoriteDriverList()
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    openBaseConfirmModal(id){
      this.driverId = id
      this.showBaseConfirmModal = true
    },
    closeBaseConfirmModal(){
      this.showBaseConfirmModal = false
    },
    async driverRemove() {
      this.closeBaseConfirmModal()
      let { data } = await removeFavorite(this.driverId)
      this.driverId = null
      if(data.success) return this.getFavoriteDriverList()
    },

    async getFavoriteDriverList(){
      const { data } = await getFavoriteList();
      if(data.success) return this.driversList = data.result
      this.driversList = []
      //console.log(data)
    },
    toHomePage() {
      this.$router.push('/');
    },
    teamChangeHandler(team) {
      //console.log(team)
      this.selectTeam = team;
    },
    driverNumberChangeHandler(driverNumber) {
      this.driverNumber = driverNumber;
    },
    openNotFoundModal() {
      this.showNotFoundModal = true;
    },
    closeNotFoundModal() {
      this.showNotFoundModal = false;
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    async driverConfirmHandler() {
      if(this.selectTeam === '') return alert('請選擇車隊')
      if(this.driverNumber === '') return alert('請填入隊編/呼號')
      let driverNumber = this.driverNumber.replace(/\s+/g,"")
      try {
        let { data } = await searchDriverInfo(driverNumber, this.selectTeam);
        if(!data.success) {
          return this.openNotFoundModal()
        }
        this.driverInfo = data.result
        this.openConfirmModal();
      } catch (err) {
        console.error(err);
      }
    },
    async addFavoriteDriver(id) {
      try {
        let { data } = await setFavorite(id);
        if(data.success){
          this.title = '新增成功'
          this.text = '新增喜愛司機成功'
          this.closeConfirmModal()
          this.getFavoriteDriverList()
          return this.openNotFoundModal()
        }
      } catch (err) {
        console.error(err);
      }

    },
  },
  components: {
    BaseModal,
    PageHeader,
    DriverSelector,
    ForbiddenDriverSelector,
    ForbiddenDriverConfirmModal,
    BaseConfirmModal
  },
};
</script>
<style lang="scss" scoped>
.favoriteDrivers {
    width: 100vw;
    height: 100vh;

    &__text {
      margin: 0;
      height: 56px;
      padding: 12px 24px;
      font-size: 12px;
      line-height: 16px;
      color: #767676;
      box-sizing: border-box;
    }

    &__selector {
      margin-top: 8px;

      &--driver {
        margin-top: 0;
        height: calc(100vh - 364px);
        overflow-y: auto;
      }
    }

    &__modal {
      z-index: 1;
    }
  }
</style>
