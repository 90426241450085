<template lang="pug">
.autoComplete
  GmapAutocomplete(v-show="false")
  .autoComplete__header
    .autoComplete__banner
      BaseBtnLastPage(
        @click="toLastPage"
      )
      BaseInputAddress.autoComplete__input(
        :value="locationName"
        placeholder="輸入地點"
        @input="locationNameInputHandler"
        @inputCleared="locationNameClearedHandler"
      )
    p.autoComplete__text(
      @click="$emit('mapModeSelected')"
    ) 在地圖上設定地點
  LocationList.autoComplete__list(
    :locationItems="predictLocations"
    @locationSelected="locationSelectHandler"
  )
  .autoComplete__btnWrapper
    BaseBtnConfirm(
      v-if="isLocationSelected"
      :text="'確定'"
      @click="$emit('locationConfirmed')"
    )
</template>
<script>
import debounce from 'lodash/debounce';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';
import BaseInputAddress from '@/components/BaseComponent/BaseInputAddress';
import LocationList from '@/components/LocationList';

export default {
  name: 'AutoCompletePanel',
  props: {
    locationName: {
      type: String,
      required: true,
    },
    predictLocations: {
      type: Array,
      required: true,
    },
    isLocationSelected: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    locationNameInputHandler(event) {
      const { value } = event.target;
      this.$emit('locationNameChanged', value);
      this.queryAutoComplete(value);
    },
    locationNameClearedHandler() {
      this.$emit('locationNameCleared');
    },
    queryAutoComplete: debounce(function(value) {
      if (value === '') {
        this.$emit('clearPredictLocations');
      } else {
        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions(
          { input: value }, 
          (predictions, status) => {
            status === 'OK'
              && this.$emit('autoCompleteQuery', predictions);
          }
        );
      }
    }, 300),
    locationSelectHandler(place_id) {
      this.$emit('locationSelected', place_id);
    },
    toLastPage() {
      this.$router.back();
    },
  },
  components: {
    BaseBtnConfirm,
    BaseBtnLastPage,
    BaseInputAddress,
    LocationList,
  },
};
</script>
<style lang="scss" scoped>
  .autoComplete {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__header {
      padding: 8px;
      box-sizing: border-box;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &__banner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__input {
      width: calc(100% - 50px);
    }

    &__text {
      margin: 0;
      margin-top: 8px;
      margin-left: 56px;
      color: #ff9900;
      font-size: 12px;
    }

    &__list {
      height: calc(100vh - 180px) ;
      overflow: scroll;
    }

    &__btnWrapper {
      position: absolute;
      left: 0;
      bottom: 16px;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
</style>
