import axiosConfig from './axiosConfig';
import { sub_domain } from './default'

export const getSavedAddress = () => axiosConfig
  .get(`/line/api/web/${sub_domain}/passenger/common_address`);

export const postNewSavedAddress = addressConfig => axiosConfig
  .post(`/line/api/web/${sub_domain}/passenger/common_address`, addressConfig);

export const deleteSavedAddress = addressId => axiosConfig
  .delete(`/line/api/web/${sub_domain}/passenger/common_address${addressId}`);
