<template lang="pug">
button.chat(
  @click="$emit('click')"
)
  chatIcon.chat__icon
</template>

<script>
import chatIcon from '@/assets/images/chat.svg';

export default {
  name: 'ButtonChat',
  components: {
    chatIcon
  }
}
</script>

<style lang="scss" scoped>
  .chat {
    border: none;
    outline: none;
    width: 64px;
    height: 64px;
    border-radius: 20px;
    padding: 0;
    background-color: #FF9900;
    &__icon {
      vertical-align: middle;
      transform: scale(0.6);
    }
  }
</style>
