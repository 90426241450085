export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_DRIVER_GPS = 'SET_DRIVER_GPS';
export const SET_PASSENGER_NAME = 'SET_PASSENGER_NAME';
export const SET_RIDE_STATUS = 'SET_RIDE_STATUS';
export const SET_ON_LOCATION = 'SET_ON_LOCATION';
export const SET_OFF_LOCATION = 'SET_OFF_LOCATION';
export const SET_DRIVER_NAME = 'SET_DRIVER_NAME';
export const SET_DRIVER_IMG = 'SET_DRIVER_IMG';
export const SET_DRIVER_PHONE = 'SET_DRIVER_PHONE';
export const SET_DRIVER_STAR = 'SET_DRIVER_STAR';
export const SET_DRIVER_NOTE = 'SET_DRIVER_NOTE';
export const SET_CAR_LICENSE = 'SET_CAR_LICENSE';
export const SET_CAR_BRAND = 'SET_CAR_BRAND';
export const SET_CALL_NUMBER = 'SET_CALL_NUMBER';
export const SET_TEAM_NAME = 'SET_TEAM_NAME';
export const SET_TEAM_PHONE = 'SET_TEAM_PHONE';
export const SET_ORDER_TIME = 'SET_ORDER_TIME';
export const SET_ESTIMATED_TIME = 'SET_ESTIMATED_TIME';
