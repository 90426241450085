import {
  SET_IS_GEO_ENABLED,
  SET_CURRENT_LAT_LNG,
  SET_GEO_ERROR_STATUS,
} from './mutation-types';

export default {
  [SET_IS_GEO_ENABLED](state, isEnabled) {
    state.isGeoEnabled = isEnabled;
  },
  [SET_CURRENT_LAT_LNG](state, { lat, lng }) {
    state.currentLatLng = { lat, lng };
  },
  [SET_GEO_ERROR_STATUS](state, errorStatus) {
    state.geoErrorStatus = errorStatus;
  },
};
