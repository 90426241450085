<template lang="pug">
.hailingModeTable
  .hailingModeTable__errorBanner
    GeoErrorBanner(
      v-if="isGeoEnabled === false"
      :errorMsg="geoErrorMsg")
  .hailingModeTable__content
    .hailingModeTable__wrapper
      .hailingModeTable__modeSelector
        .hailingModeTable__mode(
          @click="modeChangeHandler('immediate')"
          :class="{'hailingModeTable__mode--selected': mode === 'immediate'}"
        ) 現在
        .hailingModeTable__mode(
          v-if="isShowReserveTab"
          @click="modeChangeHandler('reservation')"
          :class="{'hailingModeTable__mode--selected': mode === 'reservation'}"
        ) 預約
      ButtonTimeSelector.hailingModeTable__button(
        v-if="isReserveMode"
        :reservationTime="reservationTime"
        @click="$emit('openTimePicker')"
      )
      BaseBtnSetupPosition.hailingModeTable__button(
        :btnText="setupPositionBtnText"
        @click="toAddressSetupPage")
</template>
<script>
import GeoErrorBanner from "@/components/HailingModeTable/GeoErrorBanner";
import BaseBtnSetupPosition from "@/components/BaseComponent/BaseBtnSetupPosition";
import ButtonTimeSelector from "@/components/common/ButtonTimeSelector";
import { mapGetters } from "vuex";

export default {
  props: {
    mode: {
      type: String,
      required: true
    },
    isGeoEnabled: {
      type: Boolean,
      required: true
    },
    isPickupEmpty: {
      type: Boolean,
      required: true
    },
    geoErrorMsg: {
      type: String,
      required: true
    },
    isReserveMode: {
      type: Boolean,
      required: true
    },
    reservationTime: {
      required: false
    }
  },
  computed: {
    ...mapGetters({
      getTestCarTeam: "hailingInfo/getTestCarTeam"
    }),
    setupPositionBtnText() {
      return this.isPickupEmpty || this.isReserveMode
        ? "我在哪裡?"
        : "想去哪裡?";
    },
    isShowReserveTab() {
      /* 大豐車隊沒有預約服務 */
      if (
        document.URL.indexOf("dihon.line.gladmobile.com") > -1 ||
        this.getTestCarTeam === "dihon"
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    modeChangeHandler(mode) {
      this.$emit("onModeChange", mode);
    },
    toAddressSetupPage() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點擊設定上下車頁面"
      });
      this.$router.push("AddressSetup");
    }
  },
  components: {
    GeoErrorBanner,
    ButtonTimeSelector,
    BaseBtnSetupPosition
  }
};
</script>
<style lang="scss" scoped>
.hailingModeTable {
  position: absolute;
  bottom: 20px;
  width: 100%;

  &__errorBanner {
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    position: absolute;
    bottom: calc(100% + 16px);
  }

  &__content {
    padding: 0 8px;
  }

  &__wrapper {
    background: #ffffff;
    border-radius: 20px 20px 0 0;
    padding: 16px 8px;
    box-sizing: border-box;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  }

  &__modeSelector {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__mode {
    width: 164px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    box-sizing: border-box;
    font-weight: lighter;

    &--selected {
      font-weight: normal;
      border-bottom: 1px solid #ff9900;
    }
  }

  &__button {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 16px;
    }
  }
}
</style>
