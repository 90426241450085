import axiosConfig from './axiosConfig';

export const fetchEnterpiseOptions = code => axiosConfig
  .get('/enterprise/api/web/passenger_app/enterprise/list', {
    params: {
      code: code
    }
  })
  .then(res => {
    return res.data.result.list;
  })
  .catch(err => {
    console.error(err);
    return err;
  });

export const fetchTemporaryBill = authCode => axiosConfig
  .get(`/enterprise/api/web/passenger_app/signing/info`, {
    params: {
      auth_code: authCode
    }
  })
  .then(res => {
    return res.data.result;
  })
  .catch(err => {
    console.error(err);
    return err;
  });

export const fetchEmployeeIfExist = employeeData => axiosConfig
  .get(`/enterprise/api/web/passenger_app/employee/check`, {
    params: employeeData
  });

export const addBillSigning = enterpriseFromData => axiosConfig
  .post(`/enterprise/api/web/passenger_app/employee/pwd`, enterpriseFromData);