<template lang="pug">
.locationSetupMap
  GmapMap(
    ref="map"
    :center="defaultCenter"
    :zoom="17"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    :options="mapOptions"
    @bounds_changed="boundsChangeHandler"
  )
    GmapMarker(
      ref="marker"
      :position="defaultCenter"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
  .locationSetupMap__banner
    BaseBtnLastPage(
      @click="toPreviousPage"
    )
    p.locationSetupMap__text
      FontAwesomeIcon.locationSetupMap__icon--loading(
        v-if="isLoading"
        size="lg"
        :icon="['fas', 'spinner']"
      )
      span(v-else) {{ currentLocationName }}
  .locationSetupMap__btnWrapper
    BaseBtnConfirm(
      :text="'確定'"
      @click="positionConfirmHandler"
    )
</template>
<script>
import debounce from 'lodash/debounce';
import { mapGetters, mapActions } from 'vuex';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  name: 'LocationSetupMap',
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
        clickableIcons: false,
      },
      marker: {
        url: require('@/assets/images/maker/location.png'),
      },
      defaultCenter: {
        lat: 22.997195,
        lng: 120.212343,
      },
      currentCenter: null,
      currentLocationName: '',
      currentLocation: null,
      currentPostalNumber: null,
      isLoading: false,
    };
  },
  mounted(){
    this.defaultCenter.lat = this.pickUpLacation.lat()
    this.defaultCenter.lng = this.pickUpLacation.lng()
  },
  computed: {
    ...mapGetters({
      currentInput: 'autoComplete/getCurrentInput',
      pickUpLacation: 'hailingInfo/getPickUpLocation',
    }),
    isPickUpMode() {
      return this.currentInput === 'pickUp';
    },
  },
  methods: {
    ...mapActions({
      setHailingPickUpName: 'hailingInfo/setHailingPickUpName',
      setHailingDropOffName: 'hailingInfo/setHailingDropOffName',
      setHailingPickUpLocation: 'hailingInfo/setHailingPickUpLocation',
      setHailingDropOffLocation: 'hailingInfo/setHailingDropOffLocation',
      setHailingPickUpPostalNumber: 'hailingInfo/setHailingPickUpPostalNumber',
      setHailingDropOffPostalNumber: 'hailingInfo/setHailingDropOffPostalNumber',
    }),
    async boundsChangeHandler() {
      const newCenter = await this.$refs.map.$mapPromise
        .then(map => ({
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        }));
      this.updateMapCenter(newCenter);
      this.updateLocationName();
    },
    updateMapCenter({ lat, lng }) {
      this.currentCenter = { lat, lng };
      this.$refs.marker.$markerPromise
        .then(marker => {
          marker.setPosition({ lat, lng });
        });
    },
    setCurrentLocation(LatLng) {
      this.currentLocation = LatLng;
    },
    setCurrentLocationName(name) {
      this.currentLocationName = name;
    },
    setCurrentPostalNumber(number) {
      this.currentPostalNumber = number;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    updateLocationName: debounce(async function(e) {
      this.startLoading();
      try {
        let geocoder =  new google.maps.Geocoder()
        geocoder.geocode({ location: { lat:this.currentCenter.lat, lng:this.currentCenter.lng } }, (results, status) => {
            if (status === 'OK') {
              //console.log('result',results[0])
              const { address_components, geometry, formatted_address } = results[0];
              const locationName = formatted_address.replace(/^\d{3}?台灣/, '');
              const { long_name } = address_components
                .filter(component => component.types.includes('postal_code'))
                .pop();
                //console.log('locationName', locationName)
              this.setCurrentLocationName(locationName);
              let LatLng = new google.maps.LatLng(this.currentCenter.lat,this.currentCenter.lng)
              this.setCurrentLocation(LatLng);
              this.setCurrentPostalNumber(long_name);
            } else {
              this.setCurrentLocationName('');
            }
        });
      } catch(err) {
        this.setCurrentLocationName('');
      }
      this.stopLoading();
    }, 300),
    toPreviousPage() {
      this.$router.back();
    },
    updatePickUp() {
      this.setHailingPickUpName(this.currentLocationName);
      this.setHailingPickUpLocation(this.currentLocation);
      this.setHailingPickUpPostalNumber(this.currentPostalNumber);
    },
    updateDropOff() {
      this.setHailingDropOffName(this.currentLocationName);
      this.setHailingDropOffLocation(this.currentLocation);
      this.setHailingDropOffPostalNumber(this.currentPostalNumber);
    },
    positionConfirmHandler() {
      this.isPickUpMode
        ? this.updatePickUp()
        : this.updateDropOff()

      this.toPreviousPage();
    },
  },
  components: {
    BaseBtnLastPage,
    BaseBtnConfirm,
    FontAwesomeIcon,
  },
};
</script>
<style lang="scss" scoped>
  .locationSetupMap{
    position: relative;
    width: 100vw;
    height: 100vh;

    &__map {
      width: 100%;
      height: 100%;
    }

    &__banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 56px;
      padding: 0px 8px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #ffffff;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &__text {
      margin: 0;
      margin-left: 8px;
      padding: 0 8px;
      width: calc(100% - 24px);
      box-sizing: border-box;
      height: 48px;
      line-height: 48px;
      border: 1px solid #efeeea;
      border-radius: 12px;
      font-size: 12px;
    }

    &__btnWrapper {
      position: absolute;
      bottom: 16px;
      left: 0;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
    }

    &__icon {
      &--loading {
        width: 100%;
        animation: loading 2s linear infinite;
      }
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
