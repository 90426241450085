<template lang="pug">
li.reservationListItem(
  @click="reservationSelectHandler"
)
  .reservationListItem__iconWrapper(
    :class="iconWrapperClassObj"
  )
    waiting.reservationListItem__icon.reservationListItem__icon--waiting(
      v-if="isWaiting"
    )
    done.reservationListItem__icon.reservationListItem__icon--done(
      v-if="isDone"
    )
    cancel.reservationListItem__icon.reservationListItem__icon--cancel(
      v-if="isCancel"
    )
  .reservationListItem__content
    h3.reservationListItem__subtitle {{ dateString }} ({{chineseString}}) {{meridiemString}}{{ timeString }} 
    p.reservationListItem__location 從：{{onLocation}}
    p.reservationListItem__location 到：{{offLocation}}
    p.reservationListItem__text(
      :class="{'reservationListItem__text--waiting': isWaiting}"
    ) {{ statusText }}
</template>
<script>
import { getDate, getTimeString, chineseDayConverter } from '@/utils/timeUtils';
import moment from 'moment';
import waiting from '@/assets/images/access-time-24-px.svg'
import done from '@/assets/images/check-24-px.svg'
import cancel from '@/assets/images/close-24-px.svg'

export default {
  name: 'ReservationListItem',
  components: {
    waiting,
    done,
    cancel
  },
  props: {
    rideStatus: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    onLocation: {
      type: String,
      required: true,
    },
    offLocation: {
      type: String,
      required: false,
    }
  },
  computed: {
    iconWrapperClassObj() {
      return {
        'reservationListItem__iconWrapper--waiting': this.isWaiting,
        'reservationListItem__iconWrapper--done': this.isDone,
        'reservationListItem__iconWrapper--cancel': this.isCancel,
      };
    },
    isWaiting() {
      return this.rideStatus === '未完成';
    },
    isDone() {
      return this.rideStatus === '預約成功' || this.rideStatus === '已完成';
    },
    isCancel() {
      return this.rideStatus === '乘客取消';
    },
    statusText() {
      const textDict = {
        '未完成': '尚未選擇司機',
        '乘客取消': '預約取消',
        '預約成功': '預約完成',
      };

      return textDict[this.rideStatus];
    },
    dateString() {
      return getDate(this.time);
    },
    timeString() {
      return getTimeString(this.time);
    },
    meridiemString(){
      return moment(this.time).get('hour') < 12
        ? '上午'
        : '下午';
    },
    chineseString(){
      return chineseDayConverter(moment(this.time).get('day'))
    }
  },
  methods: {
    reservationSelectHandler() {
      if(this.isWaiting || this.isDone) this.$emit('reservationSelected');
    },
  },
};
</script>
<style lang="scss" scoped>
  .reservationListItem {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 16px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;


    &__iconWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;

      &--waiting {
        background-color: #ff9900;
      }

      &--done {
        background-color: #006f37;
      }

      &--cancel {
        background-color: #ffd79c;
      }

      svg {
        vertical-align: top;
        path[fill-rule="nonzero"] {
          fill: #fff;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 4px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__location {
      margin: 0;
      margin-top: 5px;
      font-size: 14px;
      color: rgba(141, 141, 141, 0.87);
    }

    &__text {
      margin: 0;
      margin-top: 5px;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      font-weight: 500;
      color: #767676;

      &--waiting {
        color: #a62900;
      }
    }
  }
</style>
