<template lang="pug">
li.carTypeListItem(
  @click="$emit('click')"
)
  .carTypeListItem__content
    carIcon.carTypeListItem__image
    .carTypeListItem__description
      p.carTypeListItem__title {{ taxiType }}
      .carTypeListItem__timeWrapper
        // clockIcon.carTypeListItem__clockIcon
        // p.carTypeListItem__text {{ time }} min
  .carTypeListItem__content.carTypeListItem__content--price(
    v-if="price !== '無下車地點'"
  )
    //p.carTypeListItem__text 預估車資
    //p.carTypeListItem__text.carTypeListItem__text--price &#36;{{ price }}
</template>
<script>
import carIcon from '@/assets/images/car-2.svg';
import clockIcon from '@/assets/images/clock.svg';

export default {
  name: 'CarTypeListItem',
  components: {
    carIcon,
    clockIcon
  },
  props: {
    taxiType: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: false,
    },
    price: {
      type: String,
      required: false,
    }
  },
}
</script>
<style lang="scss" scoped>
  .carTypeListItem {
    height: 80px;
    width: 100%;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border: solid 1px #b1b1b1;
    border-radius: 12px;

    &__content {
      display: flex;
      align-items: center;

      &--price {
        height: 100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
      }
    }

    &__description {
      margin-left: 8px;
    }

    &__title {
      margin: 0;
      margin-bottom: 10px;
      max-width: 150px;
      font-size: 16px;
      color: #ff9900;
      height: 28px;
      line-height: 28px;
    }

    &__timeWrapper {
      display: flex;
      align-items: center;
    }

    &__clockIcon {
      margin-right: 2px;
    }

    &__text {
      margin: 0;
      font-size: 12px;
      color: #767676;
      height: 16px;
      line-height: 16px;

      &--price {
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        font-weight: 500;
      }
    }
  }
</style>
