<template lang="pug">
.chat
  .chat__title 
    BaseBtnClose.chat__close__btn(
      @click="toPreview"
    )
    span.chat__title__text(
      v-if="Boolean(driverName)"
    ) {{ driverName }}
    span.chat__title__text(
      v-else
    ) 司機名稱
  ul.chat__messages
    .chat__messages__item(
      v-for="(item, index) in messageList"
      :key="`createdAt-${index}`"
    )
      .chat__messages__item__seconds(
        v-if="item.createdAt"
      )
        span {{ messageDate(item.createdAt) }}
        span  &#8226 
        span {{ messageTime(item.createdAt) }}
      .chat__messages__item__driver(
        v-if="checkPassengerId(item.passengerId)"
      )
        div.chat__messages__item__photo__wrapper(
          v-if="Boolean(driverImg)"
        )
          img.chat__messages__item__photo(
            :src="driverImg"
          )
        div.chat__messages__item__photo__wrapper(
          v-else
        )
          img.chat__messages__item__photo(
            src="@/assets/images/driver.png"
          )
        div.chat__messages__item__text {{item.message}}
      .chat__messages__item__passenger(
        v-else
      )
        div.chat__messages__item__text {{item.message}}
        div.chat__messages__item__status(
          v-if="!item.createdAt"
        ) 傳送中...
        div.chat__messages__item__status(
          v-if="item.createdAt"
        ) 已傳送
  .chat__input
    .chat__input__wrapper
      input(
        v-model="message"
        id="input1"
        type="text"
        placeholder="輸入文字"
      )
      button.chat__send(
        @click="send"
        :disabled="!sendButtonDisabled"
      )
        div.chat__send__icon(
          :class="{'disabled': !sendButtonDisabled }"
        )
  BaseBtnScroll(
    :isShow="baseBtnScrollIsShow"
    @click.native="scrollToBottom"
  )
</template>
<script>
import firebase from '@/utils/firebaseUtils';
// import ChatItem from '../components/Chat/ChatItem';
import BaseBtnClose from '@/components/BaseComponent/BaseBtnClose';
import BaseBtnScroll from '@/components/BaseComponent/BaseBtnScroll';
import { getDate, getLTTime } from '@/utils/timeUtils';
import { mapGetters, mapActions } from 'vuex';
import { getHailingDriverInfo } from '@/api/hailingService';

export default {
    components: {
      BaseBtnClose,
      BaseBtnScroll,
      // ChatItem
    },
    created(){
      this.distinguishUserEnterPoint();
      this.addScrollListener();
      this.getDriverInfo();
      this.getMessageList();
    },
    data(){
      return {
        message: '',
        messageList: [],
        scrollY: null,
        pageEntered: false
      }
    },
    methods: {
      ...mapActions({
        setDriverGPS: 'orderInfo/setDriverGPS',
        updateOrderInfo: 'orderInfo/updateOrderInfo',
        setHailingPickUpLocation: 'hailingInfo/setHailingPickUpLocation',
        setHailingDropOffLocation: 'hailingInfo/setHailingDropOffLocation',
        setOrderID: 'orderInfo/setOrderID'
      }),
      async getDriverInfo() {
        const orderID = this.orderID ? this.orderID.toString() : '2';
        try {
          const { data } = await getHailingDriverInfo(orderID, 'instant');
          this.updateOrderInfo({ ...data.result });
        }catch(err) {
          console.error('fetch 失敗', err);
        }
      },
      checkPassengerId(id){
        //console.log('id', id)
        if(id === null || id === 'null') return true;
        return false;
      },
      getMessageList() {
        const orderID = this.orderID ? this.orderID.toString() : '2';
        firebase.db.collection("Message").doc(orderID).collection("MessageList").orderBy("createdAt", "asc")
        .onSnapshot({
          includeMetadataChanges: true
        }, doc => {
          this.messageList = [];
          doc.docs.forEach(change => {
            // console.log('==== getMessageList ====', orderID)
            // console.log('data', change.data())
            this.messageList.push(change.data());
          })
          this.$nextTick(() => {
            if(!this.pageEntered) this.scrollToBottom();
            // console.log('scrollY', this.scrollY);
            if(this.scrollY < 50) this.scrollToBottom();
            this.pageEntered = true; // 只有在進頁面那一次渲染訊息，才需要 scroll 到底部
          })
        })
      },
      async send(){
        const orderID = this.orderID ? this.orderID.toString() : '2';
        // console.log('orderID', orderID);
        if(this.message === '') return alert('請填訊息');
        let messageObject = {
          createdAt: firebase.dateNow(),
          driverId: null,
          message: this.message,
          orderId: orderID,
          passengerId: '2',
          task: 'instant'
        }
        const citiesRef = firebase.db.collection('Message').doc(orderID).collection("MessageList");
        citiesRef.add(messageObject)
        .then(docRef => {
          //console.log("Document written with ID: ", docRef.id);
          this.message = '';
          this.scrollToBottom();
        })
        .catch(error => {
          console.error("Error adding document: ", error);
          alert('傳送失敗，請重新整理');
        });
      },
      messageDate(createAt) {
        const timestamp = (createAt?.seconds) * 1000;
        return getDate(timestamp).replace('/', '-');
      },
      messageTime(createAt) {
        const timestamp = (createAt?.seconds) * 1000;
        return getLTTime(timestamp);
      },
      addScrollListener() {
        window.addEventListener('scroll', this.getScrollY);
      },
      removeScrollLitener() {
        window.removeEventListener('scroll', this.getScrollY);
      },
      scrollToBottom() {
        const scrollYBottom = document.documentElement.scrollHeight - window.innerHeight
        window.scrollTo(0, scrollYBottom);
      },
      getScrollY() {
        const pageYOffset = window.pageYOffset + window.innerHeight;
        const scrollHeight = document.documentElement.scrollHeight;
        const scrollY = scrollHeight - pageYOffset;

        this.scrollY = scrollY;
        //console.log('scrollY', this.scrollY);
      },
      toPreview() {
        this.$router.go(-1);
      },
      distinguishUserEnterPoint() {
        // console.log('orderID', this.$route.query.id);
        if(this.$route.query.id !== undefined) {
          const orderID = this.$route.query.id;
          // console.log('====== setOrderID ======', orderID);
          this.setOrderID(orderID);
          // console.log('====== After setOrderID ======', this.orderID);
          // console.log('getter orderID', this.orderID);
        }
      }
    },
    computed: {
      baseBtnScrollIsShow() {
        return this.scrollY > 50;
      },
      sendButtonDisabled() {
        return Boolean(this.message);
      },
      ...mapGetters({
        driverName: 'orderInfo/getDriverName',
        driverImg: 'orderInfo/getDriverImg',
        orderID: 'orderInfo/getOrderID'
      })
    },
    beforeDestroy() {
      this.removeScrollLitener();
    }
}
</script>

<style lang="scss" scoped>
.chat {
  &__title {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background-color: #FF9900;
    &__text {
      vertical-align: middle;
      line-height: 60px;
      margin-left: 36px;
    }
  }
  &__close__btn {
    vertical-align: middle;
    margin-left: 22px;
    background-color: transparent;
    box-shadow: none;
    img {
      vertical-align: middle;
    }
  }
  &__messages {
    box-sizing: border-box;
    height: auto;
    min-height: calc(100vh - 80px);
    overflow: scroll;
    background-color: #F2F2F2;
    margin: 0 0 75px 0;
    padding: 70px 0 30px 0;
    &__item {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
      font-family: Roboto;
      font-size: 15px;
      line-height: 20px;
      &__seconds {
        text-align: center;
        margin-bottom: 15px;
        font-size: 11px;
        line-height: 16px;
        color: #00000088;
      }
      &__driver {
        margin-bottom: 10px;
      }
      &__passenger {
        text-align: right;
        height: fit-content;
        margin-bottom: 10px;
      }
      &__photo {
        width: 100%;
        vertical-align: middle;
        &__wrapper {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 9999px;
          overflow: hidden;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      &__text {
        display: inline-block;
        max-width: 70%;
        vertical-align: middle;
        border-radius: 20px;
        padding: 11px;
        background-color: #FFF;
        text-align: left;
        word-wrap: break-word;
      }
      &__status {
        margin: 10px 5px 0 0;
        font-family: Roboto;
        font-size: 11px;
        line-height: 16px;
        color: #00000088;
      }
    }
  }
  &__input {
    position: fixed;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 75px;
    vertical-align: middle;
    background-color: #FFF;
    &__wrapper {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      input {
        resize: none;
        &:focus, &:active {
          outline: none;
        }
        width: 70%;
        margin-left: 10px;
        padding: 5px 15px;
        border: none;
        display: inline-block;
      }
      button {
        margin: 0 10px;
        display: inline-block;
        &:focus, &:active {
          outline: none;
        }
      }
    }
  }
  &__send {
    border: none;
    vertical-align: middle;
    background-color: transparent;
    &__icon {
      position: relative;
      width: 0;
      border-left: #FF9900 solid 24px;
      border-right: 0;
      border-top: transparent solid 9px;
      border-bottom: transparent solid 9px;
      &::before {
        content: "";
        position: absolute;
        left: -24px;
        top: -2px;
        display: block;
        width: 0;
        border-left: #FFF solid 18px;
        border-right: 0;
        border-top: transparent solid 2px;
        border-bottom: transparent solid 2px;
      }
    }
    .disabled {
      border-left: #C4C4c4 solid 24px;
    }
  }
}
</style>
