<template lang="pug">
li.menuListLink(
  @click="$emit('click')"
)
  slot(name="icon")
  .menuListLink__text {{ text }}
</template>
<script>

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .menuListLink {
    list-style: none;
    margin: 0;
    padding-left: 16px;
    height: 44px;
    display: flex;
    align-items: center;

    &__text {
      font-size: 14px;
      font-weight: 500;
      margin-left: 32px;
    }
  }
</style>
