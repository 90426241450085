<template lang="pug">
.pathDirectionMap
  BaseBtnLastPage.pathDirectionMap__btn.pathDirectionMap__btn--lastPage(
    v-if="showLastPageBtn"
    @click="toLastPage"
  )
  GmapMap(
    ref="map"
    :zoom="14"
    :center="center"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    :options="mapOptions"
  )
    GmapMarker(
      :position="pickUp"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
    GmapInfoWindow(
      :position="pickUp"
      :options="infoWindowOptions"
    ) 上車: {{pickUpName}}
    GmapMarker(
      v-if="dropOff"
      :position="dropOff"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
    GmapInfoWindow(
      v-if="dropOff"
      :position="dropOff"
      :options="infoWindowOptions"
    ) 下車: {{dropOffName}}
    GmapPolyline(
      v-if="dropOff"
      :path="pathDirections"
      :options="{ strokeColor:'#ff9900'}"
    )
  BaseBtnCenterMap.pathDirectionMap__btn.pathDirectionMap__btn--centerMap(
    v-if="showCenterMapBtn"
    @click="centerMap"
  )
</template>
<script>
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';
import BaseBtnCenterMap from '@/components/BaseComponent/BaseBtnCenterMap';

export default {
  props: {
    pickUpName: {
      type: String,
      /* required: true, */
    },
    dropOffName: {
      type: String,
      required: false,
    },
    pickUp: {
      type: Object,
      required: true,
    },
    dropOff: {
      type: [Object, null],
      required: false,
    },
    pathDirections: {
      type: Array,
      required: true,
    },
    showLastPageBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCenterMapBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
        clickableIcons: false,
      },
      marker: {
        url: require('@/assets/images/maker/location.png'),
      },
      infoWindowOptions:{
        pixelOffset: {
          width: 0,
          height: -40,
        }
      },
    };
  },
  computed: {
    center() {
      if (this.dropOff === null) {
        try{
          return {
            lat: this.pickUp.lat(),
            lng: this.pickUp.lng(),
          }
        }catch(err){
          return {
            lat: this.pickUp.lat,
            lng: this.pickUp.lng,
          }
        }
      }
      try{
        return {
          lat: (this.pickUp.lat() + this.dropOff.lat()) / 2,
          lng: (this.pickUp.lng() + this.dropOff.lng()) / 2,
        };
      }catch(err){
        return {
          lat: (this.pickUp.lat + this.dropOff.lat) / 2,
          lng: (this.pickUp.lng + this.dropOff.lng) / 2,
        };
      }
    },
  },
  methods: {
    toLastPage() {
      this.$router.back();
    },
    centerMap() {
      this.$refs.map.$mapPromise
        .then((map) => {
          map.panTo(this.center);
        });
    },
  },
  components: {
    BaseBtnLastPage,
    BaseBtnCenterMap,
  },
};
</script>
<style lang="scss" scoped>
  .pathDirectionMap {
    position: relative;

    &__btn {
      position: absolute;
      z-index: 1;

      &--lastPage {
        top: 8px;
        left: 8px;
      }

      &--centerMap {
        bottom: 16px;
        right: 8px;
      }
    }
  }
</style>
