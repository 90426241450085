<template>
    <div>
        Line Pay {{title}}
    </div>
</template>
<script>
import { immediateLinePay, checkLinePay } from '@/api/mobilePay';

export default {
    data(){
        return {
            title: '等待付款中...'
        }
    },
    mounted(){
        this.checkLinePay(this.$route.query.verification_code)
    },
    methods:{
        async checkLinePay(code){
            let checkLinePayResult = await checkLinePay(code)
            if(checkLinePayResult.data.result){
                this.title = '付款完成，請自行關閉瀏覽器'
                return
            }
            setTimeout(( () => {
                this.getPrime(code)
            }), 2000);
        },
        async getPrime(code){
            TPDirect.linePay.getPrime(async(result) => {
                if(code === '') return alert('缺少驗證碼')
                if(result.prime === '' || result.prime === null) return alert('缺少prime值')
                let payload ={
                    prime: result.prime,
                    verificationCode: code
                }
                let linePayResult = await immediateLinePay(payload)
                if(!linePayResult.data.success) return alert('讀取line url失敗')
                TPDirect.redirect(linePayResult.data.result)
            })
        }
    }
}
</script>