import {
  SET_HAILING_MODE,
  SET_HAILING_PICK_UP_NAME,
  SET_HAILING_PICK_UP_LOCATION,
  SET_HAILING_PICK_UP_POSTAL_NUMBER,
  SET_HAILING_DROP_OFF_NAME,
  SET_HAILING_DROP_OFF_LOCATION,
  SET_HAILING_DROP_OFF_POSTAL_NUMBER,
  SET_HAILING_PAYMENT,
  SET_HAILING_PASSENGER,
  SET_EXTRA_SERVICES,
  SET_HAILING_CAR_TYPE,
  SET_PATH_DIRECTIONS,
  SET_RESERVATION_TIME,
  SET_CUSTOMER_NOTE,
  SET_IS_SEARCHING,
  SET_IS_HAILING_SUCCESS,
  SET_TAXI_LIST,
  SET_DISTANCE,
  SET_DURATION,
  SET_EXTRA_SERVICES_NAME,
  SET_TEST_CAR_TEAM,
  SET_REPEATED,
  SET_REPEATED_MSG
} from "./mutation-types";

export default {
  [SET_HAILING_MODE](state, mode) {
    state.mode = mode;
  },
  [SET_HAILING_PICK_UP_NAME](state, name) {
    state.pickUp.name = name;
  },
  [SET_HAILING_PICK_UP_LOCATION](state, location) {
    state.pickUp.location = location;
  },
  [SET_HAILING_PICK_UP_POSTAL_NUMBER](state, postalNumber) {
    state.pickUp.postalNumber = postalNumber;
  },
  [SET_HAILING_DROP_OFF_NAME](state, name) {
    state.dropOff.name = name;
  },
  [SET_HAILING_DROP_OFF_LOCATION](state, location) {
    state.dropOff.location = location;
  },
  [SET_HAILING_DROP_OFF_POSTAL_NUMBER](state, postalNumber) {
    state.dropOff.postalNumber = postalNumber;
  },
  [SET_HAILING_PAYMENT](state, payment) {
    state.payment = payment;
  },
  [SET_HAILING_PASSENGER](state, passenger) {
    state.passenger = passenger;
  },
  [SET_EXTRA_SERVICES](state, extraServices) {
    state.extraServices = [...extraServices];
  },
  [SET_EXTRA_SERVICES_NAME](state, extraServicesName) {
    state.extraServicesName = [...extraServicesName];
  },
  [SET_HAILING_CAR_TYPE](state, type) {
    state.carType = type;
  },
  [SET_PATH_DIRECTIONS](state, directions) {
    state.pathDirections = [...directions];
  },
  [SET_RESERVATION_TIME](state, time) {
    state.reservationTime = time;
  },
  [SET_CUSTOMER_NOTE](state, note) {
    state.customerNote = note;
  },
  [SET_IS_SEARCHING](state, isSearching) {
    state.isSearching = isSearching;
  },
  [SET_IS_HAILING_SUCCESS](state, success) {
    state.isHailingSuccess = success;
  },
  [SET_TAXI_LIST](state, taxiList) {
    // state.taxiList = [...taxiList];
    state.taxiList = [taxiList];
  },
  [SET_DISTANCE](state, distance) {
    state.distance = distance;
  },
  [SET_DURATION](state, duration) {
    state.duration = duration;
  },
  [SET_TEST_CAR_TEAM](state, testCarTeam) {
    state.testCarTeam = testCarTeam;
  },
  [SET_REPEATED](state, repeated) {
    state.repeated = repeated;
  },
  [SET_REPEATED_MSG](state, repeatedMsg) {
    state.repeatedMsg = repeatedMsg;
  }
};
