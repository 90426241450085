import {
  SET_ORDER_ID,
  SET_DRIVER_GPS,
  SET_PASSENGER_NAME,
  SET_RIDE_STATUS,
  SET_ON_LOCATION,
  SET_OFF_LOCATION,
  SET_DRIVER_NAME,
  SET_DRIVER_IMG,
  SET_DRIVER_PHONE,
  SET_DRIVER_STAR,
  SET_DRIVER_NOTE,
  SET_CAR_LICENSE,
  SET_CAR_BRAND,
  SET_CALL_NUMBER,
  SET_TEAM_NAME,
  SET_TEAM_PHONE,
  SET_ORDER_TIME,
  SET_ESTIMATED_TIME
} from './mutation-types';

export default {
  setOrderID({ commit }, orderID) {
    commit(SET_ORDER_ID, orderID);
  },

  setDriverGPS({ commit }, driverGPS) {
    commit(SET_DRIVER_GPS, driverGPS);
  },

  updateOrderInfo({ dispatch }, orderConfig ) {
    const {
      passengerName,
      rideStatus,
      onLocation,
      offLocation,
      driverName,
      driverImg,
      driverStar,
      driverPhone,
      driverNote,
      carLicense,
      carBrand,
      callNumber,
      teamName,
      teamPhone,
      orderTime,
    } = orderConfig;

    dispatch('setPassengerName', passengerName);
    dispatch('setRideStatus', rideStatus);
    dispatch('setOnLocation', onLocation);
    dispatch('setOffLocation', offLocation);
    dispatch('setDriverName', driverName);
    dispatch('setDriverImg', driverImg);
    dispatch('setDriverPhone', driverStar);
    dispatch('setDriverStar', driverPhone);
    dispatch('setDriverNote', driverNote);
    dispatch('setCarLicense', carLicense);
    dispatch('setCarBrand', carBrand);
    dispatch('setCallNumber', callNumber);
    dispatch('setTeamName', teamName);
    dispatch('setTeamPhone', teamPhone);
    dispatch('setOrderTime', orderTime);
  },

  setPassengerName({ commit }, passengerName) {
    commit(SET_PASSENGER_NAME, passengerName);
  },

  setRideStatus({ commit }, rideStatus) {
    commit(SET_RIDE_STATUS, rideStatus);
  },

  setOnLocation({ commit }, onLocation) {
    commit(SET_ON_LOCATION, onLocation);
  },

  setOffLocation({ commit }, offLocation) {
    commit(SET_OFF_LOCATION, offLocation);
  },

  setDriverName({ commit }, driverName) {
    commit(SET_DRIVER_NAME, driverName);
  },

  setDriverImg({ commit }, driverImg) {
    commit(SET_DRIVER_IMG, driverImg);
  },

  setDriverPhone({ commit }, driverPhone) {
    commit(SET_DRIVER_PHONE, driverPhone);
  },

  setDriverStar({ commit }, driverStar) {
    commit(SET_DRIVER_STAR, driverStar);
  },

  setDriverNote({ commit }, driverNote) {
    commit(SET_DRIVER_NOTE, driverNote);
  },

  setCarLicense({ commit }, carLicense) {
    commit(SET_CAR_LICENSE, carLicense);
  },

  setCarBrand({ commit }, carBrand) {
    commit(SET_CAR_BRAND, carBrand);
  },

  setCallNumber({ commit }, callNumber) {
    commit(SET_CALL_NUMBER, callNumber);
  },

  setTeamName({ commit }, teamName) {
    commit(SET_TEAM_NAME, teamName);
  },

  setTeamPhone({ commit }, teamPhone) {
    commit(SET_TEAM_PHONE, teamPhone);
  },

  setOrderTime({ commit }, orderTime) {
    commit(SET_ORDER_TIME, orderTime);
  },
  setEstimatedTime({ commit }, estimatedTime) {
    commit(SET_ESTIMATED_TIME, estimatedTime);
  },
};
