<template lang="pug">
.section.section--bottom
  .section__content.section__content--bottom(
    @click="checkDetailPage"
  )
    .section__status
      p.section__text {{ stateString }}
      p.section__text.section__text--price(
        v-if="isNotCanceled"
      ) {{actualPrice}}
    .section__checkDetail(v-if="isNotCanceled && !isDetail")
      p.section__text.section__text--checkDetail 查看行程詳細資訊
      rightArrowIcon.section__icon
</template>
<script>
import rightArrowIcon from '@/assets/images/keyboard-arrow-right-24-px.svg';

export default {
  name: 'SectionBottom',
  components: {
    rightArrowIcon
  },
  props: {
    actualPrice: {
      type: Number,
    },
    isDetail: {
      type: Boolean,
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    stateString() {
      return this.isCanceled
        ? '已取消'
        : '此趟車資';
    },
    isNotCanceled() {
      return this.isCanceled === false;
    },
  },
  methods: {
    checkDetailPage() {
      this.isNotCanceled
        && this.$emit('checkDetailPage');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingRecordSection';

.section__icon {
  path[fill="#000"] {
    fill: #ff9900;
  }
}
</style>
