<template lang="pug">
.bill-signing
  PageHeader.bill-signing__pageHeader(
    :lastPageBtnIsShow="!(currentStep === 1)"
    :title="stepTitle[currentStep - 1]"
    @toLastPage="preStep"
  )
  StepProgressBar.bill-signing__stepProgressBar(
    :steps="steps"
    :currentStep="currentStep"
    @setStep="setStep"
  )
  .bill-signing__inputSerialNumber(
    v-if="currentStep === 1"
  )
    BaseSelector(
      :title="'選擇公司'"
      :options="formatedEnterpriseOptions"
      :value="enterpriseID"
      @optionSelected="selectEnterprise"
    )
    p.bill-signing__inputLabel 輸入工號
    BaseInput(
      :value="jobNumber"
      :placeholder="'輸入工號(需注意大小寫)'"
      @input="setJobNumber($event.target.value.trim())"
    )
    .bill-signing__validate-error-text(
      v-if="jobNumberValidation"
    ) 僅限英文及數字
  .bill-signing__ensureBill(
    v-if="currentStep === 2"
  )
    .bill-signing__field
      p.bill-signing__field__title 企業名稱
      p.bill-signing__field__text {{ enterpriseName }}
    .bill-signing__field
      p.bill-signing__field__title 工號
      p.bill-signing__field__text {{ jobNumber }}
    .bill-signing__field
      p.bill-signing__field__title 員工姓名
      p.bill-signing__field__text {{ employeeName }}
    .bill-signing__field
      p.bill-signing__field__title 司機
      p.bill-signing__field__text {{ driverName }}
    .bill-signing__field.bill-signing__mutable-field
      p.bill-signing__field__title 上車地點
      BaseInput(
        :value="onLocation"
        :placeholder="'輸入上車地點'"
        @input="setOnLocation($event.target.value.trim())"
      )
    .bill-signing__field.bill-signing__mutable-field
      p.bill-signing__field__title 下車地點
      BaseInput(
        :value="offLocation"
        :placeholder="'輸入下車地點'"
        @input="setOffLocation($event.target.value.trim())"
      )
    .bill-signing__field.bill-signing__mutable-field
      p.bill-signing__field__title 金額
      BaseInput(
        :value="actualFare"
        :placeholder="'輸入金額'"
        :inputType="'number'"
        @input="setActualFare($event.target.value.trim())"
      )
      span.bill-signing__unit 元
    .bill-signing__validate-error-text(
      v-if="priceValidation"
    ) 僅限正整數
    .bill-signing__comment
      p.bill-signing__field__title 乘客備註
      BaseInput(
        :value="passengerNote"
        :placeholder="''"
        @input="setPassengerNote($event.target.value)"
      )
  .bill-signing__validation(
    v-if="currentStep === 3"
  )
    p.bill-signing__subtitle 輸入付款密碼
    BaseInput(
      :value="password"
      :placeholder="'請輸入付款密碼(需注意大小寫)'"
      @input="setPassword($event.target.value.trim())"
    )
  .bill-signing__baseBtnNextStepWrapper
    BaseBtnNextStep.bill-signing__baseBtnNextStep(
      :text="'下一步'"
      :disabled="isNextStepButtonDisabled"
      v-if="currentStep < steps.length"
      @click="nextStep"
    )
    BaseBtnConfirm.bill-signing__baseBtnConfirm(
      :text="'驗證'"
      :disabled="iscConfirmButtonDisabled"
      v-if="currentStep === steps.length"
      @click="confirm"
    )
  BaseCoverModal(v-if="addBillSigningResult")
    p.bill-signing__closeGuide 若需離開請關閉頁面
    .bill-signing__result
      div(v-if="addBillSigningResult === 1")
        successIcon
        p.bill-signing__result__text 電子帳單支付成功
      //- div(v-if="addBillSigningResult === 2")
      //-   failureIcon
      //-   p.bill-signing__result__text 電子帳單支付失敗
      //-     br
      //-     | 請再試一次

  BaseModal(
    v-if="passwordError"
    title="密碼錯誤"
    text="請輸入正確的密碼"
    @confirm="passwordError = false"
  )

  BaseModal(
    v-if="employIsNotExist"
    :title="無此員工資料"
    @confirm="employIsNotExist = false"
  )
    div.bill-signing__failureIcon(slot="icon")
      failureIcon

  BaseModal(
    v-if="isDisplayReason"
    :title="reason"
    @confirm="isDisplayReason = false"
  )
    div.bill-signing__failureIcon(slot="icon")
      failureIcon
</template>

<script>
import StepProgressBar from "@/components/common/StepProgressBar";
import PageHeader from "@/components/common/PageHeader";
import BaseSelector from "@/components/BaseComponent/BaseSelector";
import BaseInput from "@/components/BaseComponent/BaseInput";
import BaseBtnNextStep from "@/components/BaseComponent/BaseBtnNextStep";
import BaseBtnConfirm from "@/components/BaseComponent/BaseBtnConfirm";
import BaseCoverModal from "@/components/BaseComponent/BaseCoverModal";
import BaseModal from "@/components/BaseComponent/BaseModal";
import successIcon from "@/assets/images/success.svg";
import failureIcon from "@/assets/images/failure.svg";

import { mapGetters, mapActions, mapMutations } from "vuex";
import {
  letterOrNumberOnly,
  positiveInteger
} from "@/utils/validationUtils.js";

export default {
  name: "BillSigning",
  components: {
    StepProgressBar,
    PageHeader,
    BaseSelector,
    BaseInput,
    BaseBtnNextStep,
    BaseBtnConfirm,
    BaseCoverModal,
    BaseModal,
    successIcon,
    failureIcon
  },
  data() {
    return {
      stepTitle: ["輸入員工編號", "確認簽單", "輸入驗證碼"],
      steps: [1, 2, 3],
      currentStep: 1,
      addBillSigningResult: false,
      employIsNotExist: false,
      passwordError: false,
      reason: "",
      isDisplayReason: false
    };
  },
  methods: {
    ...mapMutations({
      setEnterpriseID: "billSigning/enterpriseID",
      setEnterpriseName: "billSigning/enterpriseName",
      setEmployeeID: "billSigning/employeeID",
      setJobNumber: "billSigning/jobNumber",
      setOnLocation: "billSigning/onLocation",
      setOffLocation: "billSigning/offLocation",
      setActualFare: "billSigning/actualFare",
      setPassword: "billSigning/password",
      setPassengerNote: "billSigning/passengerNote",
      setEmployeeName: "billSigning/employeeName",
      setDriverName: "billSigning/driverName"
    }),
    ...mapActions({
      fetchEmployeeIfExist: "billSigning/fetchEmployeeIfExist",
      addBillSigning: "billSigning/addBillSigning"
    }),
    selectEnterprise(value) {
      this.setEnterpriseID(value);
      const enterpriseName = this.formatedEnterpriseOptions.find(
        item => item.value === value
      ).description;
      this.setEnterpriseName(enterpriseName);
    },
    async nextStep() {
      if (this.currentStep === this.steps.length) return;
      if (this.currentStep === 1) {
        try {
          const { data } = await this.fetchEmployeeIfExist({
            id: this.enterpriseID,
            job_number: this.jobNumber,
            type: "jobnumber",
            driver_id: this.driverID
          });

          this.setEmployeeID(data.result.employeeId);
          this.setEmployeeName(data.result.employeeName);

          this.currentStep++;

          // 新增提醒文字
          if (data.result.reason !== "") {
            this.reason = data.result.reason;
            this.isDisplayReason = true;
          }
          return;
        } catch (err) {
          console.log("fetchEmployeeIfExist", err);
          if (err.response.status === 403) {
            this.employIsNotExist = true;
          }
        }
      }
      if (this.currentStep === 2) {
        this.currentStep++;
        return;
      }
    },
    async confirm() {
      try {
        await this.addBillSigning({
          enterpriseId: this.enterpriseID,
          onAddress: this.onLocation,
          offAddress: this.offLocation,
          passengerNote: this.passengerNote,
          actualFare: this.actualFare,
          driverId: this.driverID,
          employeeId: this.employeeID,
          pwd: this.password
        });
        this.addBillSigningResult = 1;
      } catch (err) {
        console.log("addBillSigning", err);
        if (err.response.status === 403) this.passwordError = 2;
      }
    },
    setStep(step) {
      return;
    },
    preStep() {
      if (this.currentStep === 1) return;
      this.currentStep--;
    },
    stepOneValidation() {
      let ifPassed = true;
      if (this.jobNumber) {
        ifPassed = !letterOrNumberOnly(this.jobNumber);
        if (this.enterpriseID && this.jobNumber) {
          return ifPassed;
        }
      }
      return true;
    },
    stepTwoValidation() {
      let ifPassed = true;
      if (this.actualFare) {
        ifPassed = !positiveInteger(this.actualFare);
        if (this.actualFare.toString() && this.onLocation && this.offLocation) {
          return ifPassed;
        }
      }
      return true;
    },
    stepThreeValidation() {
      if (this.password) return false;
      return true;
    }
  },
  computed: {
    ...mapGetters({
      enterpriseOptions: "billSigning/enterpriseOptions",
      driverID: "billSigning/driverID",
      onLocation: "billSigning/onLocation",
      offLocation: "billSigning/offLocation",
      actualFare: "billSigning/actualFare",
      enterpriseID: "billSigning/enterpriseID",
      enterpriseName: "billSigning/enterpriseName",
      employeeID: "billSigning/employeeID",
      jobNumber: "billSigning/jobNumber",
      password: "billSigning/password",
      passengerNote: "billSigning/passengerNote",
      employeeName: "billSigning/employeeName",
      driverName: "billSigning/driverName"
    }),
    jobNumberValidation() {
      return !letterOrNumberOnly(this.jobNumber);
    },
    priceValidation() {
      return !positiveInteger(this.actualFare);
    },
    isNextStepButtonDisabled() {
      if (this.currentStep === 1) {
        return this.stepOneValidation();
      }
      if (this.currentStep === 2) {
        return this.stepTwoValidation();
      }
    },
    iscConfirmButtonDisabled() {
      return this.stepThreeValidation();
    },
    formatedEnterpriseOptions() {
      const options = this.enterpriseOptions.map(item => {
        return {
          description: item.name,
          value: item.enterpriseId,
          isPlaceHolder: false
        };
      });
      options.unshift({
        description: "選擇公司",
        value: 0,
        isPlaceHolder: true
      });
      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
.bill-signing {
  &__pageHeader {
    margin-bottom: 15px;
  }

  &__stepProgressBar {
    margin-bottom: 30px;
  }

  &__inputSerialNumber,
  &__ensureBill,
  &__validation {
    padding: 0 16px;
    margin-bottom: 46px;
  }

  &__inputLabel {
    color: #767676;
    font-size: 12px;
  }

  &__baseBtnNextStepWrapper {
    padding: 0 8px;
  }

  &__field {
    margin-bottom: 5px;

    &__title {
      display: inline-block;
      width: 50px;
      margin: 0;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #767676;
    }

    &__text {
      display: inline-block;
      margin: 0;
      margin-left: 16px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__mutable-field {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .bill-signing__field__title {
      flex: 0 1 80px;
    }

    .bill-signing__unit {
      margin-left: 10px;
      font-size: 14px;
    }
  }

  &__baseBtnConfirm {
    position: absolute;
    bottom: 40px;
    width: 95%;
  }

  &__closeGuide {
    text-align: center;
    margin-bottom: 95px;
    font-weight: 500;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__result {
    text-align: center;

    &__text {
      font-weight: 500;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.87);
    }
  }

  &__validate-error-text {
    font-size: 12px;
    color: #f00;
    padding-left: 5px;
  }

  &__failureIcon {
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
