<template lang="pug">
.savedLocations
  PageHeader(
    :title="'選擇地點'"
    @toLastPage="toPreviousPage"
  )
  .savedLocations__content
    SavedLocationList.savedLocations__list(
      :locationItems="savedLocstions"
      @locationSelected="locationSelectHandler"
    )
    ListItemSave.savedLocations__listItem(
      :title="'新增儲存地點'"
      @click="toStoreLocationPage"
    )
  Loading(
    v-if="isLoading"
  )
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import SavedLocationList from '@/components/SavedLocationList';
import PageHeader from '@/components/common/PageHeader';
import ListItemSave from '@/components/common/ListItemSave';
import { getSavedAddress } from '@/api/saveAddressService';
import Loading from '@/components/common/Loading';

export default {
  name: 'SavedLocations',
  data() {
    return {
      savedLocstions: [],
      isLoading: false
    };
  },
  computed: {
    ...mapGetters({
      currentInput: 'autoComplete/getCurrentInput',
    }),
    isPickUpMode() {
      return this.currentInput === 'pickUp';
    },
  },
  methods: {
    ...mapActions({
      setHailingPickUpName: 'hailingInfo/setHailingPickUpName',
      setHailingPickUpLocation: 'hailingInfo/setHailingPickUpLocation',
      setHailingPickUpPostalNumber: 'hailingInfo/setHailingPickUpPostalNumber',
      setHailingDropOffLocation: 'hailingInfo/setHailingDropOffLocation',
      setHailingDropOffName: 'hailingInfo/setHailingDropOffName',
      setHailingDropOffPostalNumber: 'hailingInfo/setHailingDropOffPostalNumber'
    }),
    toPreviousPage() {
      this.$router.back();
    },
    toStoreLocationPage() {
      this.saveBehavior({
        event: '點擊',
        type: 1,
        note: `新增儲存地點`
      })
      this.$router.push('StoreLocation');
    },
    updateSaveLocations(locations) {
      this.savedLocstions = [...locations];
    },
    setPickUpById(driverId) {
      const selectLocation = this.savedLocstions
        .filter(({ id }) => { return id === driverId })
        .pop();
        //console.log('selectLocation', selectLocation)
        this.saveBehavior({
          event: '點擊',
          type: 1,
          note: `點選儲存地點: ${selectLocation.address} =>  上車`
        })
      const { address, postalCode, latitude, longitude } = selectLocation;

      this.setHailingPickUpName(address);
      this.setHailingPickUpPostalNumber(postalCode)
      let latlng = new google.maps.LatLng(latitude, longitude)
      this.setHailingPickUpLocation(latlng);
    },
    setDropOffById(driverId) {
      //console.log('setDropOffById');
      const selectLocation = this.savedLocstions
        .filter(({ id }) => { return id === driverId })
        .pop();

      const { address, postalCode, latitude, longitude } = selectLocation;
        this.saveBehavior({
          event: '點擊',
          type: 1,
          note: `點選儲存地點: ${selectLocation.address} => 下車`
        })
      this.setHailingDropOffName(address);
      let latlng = new google.maps.LatLng(latitude, longitude)
      this.setHailingDropOffPostalNumber(postalCode)
      this.setHailingDropOffLocation(latlng);
    },
    locationSelectHandler(id) {
      //console.log(this.isPickUpMode)
      this.isPickUpMode
        ? this.setPickUpById(id)
        : this.setDropOffById(id);

      this.toPreviousPage();
    },
  },
  components: {
    ListItemSave,
    PageHeader,
    SavedLocationList,
    Loading
  },
  async mounted() {
    try {
      this.isLoading = true
      const { data } = await getSavedAddress();
      if(data.result !== null) this.updateSaveLocations(data.result);
      this.isLoading = false
    } catch(err) {
      console.error(err);
      this.isLoading = false
    } 
  },
};
</script>
<style lang="scss" scoped>
  .savedLocations {
    height: 100vh;

    &__content {
      height: calc(100% - 56px);
      overflow-y: auto;
    }

    &__listItem {
      border-top: 1px solid #cbcbcb;
    }
  }
</style>
