<template lang="pug">
button.baseBtnSetupPosition(
  @click="$emit('click')")
  span.baseBtnSetupPosition__text {{ btnText }}
  searchIcon.baseBtnSetupPosition__icon
</template>
<script>
import searchIcon from '@/assets/images/search-24-px.svg';

export default {
  name: 'BaseBtnSetupPosition',
  props: {
    btnText: {
      type: String,
      required: true,
    }
  },
  components: {
    searchIcon
  }
};
</script>
<style lang="scss" scoped>
  .baseBtnSetupPosition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;
    height: 48px;
    outline: none;
    border-radius: 12px;
    border: solid 1px #efeeea;
    background-color: #f1f1f1;


    &__text {
      font-size: 12px;
      color: #767676;
    }

    &__icon {
      fill: #B7B7B7;
    }
  }
</style>
