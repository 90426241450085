<template lang="pug">
ul.driverSelector
  DriverSelectorItem(
    v-for="driver in driversList"
    :key="driver.id"
    :driverNote="driver.note"
    :teamName="driver.teamName"
    :callNumber="driver.callNumber"
    :reputation="driver.reputation"
    :carBrand="driver.carBrand"
    :licence="driver.licence"
    :photo="driver.photo"
    :text="text"
    @driverConfirmed="driverConfirmHandler(driver.id)"
  )
</template>
<script>
import DriverSelectorItem from './DriverSelectorItem';

export default {
  props: {
    text: {
      type: String,
      required: true
    },
    driversList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    driverConfirmHandler(id) {
      this.$emit('driverConfirmed', id);
    },
  },
  components: {
    DriverSelectorItem,
  },
};
</script>
<style lang="scss" scoped>
  .driverSelector {
    margin: 0;
    padding: 0;
  }
</style>
