<template lang="pug">
button.baseBtnConfirm(
  :disabled="disabled"
  :class="{'baseBtnConfirm--disabled': disabled}"
  @click="$emit('click')"
)
  span.baseBtnConfirm__text {{ text }}
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  }, 
};
</script>
<style lang="scss" scoped>
  .baseBtnConfirm {
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 20px;
    background-color: #ff9900;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    &__text {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #fff;
    }

    &--disabled {
      background: #ffd79c;
    }
  }
</style>
