<template lang="pug">
button.close(
  @click="$emit('click')"
)
  closeIcon.close__icon
</template>

<script>
import closeIcon from '@/assets/images/close-24-px.svg';

export default {
  name: 'BaseBtnClose',
  components: {
    closeIcon
  }
}
</script>

<style lang="scss" scoped>
  .close {
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    padding: 8px;
    &__icon {
      vertical-align: top;
    }
  }
</style>
