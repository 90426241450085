<template lang="pug">
.geoErrorBanner
  p.geoErrorBanner__msg {{ errorMsg }}
</template>
<script>
export default {
  props: {
    errorMsg: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
  .geoErrorBanner {
    background-color: #f7f7f7;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.13);

    &__msg {
      margin: 0;
      height: 55px;
      line-height: 55px;
      font-size: 12px;
    }
  }
</style>
