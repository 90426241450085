export function carFilterList() {
    let carFilter =[{
        carType: "舒適不限車種",
        estimate: '',
        minutes: 0,
        seatNumber: 1,
        taxiSort: 2,
        isOpt: true
    },
    // {
    //     carType: "舒適多元",
    //     estimate: '',
    //     minutes: 0,
    //     seatNumber: 1,
    //     taxiSort: 1,
    //     isOpt: false
    // },
    {
        carType: "寬敞不限車種(6人座)",
        estimate: '',
        minutes: 0,
        seatNumber: 6,
        taxiSort: 2,
        isOpt: false
    }
    // ,{
    //     carType: "寬敞多元(6人座)",
    //     estimate: '',
    //     minutes: 0,
    //     seatNumber: 6,
    //     taxiSort: 1,
    //     isOpt: false
    // }
]
    return carFilter
  };