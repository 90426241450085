export default {
  enterpriseOptions: state => state.enterpriseOptions,
  driverID: state => state.driverID,
  onLocation: state => state.onLocation,
  offLocation: state => state.offLocation,
  actualFare: state => state.actualFare,
  enterpriseID: state => state.enterpriseID,
  enterpriseName: state => state.enterpriseName,
  employeeID: state => state.employeeID,
  jobNumber: state => state.jobNumber,
  password: state => state.password,
  passengerNote: state => state.passengerNote,
  employeeName: state => state.employeeName,
  driverName: state => state.driverName
};