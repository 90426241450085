<template lang="pug">
ol.steps
  li.step(
    v-for="item in steps"
    :key="item"
    :data-step="item"
    :class="[{ 'is-complete': item < currentStep }, { 'is-active': item === currentStep }]"
    @click="$emit('setStep', item)"
  )
</template>

<script>
export default {
  name: '',
  props: {
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
$complete: #FF9900;
$active: #FF9900;
$mute: #FFD79C;
$static: #CDCDCD;

.steps {
  list-style: none;
  margin: 4px auto 18px auto;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 70%;
  color: #000;

  > .step {
    position: relative;
    display: table-cell;
    text-align: center;
    color:#6D6875;
    
    &:before {
      content: attr(data-step);
      display: block;
      margin: 0 auto;
      background: $mute;
      border: 2px solid $mute;
      color: #000;
      width: 24px;
      height: 24px;
      text-align: center;
      border-radius: 100%;
      position: relative;
      z-index: 1;
      font-size: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      background: $static;
      width: 100%;
      height: 3px;
      top: 10px;
      left: 50%;
    }
    &:last-child:after {
      display: none;
    }
    &.is-complete {
      &:before {
        color: #000;
        background: $complete;
        border: 2px solid $complete;
      }
      &:after {
        background: $complete;
      }
    }
    &.is-active {
      font-size:1.5rem;

      &:before {
        color: #000;
        border: 2px solid $complete;
        background: $active;
      }
    }
  }
}

*, *:after, *:before {
  box-sizing: border-box;
}
</style>
