<template lang="pug">
.forbiddenDrivers
  PageHeader(
    :title="'黑名單司機列表'"
    @toLastPage="toHomePage"
  )
  p.forbiddenDrivers__text 當您將該司機加入黑名單之後，該司機將無法再承接您的叫車訂單
  ForbiddenDriverSelector.forbiddenDrivers__selector(
    :options="teamOptions"
    :selectTeam="selectTeam"
    :driverNumber="driverNumber"
    @teamChanged="teamChangeHandler"
    @driverNumberChanged="driverNumberChangeHandler"
    @driverConfirmed="driverConfirmHandler"
  )
  DriverSelector.forbiddenDrivers__selector.forbiddenDrivers__selector--driver(
    :driversList="driversList"
    :text="'移除'"
    @driverConfirmed="openBaseConfirmModal"
  )
  BaseModal.forbiddenDrivers__modal(
    v-if="showNotFoundModal"
    :title="title"
    :text="text"
    @confirm="closeNotFoundModal"
  )
  ForbiddenDriverConfirmModal.forbiddenDrivers__modal(
    v-if="showConfirmModal"
    :driverInfo="driverInfo"
    :modalTitle="'是否加入黑名單司機?'"
    @cancel="closeConfirmModal"
    @confirm="addForbbidenDriver"
  )
  BaseConfirmModal.favoriteDrivers__modal(
    v-if="showBaseConfirmModal"
    :title="'確定要刪除司機嗎'"
    :cancelBtnText="'取消'"
    :confirmBtnText="'確定'"
    @confirm="driverRemove"
    @cancel="closeBaseConfirmModal"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import ForbiddenDriverSelector from '@/components/ForbiddenDriverSelector';
import DriverSelector from '@/components/DriverSelector';
import BaseModal from '@/components/BaseComponent/BaseModal';
import ForbiddenDriverConfirmModal from '@/components/ForbiddenDriverConfirmModal';
import { getTeamInfo, searchDriverInfo } from '@/api/teamInfoService';
import { setHateDriver, getHateList, removeHate } from '@/api/userInfoService';
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';

export default {
  name: 'ForbiddenDrivers',
  data() {
    return {
      teamOptions: [{
        name: '第一大車隊',
        value: '第一大車隊',
      }],
      title: '找不到此司機',
      text: '請確認輸入資料是否正確。',
      selectTeam: '',
      driverNumber: '',
      showNotFoundModal: false,
      showConfirmModal: false,
      driversList: [],
      driverInfo: {
        callNumber: null,
        id: null,
        licence: null,
        name: null,
        note: null,
        photo: null,
        reputation: null,
        teamName: null,
      },
      showBaseConfirmModal: false,
      driverId: null
    };
  },
  async created() {
    try {
      const { data } = await getTeamInfo();
      this.teamOptions  =data.result
      this.getHateDriverList()
    } catch (err) {
      console.error(err);
    }
  },
  methods: {
    openBaseConfirmModal(id){
      this.driverId = id
      this.showBaseConfirmModal = true
    },
    closeBaseConfirmModal(){
      this.showBaseConfirmModal = false
    },
    async driverRemove(id) {
      this.closeBaseConfirmModal()
        let { data } = await removeHate(this.driverId)
        this.driverId = null
        if(data.success) return this.getHateDriverList()
      
    },
    toHomePage() {
      this.$router.push('/');
    },
    teamChangeHandler(team) {
      this.selectTeam = team;
    },
    driverNumberChangeHandler(driverNumber) {
      this.driverNumber = driverNumber;
    },
    openNotFoundModal() {
      this.showNotFoundModal = true;
    },
    closeNotFoundModal() {
      this.showNotFoundModal = false;
    },
    openConfirmModal() {
      this.showConfirmModal = true;
    },
    closeConfirmModal() {
      this.showConfirmModal = false;
    },
    async driverConfirmHandler() {
      if(this.selectTeam === '') return alert('請選擇車隊')
      if(this.driverNumber === '') return alert('請填入隊編/呼號')
      let driverNumber = this.driverNumber.replace(/\s+/g,"")
      try {
        let { data } = await searchDriverInfo(driverNumber, this.selectTeam);
        if(!data.success) {
          return this.openNotFoundModal()
        }
        this.driverInfo = data.result
        this.openConfirmModal();
      } catch (err) {
        console.error(err);
      }
    },
     async getHateDriverList(){
      const { data } = await getHateList();
      if(!data.success) return this.driversList = []
      this.driversList = data.result
      //console.log(data)
    },
    async addForbbidenDriver(id) {
      try {
        let { data } = await setHateDriver(id);
        if(data.success){
          this.title = '新增成功'
          this.text = '新增黑名單司機成功'
          this.closeConfirmModal()
          this.getHateDriverList()
          return this.openNotFoundModal()
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  components: {
    BaseModal,
    PageHeader,
    DriverSelector,
    ForbiddenDriverSelector,
    ForbiddenDriverConfirmModal,
    BaseConfirmModal
  },
};
</script>
<style lang="scss" scoped>
.forbiddenDrivers {
    width: 100vw;
    height: 100vh;

    &__text {
      margin: 0;
      height: 56px;
      padding: 12px 24px;
      font-size: 12px;
      line-height: 16px;
      color: #767676;
      box-sizing: border-box;
    }

    &__selector {
      margin-top: 8px;

      &--driver {
        margin-top: 0;
        height: calc(100vh - 364px);
        overflow-y: auto;
      }
    }

    &__modal {
      z-index: 1;
    }
  }
</style>
