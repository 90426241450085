<template lang="pug">
BaseConfirmModal.forbiddenDriverConfirmModal(
  :title="modalTitle"
  :confirmBtnText="confirmBtnText"
  :cancelBtnText="cancelBtnText"
  @cancel="$emit('cancel')"
  @confirm="$emit('confirm', driverInfo.id)"
)
  template(v-slot:content)
    .forbiddenDriverConfirmModal__content
      .forbiddenDriverConfirmModal__description
        p.forbiddenDriverConfirmModal__subtitle 司機姓名
        p.forbiddenDriverConfirmModal__text {{driverInfo.name}}
      .forbiddenDriverConfirmModal__description
        p.forbiddenDriverConfirmModal__subtitle 車隊名稱
        p.forbiddenDriverConfirmModal__text {{driverInfo.teamName}}
      .forbiddenDriverConfirmModal__description
        p.forbiddenDriverConfirmModal__subtitle 司機編號/呼號
        p.forbiddenDriverConfirmModal__text {{driverInfo.callNumber}}
      .forbiddenDriverConfirmModal__description
        p.forbiddenDriverConfirmModal__subtitle 司機備註
        p.forbiddenDriverConfirmModal__text {{driverInfo.note}}
      .forbiddenDriverConfirmModal__description
        p.forbiddenDriverConfirmModal__subtitle 車牌號碼
        p.forbiddenDriverConfirmModal__text {{driverInfo.licence}}
</template>
<script>
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';

export default {
  props: {
    driverInfo: {
      type: Object
    },
    modalTitle: {
      type: String
    }
  },
  data() {
    return {
      modalTitle: '是否將此司機加入黑名單？',
      confirmBtnText: '確定',
      cancelBtnText: '取消',
    };
  },
  components: {
    BaseConfirmModal
  },
};
</script>
<style lang="scss" scoped>
  .forbiddenDriverConfirmModal {

    &__content {
      margin-bottom: 40px;
    }

    &__description {
      margin: 0;
      padding-left: 24px;
    }

    &__subtitle {
      display: inline-block;
      margin: 0;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #767676;
    }

    &__text {
      display: inline-block;
      margin: 0;
      margin-left: 16px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }
  }
</style>
