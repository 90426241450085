<template lang="pug">
.pageHeader
  BaseBtnLastPage.pageHeader__btn(
    :lastPageBtnIsShow="lastPageBtnIsShow"
    @click="$emit('toLastPage')"
  )
  h3.pageHeader__title {{ title }}
</template>
<script>
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    lastPageBtnIsShow: {
      type: Boolean,
      default: true
    }
  },
  components: {
    BaseBtnLastPage,
  },
};
</script>
<style lang="scss" scoped>
  .pageHeader {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 0 8px;

    &__title {
      margin: 0;
      margin-left: 16px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      font-weight: normal;
    }
  }
</style>
