<template lang="pug">
ul.reservationList
  ReservationListItem(
    v-for="reservation in reservationRecords"
    :key="reservation.orderID"
    :time="reservation.orderTime"
    :rideStatus="reservation.rideStatus"
    :onLocation="reservation.onLocation"
    :offLocation="reservation.offLocation"
    @reservationSelected="reservationSelectHandler(reservation.orderID)"
  )
</template>
<script>
import ReservationListItem from '@/components/ReservationList/ReservationListItem';

export default {
  props: {
    reservationRecords: {
      type: Array,
      required: true,
    },
  },
  methods: {
    reservationSelectHandler(id) {
      this.$emit('reservationSelected', id);
    },
  },
  components: {
    ReservationListItem
  }, 
};
</script>
<style lang="scss" scoped>
  .reservationList {
    width: 100%;
    margin: 0;
    padding: 0;
  }
</style>
