<template lang="pug">
.base-cover-modal
  slot.base-cover-modal__content
</template>

<script>
export default {
  name: 'BaseCoverModal'
}
</script>

<style lang="scss" scoped>
.base-cover-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 10;
  &__content {
  }
}
</style>
