import { festivalObj } from "./festival.js";

// dealing with the multiple of the car fare
export const finalPrice = (minFare, maxFare, multiple, now) => {
  let finalMinFare = minFare;
  let finalMaxFare = maxFare;

  // console.log(`before multiple ${minFare} ~ ${maxFare}`)
  if (multiple) {
    finalMinFare = finalMinFare * multiple.multipleOfNormal;
    finalMaxFare = finalMaxFare * multiple.multipleOfNormal;

    if (isFestival(now, festivalObj(now)) || isSpecialCase(now)) {
      finalMinFare = finalMinFare * multiple.multipleOfFestival;
      finalMaxFare = finalMaxFare * multiple.multipleOfFestival;
    }
    // determine time multiple
    if (isTimeMultiple(now)) {
      finalMinFare = finalMinFare * multiple.multipleOfRush;
      finalMaxFare = finalMaxFare * multiple.multipleOfRush;
    }
  }
  if (!isNaN(finalMinFare)) {
    /* 讓預估車資的最小與最大金額皆為5的倍數 */
    const [minf, maxf] = to5Multiple(finalMinFare, finalMaxFare);
    return `${minf} ~ ${maxf}`;
  }
  return "無下車地點";
};

function to5Multiple(min, max) {
  if (typeof min === "number" && typeof max === "number") {
    if (min <= max) {
      const minMultiple = Math.floor(min / 5);
      const maxMultiple = Math.ceil(max / 5);
      return [minMultiple * 5, maxMultiple * 5];
    } else {
      throw new Error("min fare should less than equal to min fare");
    }
  } else {
    throw new Error(
      "min or max fare is missing or the input type is not number"
    );
  }
}

const isFestival = (now, festival) => {
  // present time
  const date = `${now.getMonth()} ${now.getDate()}`;
  const year = now.getFullYear();

  // check for one day festival
  const oneDayFestival = festival.festivalOneDay?.filter(
    item => item.date === date
  );

  // check for multiple day festival
  const multipleDayFestival = festival.festivalInterval.filter(festival =>
    isInterval(year, date, festival.date.start, festival.date.end)
  );

  return oneDayFestival.length || multipleDayFestival.length ? true : false;
};

// special condition need to consider both date and time
const isSpecialCase = now => {
  // case 1 : 12.31 18:00 ~ 1.1 6:00
  const date = `${now.getMonth()} ${now.getDate()}`;
  const hour = Number(now.getHours());
  const minute = Number(now.getMinutes());

  if (isInterval(now.getFullYear(), date, "11 31", "0 1")) {
    // notice !! month start from zero
    // check if hour is the interval of 18-23 or 0-6
    let isInSpecialHour;
    if (Number(now.getMonth()) === 12 - 1 && hour >= 18 && hour <= 23) {
      isInSpecialHour = true;
    } else if (Number(now.getMonth() === 1 - 1) && hour >= 0 && hour <= 6) {
      isInSpecialHour = true;
    } else {
      isInSpecialHour = false;
    }

    // check minute because can't allow 6:10 etc.
    const isInSpecialMin = hour === 6 ? minute === 0 : true;

    return isInSpecialHour && isInSpecialMin;
  }

  return false;
};

const isInterval = (presentYear, date, festivalStart, festivalEnd) => {
  const year = Number(presentYear);

  const startMonth = Number(festivalStart.split(" ")[0]);
  const startDate = Number(festivalStart.split(" ")[1]);

  const endMonth = Number(festivalEnd.split(" ")[0]);
  const endDate = Number(festivalEnd.split(" ")[1]);

  const dateMonth = Number(date.split(" ")[0]);
  const dateDate = Number(date.split(" ")[1]);

  return isIntervalMonth(dateMonth, startMonth, endMonth)
    ? isIntervalDate(
      dateMonth,
      dateDate,
      startMonth,
      startDate,
      endMonth,
      endDate,
      year
    )
    : false;
};

const isIntervalMonth = (date, start, end) => {
  const month = Array.from(Array(12).keys());
  let isInterval = false;

  if (date === start || date === end) {
    return true;
  } else {
    for (let i = 0; i < month.length; i++) {
      let index = i + start;
      // if at the end of the element start from the beginning
      if (index >= month.length) {
        index = index - month.length;
      }

      if (month[index] === date) {
        isInterval = true;
        break;
      }
      if (month[index] === end) {
        isInterval = false;
        break;
      }
    }
  }

  return isInterval;
};

export const isIntervalDate = (
  dateMonth,
  dateDate,
  startMonth,
  startDate,
  endMonth,
  endDate,
  year
) => {
  if (dateMonth === startMonth && dateMonth === endMonth) {
    return dateDate >= startDate && dateDate <= endDate;
  } else {
    const startMonthTotalDays = totalDayOfMonth(startMonth, year);
    if (dateMonth === startMonth) {
      return dateDate >= startDate && dateDate <= startMonthTotalDays;
    } else if (dateMonth === endMonth) {
      return dateDate <= endDate;
    }
  }
};

const totalDayOfMonth = (month, year) => {
  const totalDays = new Date(year, month + 1, 0);
  return totalDays.getDate();
};

// can be more flexible => input now and desired time interval
// function should return if now is that interval
const isTimeMultiple = now => {
  // present time
  const day = Number(now.getDay()); //  Sunday - Saturday : 0 - 6
  const hour = Number(now.getHours());
  const minute = Number(now.getMinutes());

  // check if day is in the interval of monday to friday
  const isRushByDay = day >= 1 && day <= 5;

  // check if hour is the interval of 7-9 or 17-19
  const isRushByHour = (hour >= 7 && hour <= 9) || (hour >= 17 && hour <= 19);

  // check minute because can't allow 9:15 or 19:15 etc.
  const isRushByMin = hour === 9 || hour === 19 ? minute === 0 : true;

  return isRushByDay && isRushByHour && isRushByMin;
};

// divide services into two group, service with multiple or service without multiple
export function distinguishMultiple(services) {
  let resultObj = { optionsMultiple: [], optionsNoMultiple: [] };

  services.map(item => {
    item.isChoosingCarSelection
      ? resultObj.optionsMultiple.push(item)
      : resultObj.optionsNoMultiple.push(item);
  });

  return resultObj;
}

// export the finalPrice function for the unit test
// this exports will cause the error in mocha
// might due to already export the function
//module.exports = {finalPrice}
