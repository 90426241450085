<template lang="pug">
.baseInputAddress
  input.baseInputAddress__input(
    ref="input"
    :placeholder="placeholder"
    :value="value"
    :disabled="disabled"
    @input="inputHandler"
    @focus="focusHandler")
  deleteIcon.baseInputAddress__icon(
    v-if="showIcon"
    @click="clearInput"
  )
  slot(name="icon")
</template>
<script>
import deleteIcon from '@/assets/images/close-roinded-24-px.svg';

export default {
  name: 'BaseInputAddress',
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    deleteIcon
  },
  computed: {
    showIcon() {
      return this.disabled === false
        && this.value !== '';
    }
  },
  methods: {
    inputHandler(event) {
      this.$emit('input', event);
    },
    focusHandler(event) {
      this.$emit('focus', event);
    },
    clearInput() {
      this.$emit('inputCleared');
      this.$refs.input.focus();
    }
  },
};
</script>
<style lang="scss" scoped>
  .baseInputAddress {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 48px;
    padding: 0 8px;
    border: 1px solid #ebecec;
    border-radius: 12px;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);

    &__input {
      outline: none;
      border: none;
      font-size: 12px;
      width: 100%;
    }

    &__icon {
      vertical-align: middle;
    }

  }
</style>
