<template lang="pug">
li.hailingRecordListItem
  .hailingRecordListItem__sectionWrapper
    SectionTop(
      :time="time"
      :onLocation="onLocation"
      :offLocation="offLocation"
      :isCanceled="isCanceled"
    )
  .hailingRecordListItem__sectionWrapper
    SectionBottom(
      :actualPrice="actualPrice"
      :isCanceled="isCanceled"
      @checkDetailPage="$emit('checkDetailPage')"
    )
</template>
<script>
import SectionTop from './SectionTop';
import SectionBottom from './SectionBottom';

export default {
  props: {
    time: {
      type: String
    },
    onLocation: {
      type: String
    },
    offLocation: {
      type: String
    },
    actualPrice: {
      type: Number
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    SectionTop,
    SectionBottom,
  },
};
</script>
<style lang="scss" scoped>
  .hailingRecordListItem {
    list-style: none;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    &__sectionWrapper {
      &:last-child {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: -10px;
          left: -10px;
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: 2px 0 2px rgba(0, 0, 0, 0.16)
        }

        &::after {
          content: '';
          position: absolute;
          top: -10px;
          right: -10px;
          width: 20px;
          height: 20px;
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: -2px 0 2px rgba(0, 0, 0, 0.16)
        }

      }
    }
  }
</style>
