<template lang="pug">
li.listItem(
  @click="$emit('click')")
  .listItem__content
    .listItem__logoWrapper
      slot(name="logo")
    .listItem__description
      p.listItem__title {{ title }}
      p.listItem__text(
        v-if="text !== undefined"
      ) {{ text }}
  listIcon.listItem__angleRight(
    v-if="showIcon"
  )
</template>
<script>
import listIcon from '@/assets/images/keyboard-arrow-right-24-px.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    listIcon
  }
};
</script>
<style lang="scss" scoped>
  .listItem {
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #cbcbcb;

    &__content {
      display: flex;
      align-items: center;
    }

    &__logoWrapper {
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      background-color: #ff9900;
      text-align: center;
      color: #ffffff;
    }

    &__description {
      margin-left: 16px;
    }

    &__title {
      margin: 0;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__text {
      margin: 0;
      font-size: 12px;
      color: #767676;
    }

    &__angleRight {
      vertical-align: middle;
    }
  }
</style>
