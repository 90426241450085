<template lang="pug">
ul.menuList
  MenuListLink(
    :text="'行程紀錄'"
    @click="toUrl('/ReservationRecords')"
  )
    template(v-slot:icon)
      reservationIcon.menuList__icon.menuList__icon--reservation
  <!-- MenuListLink( -->
  <!--   :text="'付款設定'" -->
  <!--   @click="toUrl('/PaymentSetup')" -->
  <!-- ) -->
  <!--   template(v-slot:icon) -->
  <!--     paymentIcon.menuList__icon.menuList__icon--payment -->
  MenuListLink(
    :text="'最愛司機設定'"
    @click="toUrl('/FavoriteDrivers')"
  )
    template(v-slot:icon)
      favoriteIcon.menuList__icon.menuList__icon--favorite
  MenuListLink(
    :text="'黑名單司機設定'"
    @click="toUrl('/ForbiddenDrivers')"
  )
    template(v-slot:icon)
      forbbidenIcon.menuList__icon.menuList__icon--forbbiden
</template>
<script>
import MenuListLink from './MenuListLink';
import reservationIcon from '@/assets/images/list-alt-24-px.svg';
import historyIcon from '@/assets/images/history-24-px-2.svg';
import paymentIcon from '@/assets/images/payment-24-px.svg';
import favoriteIcon from '@/assets/images/favorite-24-px.svg';
import forbbidenIcon from '@/assets/images/block-24-px.svg';

export default {
  name: 'MenuList',
  components: {
    MenuListLink,
    reservationIcon,
    historyIcon,
    paymentIcon,
    favoriteIcon,
    forbbidenIcon
  },
  methods: {
    toUrl(url) {
      this.$emit('routeChanged', url);
    },
  }
};
</script>

<style lang="scss" scoped>
  .menuList {
    margin: 0;
    padding: 0;
    padding-top: 12px;
  }
</style>
