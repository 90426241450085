<template lang="pug">
.hailingStatusModal__popup.hailingStatusModal__popup--success
  .hailingStatusModal__iconSuccess
    successIcon
  h3.hailingStatusModal__title.hailingStatusModal__title--success 成功叫車
  h3.hailingStatusModal__title.hailingStatusModal__title--success 預計{{getTime()}}到達
  TaxiInfoPanel.hailingStatusModal__panel.hailingStatusModal__panel--taxiinfo(
    :driverImg="driverImg"
    :driverStar="driverStar"
    :driverNote="driverNote"
    :carLicense="carLicense"
    :carBrand="carBrand"
    :callNumber="callNumber"
    :teamName="teamName"
  )
  .hailingStatusModal__btnWrapper.hailingStatusModal__btnWrapper--call(
    @click="$emit('callTaxiDriver')"
  )
    phoneIcon.hailingStatusModal__iconCall
    p.hailingStatusModal__subtitle.hailingStatusModal__subtitle--phone 打給司機
  .hailingStatusModal__btnWrapper.hailingStatusModal__btnWrapper--success
    BaseBtnConfirm(
      :text="'確定'"
      @click="$emit('successConfirmed')"
    )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import TaxiInfoPanel from '@/components/common/TaxiInfoPanel';
import moment from 'moment';
import successIcon from '@/assets/images/success.svg';
import phoneIcon from '@/assets/images/phone.svg';

export default {
  props: {
    driverImg: {
      required: true,
    },
    driverStar: {
      type: Number,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
    estimatedTime: {
      type: Number,
      required: true,
    }
  },
  methods: {
    getTime(){
      return `${moment().add(this.estimatedTime, 'minute').format('HH:mm')}-${moment().add(this.estimatedTime+5, 'minute').format('HH:mm')}`
    }
  },
  components: {
    BaseBtnConfirm,
    TaxiInfoPanel,
    successIcon,
    phoneIcon
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingStatusModal';

.hailingStatusModal {

  &__panel {
    margin-top: 40px;
    border-radius: 20px;
    border: solid 2px #efeeea;
  }

  &__iconSuccess {
    margin: 16px auto;
    vertical-align: middle;
    text-align: center;
  }
}
</style>
