<template lang="pug">
.locationConfirmMap
  GmapMap.locationConfirmMap(
    ref="map"
    :center="center"
    :zoom="17"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    :options="mapOptions"
  )
    GmapMarker(
      :position="center"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
  BaseBtnConfirm.locationConfirmMap__btn(
    :text="'確定'"
    @click="$emit('confirm')"
  )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  props: {
    center: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
        zoomControl: false,
        draggable: false,
        clickableIcons: false,
      },
      marker: {
        url: require('@/assets/images/maker/location.png'),
      },
    };
  },
  components: {
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>
  .locationConfirmMap {
    position: relative;

    &__btn {
      width: calc(100vw - 20px);
      position: absolute;
      bottom: 16px;
      left: 10px;
    }
  }
</style>
