<template lang="pug">
li.driverSelectorItem
  .driverSelectorItem__detail
    .driverSelectorItem__topWrapper
      .driverSelectorItem__logo
        img(:src='photo' v-if="photo").driverSelectorItem__logo
        BaseIconStarWithValue.driverSelectorItem__icon(
          :value="reputation"
        )
      .driverSelectorItem__content
        p.driverSelectorItem__subtitle {{ teamName }} {{ callNumber }}
        p.driverSelectorItem__text {{ carBrand }} {{ licence }}
        p.driverSelectorItem__text {{ driverNote }}
    .driverSelectorItem__bottomWrapper
      button.driverSelectorItem__button(
        @click="$emit('driverConfirmed')"
      ) 選擇這位司機
</template>
<script>
import BaseIconStarWithValue from '@/components/BaseComponent/BaseIconStarWithValue';

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    photo: {
      type: String,
      required: true,
    },
    driverNote: {
      type: String,
      required: false,
    },
    teamName: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    reputation: {
      type: Number,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    licence: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseIconStarWithValue,
  },
};
</script>
<style lang="scss" scoped>
  .driverSelectorItem {
    list-style: none;
    padding: 0 8px;
    margin-bottom: 10px;

    &__detail {
      border-radius: 5px;
      border: 1px solid #dfdfdf;
      box-sizing: border-box;
    }

    &__topWrapper {
      display: flex;
      align-items: center;
      padding: 16px;
    }

    &__bottomWrapper {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid #dfdfdf;
      padding: 8px 16px;
    }

    &__logo {
      position: relative;
      width: 64px;
      height: 64px;
      // background-color: #777;
      background-image: url('../../assets/images/user.png') no-repeat 0 0 / contain;
      background-size: contain;
      border-radius: 20px;
      display: inline-block;
    }

    &__icon {
      position: absolute;
      right: -12px;
      bottom: -12px;
    }

    &__content {
      margin-left: 16px;
      width: 100%;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 10px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      max-width: 100px;
    }

    &__text {
      margin: 0;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
      color: #767676;

    }

    &__btnWrapper {
      width: 124px;
    }

    &__button {
      padding: 0;
      background: rgba(0, 0, 0, 0);
      outline: none;
      border: none;
      font-size: 14px;
      font-weight: bold;
      color: #ff9900;
    }
  }
</style>
