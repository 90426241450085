<template lang="pug">
.itineraryTable
  BaseBtnClose.itineraryTable__icon(
    @click="$emit('closeItinerary')"
  )
  h3.itineraryTable__title [{{ isSerivce ? '----' : passengerName }}]的行程
  HailingInfo(
    :orderTime="orderTime"
    :rideStatus="rideStatus"
    :onLocationName="onLocationName"
    :offLocationName="offLocationName"
  )
  DriverInfo(
    :driverName="driverName"
    :teamName="teamName"
    :teamPhone="teamPhone"
    :callNumber="callNumber"
    :driverPhone="driverPhone"
    :driverNote="driverNote"
    :carBrand="carBrand"
    :carLicense="carLicense"
    @callTaxiTeam="$emit('callTaxiTeam')"
    @callTaxiDriver="$emit('callTaxiDriver')"
  )
</template>
<script>
import HailingInfo from './HailingInfo';
import DriverInfo from './DriverInfo';
import BaseBtnClose from '@/components/BaseComponent/BaseBtnClose';

export default {
  props: {
    orderTime: {
      type: String,
      required: true,
    },
    rideStatus: {
      type: String,
      required: true,
    },
    passengerName: {
      type: String,
      required: true,
    },
    onLocationName: {
      type: String,
      required: true,
    },
    offLocationName: {
      type: String
    },
    driverName: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
    teamPhone: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    driverPhone: {
      type: String,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
    isSerivce: {
      type: Boolean
    }
  },
  components: {
    HailingInfo,
    DriverInfo,
    BaseBtnClose,
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_itineraryTable';
  .itineraryTable {
    position: relative;

    &__icon {
      position: absolute;
      top: 8px;
      right: 16px;
    }
  }
</style>
