<template lang="pug">
.addressSetupForm
  GmapAutocomplete(v-show="false")
  .addressSetupForm__inputWrapper
    .addressSetupForm__iconMarker
      locationIcon
      .addressSetupForm__ellipsis
    BaseInputAddress.addressSetupForm__input(
      :value="pickUp"
      :class="{'addressSetupForm__input--focus': isPickUpMode }"
      :disabled="isLoadingShow"
      placeholder="上車地點"
      @input="addressInputHandler"
      @focus="focusPickUpInput"
      @inputCleared="inputClearedHandler('pickUp')"
    )
      .addressSetupForm__inputLoading(
        slot="icon"
        v-if="isLoadingShow"
      )
  .addressSetupForm__inputWrapper(v-show="pickUpLacation !==null")
    .addressSetupForm__iconMarker
      locationIcon
    BaseInputAddress.addressSetupForm__input(
      :value="dropOff"
      :class="{'addressSetupForm__input--focus': !isPickUpMode }"
      placeholder="下車地點"
      @input="addressInputHandler"
      @focus="focusDropOffInput"
      @inputCleared="inputClearedHandler('dropOff')"
    )
  router-link(
    v-if="showLocationSetupMapLink"
    :class="'addressSetupForm__link'"
    to="/LocationSetupMap"
  ) 在地圖上設定地點
</template>
<script>
import debounce from 'lodash/debounce';
import BaseInputAddress from '@/components/BaseComponent/BaseInputAddress';
import locationIcon from '@/assets/images/location-on-24-px.svg';

export default {
  props: {
    pickUp: {
      type: String,
      required: true,
    },
    dropOff: {
      type: String,
      required: true,
    },
    showLocationSetupMapLink: {
      type: Boolean,
      required: true,
    },
    isPickUpMode: {
      type: Boolean,
      required: true,
    },
    pickUpLacation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      firstBuild: false,
      isLoadingShow: true
    }
  },
  methods: {
    focusPickUpInput(event) {
      //console.log('focusPickUpInput')
      this.vue.gtm.trackEvent({
          event: 'click',
          clickEvent: '關注上車地點'
      })
      this.$emit('currentInputChange', 'pickUp');
      this.queryAutoComplete(event);
    },
    focusDropOffInput(event) {
      this.vue.gtm.trackEvent({
          event: 'click',
          clickEvent: '關注下車地點'
      })
      this.$emit('currentInputChange', 'dropOff');
      this.queryAutoComplete(event);
    },
    addressInputHandler(event) {
      const { value } = event.target;
      this.$emit('addressNameChange', value);
      this.queryAutoComplete(event);
    },
    inputClearedHandler(item) {
      this.$emit('addressNameCleared', item);
    },
    queryAutoComplete: debounce(function(e) {
      const { value } = e.target;
      if (value === '') {
        this.$emit('clearPredictLocations', true);
      } else {
        const service = new google.maps.places.AutocompleteService();
        service.getQueryPredictions(
          { input: value }, 
          (predictions, status) => {
            status === 'OK'
              && this.$emit('autoCompleteQuery', predictions);
          }
        );
      }
    }, 300),
  },
  watch: {
    pickUp: {
      handler(value) {
        if (!value && !this.firstBuild) {
          this.firstBuild = true;
          this.isLoadingShow = false;
        }
      },
      immediate: false,
    }
  },
  components: {
    BaseInputAddress,
    locationIcon
  },
  mounted() {
    console.log('isLoadingShow', this.isLoadingShow);
    setTimeout(() => {
      this.isLoadingShow = false;
      console.log('after 2.5s isLoadingShow', this.isLoadingShow);
    }, 2500);
  }
};
</script>
<style lang="scss" scoped>
  .addressSetupForm {
    position: relative;
    z-index: 1;
    padding: 8px 16px 16px 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);

    &__inputWrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }

    &__iconMarker {
      position: relative;
      width: 24px;
      height: 24px;
    }

    &__ellipsis {
      position: absolute;
      bottom: -35px;
      right: 11px;
      height: 30px;
      border-right: 3px dotted #b7b7b7;
    }

    &__input {
      margin-left: 10px;

      &--focus { border: 1px solid  #ff9900 }
      &Loading {
        width: 60px;
        height: 20px;
        background-image: url('../../assets/images/loading.gif');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &__link {
      display: inline-block;
      color: #ff9900;
      font-size: 12px;
      text-decoration: none;
      height: 16px;
      line-height: 16px;
      margin-left: 32px;
    }
  }
</style>
