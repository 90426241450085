import axiosConfig from './axiosConfig';

export const getReservationList = page => axiosConfig
  .get(`/web/api/web_api/lists/?page=${page}`);

export const getReservationDetail = reservationId => axiosConfig
  .get(`/web/api/appreservation/${reservationId}`);

export const getReservationDriverList = reservationId => axiosConfig
  .get(`/web/api/appreservation/${reservationId}/drivers`);

export const postReservation = reservationConfig => axiosConfig
  .post('/web/api/appreservation', reservationConfig);

export const putReservationDriver = (reservationId, driverConfig) => axiosConfig
  .put(`/web/api/appreservation/${reservationId}/driver`, driverConfig);

export const putCancelReservation = reservationId => axiosConfig
  .put(`/web/api/appreservation/cancel/${reservationId}`, {
    passengerCancelNote: '乘客取消',
  });
