<template lang="pug">
.passengerServiceSelector
  p.passengerServiceSelector__title {{ title }}
  .passengerServiceSelector__list
    .passengerServiceSelector__item(
      v-for="(item, index) in options"
      :key="index"
      :class="{'passengerServiceSelector__item--selected': passenger === item.value}"
      @click="optionSelectedHandler(item.value)"
    ) {{ item.description }}
</template>
<script>
import BaseSelector from '@/components/BaseComponent/BaseSelector';

export default {
  props: {
    passenger: {
      type: Number,
      required: true,
    },
    showCarTypeSelector: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: '乘客人數',
      options: [
        { value: 1, description: '1' },
        { value: 2, description: '2' },
        { value: 3, description: '3' },
        { value: 4, description: '4' },
        { value: 5, description: '5' },
        { value: 6, description: '6' },
      ],
    };
  },
  methods: {
    optionSelectedHandler(value) {
      this.$emit('passengerChanged', value);
      if(!this.showCarTypeSelector) return;
      if(value > 4) {
        const carSixSeatType = 1;
        this.$emit('carTypeChanged', carSixSeatType);
      }
    },
  },
  components: {
    BaseSelector,
  },
};
</script>

<style lang="scss" scoped>
.passengerServiceSelector {
    width: calc(100vw - 30px);
    overflow: hidden;

    &__title {
      margin: 0;
      color: #767676;
      font-size: 12px;
    }

    &__list {
      margin-top: 4px;
      -ms-overflow-style: none;
      width: calc(100vw - 30px);
      list-style: none;
      display: inline-flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    &__item {
      margin-left: 8px;
      display: inline-block;
      width: 40px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      border: solid 2px #efeeea;
      text-align: center;
      flex-shrink: 0;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      &--selected {
        color: #ff9900;
        border: solid 2px #ff9900;
      }
    }
  }
</style>
