<template lang="pug">
.mapPanel
  GmapMap(
    ref="map"
    :center="defaultCenter"
    :zoom="17"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    :options="mapOptions"
    @bounds_changed="boundsChangeHandler"
  )
    GmapMarker(
      ref="marker"
      :position="defaultCenter"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
  .mapPanel__banner
    BaseBtnLastPage(
      @click="$emit('mapModeClosed')"
    )
    p.mapPanel__text
      FontAwesomeIcon.mapPanel__icon--loading(
        v-if="isLoading"
        size="lg"
        :icon="['fas', 'spinner']"
      )
      span(v-else) {{ currentLocationName }}
  .mapPanel__btnWrapper
    BaseBtnConfirm(
      :text="'確定'"
      @click="locationConfirmHandler"
    )
</template>
<script>
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  name: 'MapPanel',
  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
        clickableIcons: false,
      },
      marker: {
        url: require('@/assets/images/maker/location.png'),
      },
      defaultCenter: {
        lat: 22.997195,
        lng: 120.212343,
      },
      currentCenter: null,
      currentLocationName: '',
      currentLocation: null,
      currentLocationPostalNumber: null,
      isLoading: false,
    };
  },
  methods: {
    async boundsChangeHandler() {
      const newCenter = await this.$refs.map.$mapPromise
        .then(map => ({
          lat: map.getCenter().lat(),
          lng: map.getCenter().lng(),
        }));
      this.updateMapCenter(newCenter);
      this.updateLocationName();
    },
    updateMapCenter({ lat, lng }) {
      this.currentCenter = { lat, lng };
      this.$refs.marker.$markerPromise
        .then(marker => {
          marker.setPosition({ lat, lng });
        });
    },
    setCurrentLocation(LanLng) {
      //console.log(LanLng)
      this.currentLocation = LanLng;
    },
    setCurrentLocationName(name) {
      this.currentLocationName = name;
    },
    setCurrentLocationPostalNumber(number) {
      this.currentLocationPostalNumber = number;
    },
    startLoading() {
      this.isLoading = true;
    },
    stopLoading() {
      this.isLoading = false;
    },
    updateLocationName: debounce(async function(e) {
      this.startLoading();
      try {
        let geocoder =  new google.maps.Geocoder()
        geocoder.geocode({ location: { lat:this.currentCenter.lat, lng:this.currentCenter.lng } }, (results, status) => {
            if (status === 'OK') {
              //console.log('result',results[0])
              const { address_components, geometry, formatted_address } = results[0];
              const locationName = formatted_address.replace(/^\d{3}?台灣/, '');
              const { long_name } = address_components
                .filter(component => component.types.includes('postal_code'))
                .pop();
                //console.log('locationName', locationName)
              this.setCurrentLocationName(locationName);
              let LatLng = new google.maps.LatLng(this.currentCenter.lat, this.currentCenter.lng)
              this.setCurrentLocation(LatLng);
              this.setCurrentLocationPostalNumber(long_name);
            } else {
              this.setCurrentLocationName('');
            }
        });
      } catch(err) {
        this.setCurrentLocationName('');
      }
      this.stopLoading();
    }, 300),
    locationConfirmHandler() {
      //console.log('confirm', this.currentLocation)
      this.$emit('mapLocationConfirmed', {
        name: this.currentLocationName,
        location: this.currentLocation,
        postalNumber: this.currentLocationPostalNumber,
      });
    },
  },
  components: {
    BaseBtnLastPage,
    BaseBtnConfirm,
    FontAwesomeIcon,
  },
};
</script>
<style lang="scss" scoped>
  .mapPanel {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__map {
      width: 100%;
      height: 100%;
    }

    &__banner {
      position: absolute;
      top: 0;
      left: 0;
      background-color: #ffffff;
      width: 100%;
      height: 56px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    }

    &__text {
      margin: 0;
      margin-left: 8px;
      width: calc(100% - 24px);
      height: 48px;
      line-height: 48px;
      padding: 0 8px;
      box-sizing: border-box;
      border: 1px solid #efeeea;
      border-radius: 12px;
      font-size: 12px;
    }

    &__btnWrapper {
      position: absolute;
      bottom: 16px;
      left: 0;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
    }

    &__icon {
      &--loading {
        width: 100%;
        animation: loading 2s linear infinite;
      }
    }

    @keyframes loading {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
</style>
