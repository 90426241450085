<template lang="pug">
li.listItem
  .listItem__radioWrapper
    RadioCheckedUI(
      :isChecked="isChecked"
    )
  p.listItem__text {{ name }}
  button.listItem__btn(
    v-if="deletable"
    @click="$emit('delete')"
  ) 刪除
</template>
<script>
import RadioCheckedUI from '@/components/common/RadioCheckedUI';

export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    deletable: {
      type: Boolean,
      required: false,
      default: true,
    },
    isChecked: {
      type: Boolean,
      required: true,
    }
  },
  components: {
    RadioCheckedUI,
  },
};
</script>
<style lang="scss" scoped>
  .listItem {
    list-style: none;
    position: relative;
    height: 72px;
    padding-left: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &__text {
      margin: 0;
      margin-left: 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__btn {
      position: absolute;
      top: 28px;
      right: 16px;
      outline: none;
      border: none;
      padding: 0;
      background-color: #ffffff;
      color: #a62900;
    }
  }
</style>
