<template lang="pug">
.section.section--top
  .section__content.section__content--top(
    :class="{'section__content--cancel': isCanceled}"
  )
    .section__description
      p.section__subtitle 叫車日期
      p.section__value {{time}}
    .section__description
      p.section__subtitle 上車地點
      p.section__value {{onLocation}}
    .section__description
      p.section__subtitle 下車地點
      p.section__value {{offLocation}}
</template>
<script>
export default {
  props: {
    time: {
      type: String
    },
    onLocation: {
      type: String
    },
    offLocation: {
      type: String
    },
    isCanceled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingRecordSection';

</style>
