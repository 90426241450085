import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const geolocation = {
  namespaced: true,
  state: {
    isGeoEnabled: false,
    currentLatLng: null,
    geoErrorStatus: '若要自動搜尋您的上車地點，請啟用定位服務',
  },
  getters,
  mutations,
  actions,
};

export default geolocation;
