import axiosConfig from './axiosConfig';
import { sub_domain } from './default'

export const loginUser = userConfig => axiosConfig
  .post(`/line/api/web/${sub_domain}/login`, userConfig);

  export const getTeamName = () => axiosConfig
  .get(`/line/api/web/${sub_domain}/login`);

export const setHateDriver = driverId => axiosConfig
  .post(`/web/api/linehate/add/?driverId=${driverId}`);

export const getHateList = userConfig => axiosConfig
.get(`/web/api/linehate/lists`);

export const removeHate = driverId => axiosConfig
  .delete(`/web/api/linehate/remove/?driverId=${driverId}`);

export const setFavorite = driverId => axiosConfig
  .post(`/web/api/linefavorite/add/?driverId=${driverId}`);

export const getFavoriteList = () => axiosConfig
  .get(`/web/api/linefavorite/lists`);

export const removeFavorite = driverId => axiosConfig
  .delete(`/web/api/linefavorite/remove/?driverId=${driverId}`);

export const pushLineMessage = message => axiosConfig
  .post('/line/api/web/staging/push_message', message);