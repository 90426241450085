<template lang="pug">
.baseModal
  .baseModal__popup
</template>
<script>

export default {
  name: 'Loader',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  }
};
</script>
<style lang="scss" scoped>
  .baseModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    // background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;

    &__popup {
      width: 75px;
      height: 25px;
    //   background-color:  rgba(0, 0, 0,0);
      background-image: url('../../assets/images/loading.gif');
      padding-top: 24px;
      background-repeat: no-repeat;
      background-size: 75px 25px;
    }
  }
</style>
