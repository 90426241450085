<template lang="pug">
.teamSelector
  select.teamSelector__select(
    :value="value"
    @change="teamChangeHandler"
  )
    option.teamSelector__option(
      value=""
      disabled
      selected
    ) 選擇車隊
    option.teamSelector__option(
      v-for="{ id, name } in options"
      :value="id"
    ) {{ name }}
</template>
<script>
export default {
  name: 'TeamSelector',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    teamChangeHandler(e) {
      const { value } = e.target;
      this.$emit('teamChanged', value);
    },
  },
};
</script>
<style lang="scss" scoped>
  .teamSelector {
    position: relative;
    width: 100%;
    background-color: #ffffff;

    &__select {
      position: relative;
      z-index: 1;
      outline: none;
      width: 100%;
      height: 48px;
      padding: 0 16px;
      line-height: 48px;
      box-sizing: border-box;
      border: 1px solid #ebecec;
      border-radius: 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      background-color: rgba(0, 0, 0, 0);
    }
  }
</style>
