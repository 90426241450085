import axiosConfig from './axiosConfig';
import axiosApConfig from './axiosApConfig';

import { sub_domain } from './default'

export const getHailingDriverInfo = (hailingId,task) => axiosConfig
  .get(`/line/api/web/${sub_domain}/passenger/information/${hailingId}/?task=${task}`);

export const getHailingDriverLocation = (hailingId,task) => axiosConfig
  .get(`/line/api/web/${sub_domain}/driver/gps/${hailingId}/?task=${task}`);

export const getWithDropOffTaxiList = hailingConfig => {
  const {
    distance,
    postal_number,
    spend_time,
    passenger_number,
    on_longitude,
    on_latitude,
    serviceList
  } = hailingConfig;

  const url = '/app/api/passenger/near_drivers';

  return axiosApConfig
    .get(`${url}?postal_number=${postal_number}&distance=${distance}&spend_time=${spend_time}&passenger_number=${passenger_number}&on_longitude=${on_longitude}&on_latitude=${on_latitude}&serviceList=${serviceList}`)
};

export const getNoDropOffTaxiList = hailingConfig => {
  const {
    passenger_number,
    on_longitude,
    on_latitude,
    serviceList
  } = hailingConfig;

  const url = '/app/api/passenger/near_drivers';

  return axiosApConfig
    .get(`${url}?passenger_number=${passenger_number}&on_longitude=${on_longitude}&on_latitude=${on_latitude}&serviceList=${serviceList}`)
};

export const postNewHailing = hailingInfo => axiosConfig
  .post(`/line/api/web/${sub_domain}/passenger/callcar`, hailingInfo);

export const getCancelAfter = id => axiosConfig
  .get(`/line/api/web/${sub_domain}/passenger/cancelcallcar?action=after&id=${id}`);

export const getCancelBefore = () => axiosConfig
  .get(`/line/api/web/${sub_domain}/passenger/cancelcallcar?action=before`);

export const getExtraServiceOptions = () =>  axiosConfig
  .get(`/line/api/web/${sub_domain}/team/service`)
    
