<template lang="pug">
.baseSelector
  p.baseSelector__title {{ title }}
  .baseSelector__selected(
    @click="toggleShowOptions"
  )
    p.baseSelector__description(
      :class="{'baseSelector__placeholder': selectedDescription === options[0].description}"
    ) {{ selectedDescription }}
    dropListIcon.baseSelector__icon
  ul.baseSelector__optionList(v-if="showOptions")
    li.baseSelector__option(
      v-for="{ value, description, isPlaceHolder } in options"
      v-if="!isPlaceHolder"
      @click="optionSelectedHandler(value)"
    ) {{ description }}
</template>
<script>
import dropListIcon from '@/assets/images/arrow-drop-down-24-px.svg';

export default {
  name: 'BaseSelector',
  components: {
    dropListIcon
  },
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    options: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      showOptions: false,
    };
  },
  computed: {
    selectedDescription() {
      const seletedOption = this.options
        .filter(option => option.value === this.value)
        .pop();

      return seletedOption.description;
    },
  },
  methods: {
    toggleShowOptions() {
      this.showOptions = !this.showOptions;
    },
    closeShowOptions() {
      this.showOptions = false;
    },
    optionSelectedHandler(value) {
      this.$emit('optionSelected', value);
      this.closeShowOptions();
    },
  },
};
</script>
<style lang="scss" scoped>
  .baseSelector {
    position: relative;
    width: 136px;

    &__title {
      margin: 0;
      color: #767676;
      font-size: 12px;
    }

    &__selected {
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 4px;
      padding: 0 8px 0 16px;
      border: 1px solid #efeeea;
      border-radius: 12px;
      box-sizing: border-box;
    }

    &__description {
      font-size: 14px;
      margin: 0;
    }

    &__placeholder {
      color: #767676;
      font-size: 12px;
    }

    &__placeholder-option {
      display: none;
    }

    &__icon {
      background-position: center;
      background-repeat: no-repeat;
    }

    &__optionList {
      background-color: #fff;
      width: 100%;
      position: absolute;
      top: 100%;
      margin: 0;
      padding: 0;
      border: 1px solid #efeeea;
      border-radius: 12px;
      box-sizing: border-box;
      overflow: hidden;
    }

    &__option {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-bottom: 1px solid #efeeea;
      box-sizing: border-box;
      font-size: 14px;

      &:last-child {
        border-bottom: none;
      }
    }
  }
</style>
