<template>
  <GmapMap
    :center="center"
    :zoom="15"
    :options="options"
    style="width: 100%; height: 600px"
  >
    <GmapMarker
      :position="marker.position"
      :clickable="false"
      :draggable="false"
    />
  </GmapMap>
</template>
<script>
import Axios from 'axios';
import { create } from 'domain';

  export default {
    name: "AddGoogleMap",
    data() {
      return {
        marker: { position: { lat: 25.033671, lng: 121.564427 } },
        center: { lat: 25.033671, lng: 121.564427 },
        options: {
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          scrollwheel: true,
          clickableIcons: false,
        },
      };
    },
    methods: {
      getLocation() {
        navigator.geolocation.watchPosition((position) => {
          console.log(position.coords.latitude)
          this.center.lat = position.coords.latitude
          this.center.lng = position.coords.longitude;
        })
      }
    },
    created(){
      this.getLocation()
    }
  }
</script>