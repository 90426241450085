<template>
    <div>
        Line Pay {{title}}
    </div>
</template>
<script>

export default {
    data(){
        return {
            title: '付款完成，請自行關閉瀏覽器'
        }
    },
    mounted(){
        // let status = this.$route.query.status
        // if(status === 0 || status === '0'){
        //     this.title = '付款完成，請自行關閉瀏覽器'
        // }
    },
}
</script>