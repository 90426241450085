<template lang="pug">
button.baseBtnPreStep(
  :class="{'baseBtnNextStep--disabled': disabled}"
  @click="$emit('click')"
)
  span.baseBtnPreStep__text {{ text }}
</template>
<script>
export default {
  name: 'BaseBtnPreStep',
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false
    }
  }, 
};
</script>
<style lang="scss" scoped>
  .baseBtnPreStep {
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 20px;
    background-color: #B7B7B7;
    text-align: center;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);

    &__text {
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #fff;
    }
  }
</style>
