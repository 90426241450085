import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';
import Home from '../views/Home.vue'
import AddressSetup from '../views/AddressSetup';
import SavedLocations from '../views/SavedLocations';
import ServiceOptions from '../views/ServiceOptions';
import HailingStatus from '../views/HailingStatus';
import TaxiStatus from '../views/TaxiStatus';
import LocationSetupMap from '../views/LocationSetupMap';
import StoreLocation from '../views/StoreLocation';
import ReservationRecords from '../views/ReservationRecords';
import ReservationDetail from '../views/ReservationDetail';
import ForbiddenDrivers from '../views/ForbiddenDrivers';
import FavoriteDrivers from '../views/FavoriteDrivers';
// import HailingHistory from '../views/HailingHistory';
import HailingHistoryDetail from '../views/HailingHistoryDetail';
import PaymentSetup from '../views/PaymentSetup';
import NewCreditCard from '../views/NewCreditCard';
import Chat from '../views/Chat.vue';
import LinePay from '../views/LinePay.vue';
import BillSigning from '../views/BillSigning.vue';
import LinePaySuccess from '../views/LinePaySuccess.vue';
import Test from '../views/Test.vue';
// import { loginUser } from '../api/userInfoService';
// import * as jsCookie from 'js-cookie'
// import axiosConfig from '../api/axiosConfig';

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { trackedTitle: '主頁面' }
    // meta: { auth: true }
  },
  {
    path: '/Chat',
    name: 'Chat',
    component: Chat,
    meta: { trackedTitle: '聊天畫面' }
    // meta: { auth: true }
  },
  {
    path: '/AddressSetup',
    name: 'AddressSetup',
    component: AddressSetup,
    meta: { trackedTitle: '地址設定頁面' }
  },
  {
    path: '/SavedLocations',
    name: 'SavedLocations',
    component: SavedLocations,
    meta: { trackedTitle: '儲存地點列表' }
  },
  {
    path: '/ServiceOptions',
    name: 'ServiceOptions',
    component: ServiceOptions,
    meta: { trackedTitle: '選擇服務選擇' }
  },
  {
    path: '/HailingStatus',
    name: 'HailingStatus',
    component: HailingStatus,
    meta: { trackedTitle: '派車狀態' }

  },
  {
    path: '/TaxiStatus',
    name: 'TaxiStatus',
    component: TaxiStatus,
    meta: { trackedTitle: '訂單狀態' }
  },
  {
    path: '/Test',
    name: 'Test',
    component: Test,
  },
  {
    path: '/LocationSetupMap',
    name: 'LocationSetupMap',
    component: LocationSetupMap,
    meta: { trackedTitle: '地圖上設定地址' }
  },
  {
    path: '/StoreLocation',
    name: 'StoreLocation',
    component: StoreLocation,
    meta: { trackedTitle: '設定地址' }
  },
  {
    path: '/ReservationRecords',
    name: 'ReservationRecords',
    component: ReservationRecords,
    meta: { trackedTitle: '派單行程紀錄' }
  },
  {
    path: '/ReservationDetail',
    name: 'ReservationDetail',
    component: ReservationDetail,
    meta: { trackedTitle: '預約單詳細資訊' }
  },
  {
    path: '/ForbiddenDrivers',
    name: 'ForbiddenDrivers',
    component: ForbiddenDrivers,
    meta: { trackedTitle: '黑名單' }
  },
  {
    path: '/FavoriteDrivers',
    name: 'FavoriteDrivers',
    component: FavoriteDrivers,
    meta: { trackedTitle: '喜好司機' }
  },
  // {
  //   path: '/HailingHistory',
  //   name: 'HailingHistory',
  //   component: HailingHistory,
  // },
  {
    path: '/HailingHistoryDetail',
    name: 'HailingHistoryDetail',
    component: HailingHistoryDetail,
    meta: { trackedTitle: '立即詳細資料' }
  },
  {
    path: '/PaymentSetup',
    name: 'PaymentSetup',
    component: PaymentSetup,
    meta: { trackedTitle: '設定付款方式' }
  }, {
    path: '/NewCreditCard',
    name: 'NewCreditCard',
    component: NewCreditCard,
    meta: { trackedTitle: '設定信用卡' }
  },{
    path: '/LinePay',
    name: 'LinePay',
    component: LinePay,
  },{
    path: '/BillSigning',
    name: 'BillSigning',
    component: BillSigning,
    meta: { trackedTitle: '電子簽單' },
    beforeEnter: async (to, _from, next) => {
      const code = to.query.code;
      const authCode = to.query.auth_code;
      store.dispatch('billSigning/fetchEnterpiseOptions', code);
      store.dispatch('billSigning/fetchTemporaryBill', authCode);
      next();
    }
    },{
      path: '/LinePaySuccess',
      name: 'LinePaySuccess',
      component: LinePaySuccess,
      meta: { trackedTitle: '付款完成頁面'
    }
  }

];

const router = new VueRouter({
  routes
});

// router.beforeEach(({meta, path, query}, from, next) => {
//   //console.log(path)
//   if (path !== '/TaxiStatus') next({ name: 'Home' })
//   // 如果用户未能验证身份，则 `next` 会被调用两次
//   next()
// })

router.afterEach(route => {
  // 時間稍微延遲以避免抓取到前個 window.location.href 位置
  setTimeout(() => {
    //console.log(route.meta.trackedTitle)

    Vue.gtm.trackEvent({
        // 自行定義的事件名稱
        event: 'content-view',
        // 頁面功能/分頁標題文字
        pageTitle: route.meta.trackedTitle
    })
    // trackService.pageUserTrack(router)
  }, 500)
})


export default router;
