import moment from 'moment'
export function chineseDayConverter(day) {
  const convertDict = {
    0: '日',
    1: '一',
    2: '二',
    3: '三',
    4: '四',
    5: '五',
    6: '六',
  };

  return convertDict[day];
};

export function getInitReservationTime()  {
  const delayMins = 15;
  const currentMins = new Date().getMinutes();
  const shiftMins = currentMins % 10 >= 5
    ? 10 - (currentMins % 10)
    : 5 - (currentMins % 10);

  const reserveTime = new Date(Date.now() + (delayMins + shiftMins) * 60000)
    .setSeconds(0);
  return moment(reserveTime)
};

export function getDate(time) {
  return moment(time).format('MM/DD')
}

export function getLTTime(time) {
  return moment(time).format('LT');
}

export function getDateString(str) {
  let time = moment(str)
  const year = time.get('year');
  const month = time.get('month') + 1;
  const date = time.get('date');
  return `${year}年${month}月${date}日`;
};

export function getTimeString(str) {
  let time = moment(str)
  const hours = time.get('hour')
  const mins = time.get('minute');
  return `${hours}:${("0" + mins).slice(-2)}`;
};

export function getReservationFormatString(str) {
  let time = moment(str)
  const year = time.get('year');
  const month = time.get('month') +1;
  const date = time.get('date');
  const hours = `0${time.get('hour')}`.slice(-2);
  const mins = `0${time.get('minute')}`.slice(-2);
  return `${year}-${month}-${date} ${hours}:${mins}:00`;
};

export function getReserveDateOptions(str) {
  let time = moment(str)
  const millisecsOfOneDay = 24 * 60 * 60 * 1000;
  const year = moment(time).get('year')

  const additionalOptions = [...Array(5).keys()]
    .map(index => index + 1)
    .map(dayShift => {
      const additionalDateObj = moment(time).add(dayShift * millisecsOfOneDay,'ms');
      const year = additionalDateObj.get('year');
      const month = additionalDateObj.get('month') + 1;
      const date = additionalDateObj.get('date');
      const day = additionalDateObj.get('day');

    return {
      year,
      value: dayShift,
      name: `${month}月${date}號 ${chineseDayConverter(day)}`,
      month,
      date,
    };
  });

  return [
    {
      year,
      value: 0,
      name: '今天',
      month: time.get('month') + 1,
      date: time.get('date'),
    },
    ...additionalOptions,
  ];
};
