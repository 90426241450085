<template lang="pug">
button.phoneCall(
  @click="$emit('click')"
)
  phoneIcon.phoneCall__icon
</template>

<script>
import phoneIcon from '@/assets/images/call.svg';

export default {
  name: 'ButtonPhoneCall',
  components: {
    phoneIcon
  }
}
</script>

<style lang="scss" scoped>
  .phoneCall {
    border: none;
    outline: none;
    border-radius: 20px;
    padding: 0;
    &__icon {
      vertical-align: middle;
    }
  }
</style>
