export function extractPathDirections(steps) {
  try {
    const paths = steps.map(step => [
      step.start_location,
      step.end_location,
    ]);
    let pathArray = paths.reduce((acc, cur, index) => {
      return index === 0
        ? [...acc, ...cur]
        : [...acc, cur.pop()];
    }, []);
    return pathArray

  } catch(err) {
    return [];
  }
};

export function extractLocationsToListFormat(locations) {
  return locations.map(location => {
    const [title, text] = location.description
      .replace(/\s/g, '')
      .split(',');

    return {
      title: title.replace(/^台灣/, ''),
      text,
      place_id: location.place_id,
    };
  });
};
