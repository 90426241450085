<template lang="pug">
BaseConfirmModal.reservationDriverConfirmModal(
  :title="modalTitle"
  :confirmBtnText="confirmBtnText"
  :cancelBtnText="cancelBtnText"
  @cancel="$emit('cancel')"
  @confirm="$emit('confirm')"
)
  template(v-slot:content)
    .reservationDriverConfirmModal__panelWrapper
      TaxiInfoPanel.reservationDriverConfirmModal__panel(
        :driverImg="driverImg"
        :driverStar="driverStar"
        :driverNote="driverNote"
        :carLicense="carLicense"
        :carBrand="carBrand"
        :callNumber="callNumber"
        :teamName="teamName"
      )
    p.reservationDriverConfirmModal__text 確定司機後無法修改。
</template>
<script>
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';
import TaxiInfoPanel from '@/components/common/TaxiInfoPanel';

export default {
  props: {
    driverImg: {
      required: true,
    },
    driverStar: {
      type: Number,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalTitle: '是否選擇此司機',
      confirmBtnText: '確定',
      cancelBtnText: '取消',
    };
  },
  components: {
    BaseConfirmModal,
    TaxiInfoPanel,
  },
};
</script>
<style lang="scss" scoped>
  .reservationDriverConfirmModal {

    &__panelWrapper {
      padding: 0 16px;
    }

    &__panel {
      border: 1px solid #EFEEEA;
      border-radius: 20px;
      padding-bottom: 8px;
    }

    &__text {
      margin: 0;
      margin-top: 8px;
      margin-bottom: 40px;
      padding: 0 16px;
      font-size: 12px;
      line-height: 16px;
      color: #767676;
    }
  }
</style>
