<template lang="pug">
button.menu(
  @click="$emit('click')"
)
  menuIcon.menu__icon
</template>

<script>
import menuIcon from '@/assets/images/menu-24-px.svg';

export default {
  name: 'BaseBtnMenu',
  components: {
    menuIcon
  }
}
</script>

<style lang="scss" scoped>
  .menu {
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    padding: 8px;
    
    &__icon {
      vertical-align: top;
    }
  }
</style>
