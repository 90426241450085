<template lang="pug">
.baseConfirmModal
  .baseConfirmModal__popup
    h3.baseConfirmModal__title 確認訂單
    HailingDetailTable.hailingStatusModal__detail(
      :reservationTime="reservationTime"
      :pickUpName="pickUpName"
      :dropOffName="dropOffName"
      :taxiType="taxiType"
      :price="price"
      :payment="payment"
      :extraServices="extraServices"
      :extraServicesOptions = "extraServicesOptions"
      :note="note"
    )
    .baseConfirmModal__btnGroup
      BaseBtnConfirm.baseConfirmModal__btn.baseConfirmModal__btn--cancel(
        :text="'修改訂單'"
        @click="$emit('modify')"
      )
      BaseBtnConfirm.baseConfirmModal__btn(
        :text="'確定送出'"
        @click="$emit('confirm')"
      )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import HailingDetailTable from '@/components/HailingStatusModal/HailingDetailTable';
import {getExtraServiceOptions} from '../../api/hailingService.js' 

export default {
  data(){
    return{
      extraServicesOptions:null
    }
  },
  props: {
    pickUpName: {
      type: String,
      required: true,
    },
    dropOffName: {
      type: String,
      required: true,
    },
    taxiType: {
      type: String,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    price: {
      type: String,
      /* required: true, */
    },
    note: {
      type: String,
      required: true,
    },
    reservationTime: {
      type: String,
      /* required: true */
    }
  },
  components: {
    BaseBtnConfirm,
    HailingDetailTable,
  },
  mounted() {
    this.extraServicesOptions = getExtraServiceOptions() 
  },
};
</script>
<style lang="scss" scoped>
  .hailingStatusModal {

    &__detail {
      margin: 0 auto;
      margin-bottom: 60px;
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
    }
  }
  .baseConfirmModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &__popup {
      width: 296px;
      border-radius: 20px;
      background-color: #ffffff;
      padding-top: 24px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    &__title {
      height: 28px;
      margin: 0;
      padding-bottom: 24px;
      line-height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }

    &__btnGroup {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__btn {
      width: 124px;

      &--cancel {
        background-color: #b7b7b7;
      }
    }
  }
</style>
