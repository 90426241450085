<template lang="pug">
button.scroll(
  @click="$emit('click')"
  v-if="isShow"
)
  ArrowDownIcon
</template>

<script>
import ArrowDownIcon from '@/assets/images/bi_arrow-down.svg';

export default {
  name: 'BaseBtnScroll',
  components: {
    ArrowDownIcon
  },
  props: {
    isShow: {
      type: Boolean,
      required: true,
    }
  }
}
</script>

<style>
.scroll {
  position: fixed;
  bottom: 90px;
  right: 20px;
  z-index: 10;
  padding: 6px 0;
  outline: none;
  border: none;
  background-color: #C4C4C4;
  border-radius: 5px;
}
</style>
