<template lang="pug">
BaseListItem.listItemSavedLocation(
  :text="text"
  :title="title"
  :showIcon="false"
  @click="$emit('click')"
)
  template(v-slot:logo)
    locationIcon.listItemSavedLocation__logo
</template>
<script>
import BaseListItem from '@/components/BaseComponent/BaseListItem';
import locationIcon from '@/assets/images/location_circle.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    BaseListItem,
    locationIcon
  },
};
</script>
<style lang="scss" scoped>
</style>
