import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const hailingInfo = {
  namespaced: true,
  state: {
    mode: "immediate",
    pickUp: {
      postalNumber: "",
      name: "",
      location: null
    },
    dropOff: {
      postalNumber: "",
      name: "",
      location: null
    },
    carType: 0,
    passenger: 1,
    payment: 0,
    customerNote: "",
    extraServices: [],
    pathDirections: [],
    reservationTime: null,
    isSearching: true,
    isHailingSuccess: false,
    taxiList: [],
    distance: null,
    duration: null,
    testCarTeam: null /* 目前測試的車隊 */,
    repeated: 0 /* 確認重複叫車的參數 */,
    repeatedMsg: "尋找司機中，是否要再叫一輛？" /* 重複叫車的訊息 */
  },
  getters,
  mutations,
  actions
};

export default hailingInfo;
