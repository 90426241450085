<template lang="pug">
.paymentInfoModal
  .paymentInfoModal__popup
    h3.paymentInfoModal__title 輸入付款資訊
    .paymentInfoModal__content
      .paymentInfoModal__row
        .paymentInfoModal__inputWrapper
          p.paymentInfoModal__subtitle 專屬碼
          input.paymentInfoModal__input(
             v-model="code"
            placeholder="例如：A001"
          )
        .paymentInfoModal__inputWrapper
          p.paymentInfoModal__subtitle 隊編/呼號
          input.paymentInfoModal__input(
            v-model="callNumber"
            placeholder="例如：329114"
          )
      .paymentInfoModal__inputWrapper.paymentInfoModal__inputWrapper--charge
        p.paymentInfoModal__subtitle 此趟車資
        input.paymentInfoModal__input(
          v-model="price"
          placeholder="請輸入金額(單位台幣)"
        )
    //- .paymentInfoModal__btnWrapper
    //-   button.paymentInfoModal__button
    //-     span.paymentInfoModal__btnText 使用QRocid掃描器
    .paymentInfoModal__btnWrapper
      BaseBtnConfirm(:text="'行動支付'" @click="$emit('confirmPrice',price)")
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  props:{
    code: {
      type: String,
      required: false,
      default: ''
    },
    callNumber: {
      type: String,
      required: false,
      default: ''
    },
  },
  data(){
    return {
      price: 0
    }
  },
  components: {
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>

.paymentInfoModal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  box-sizing: border-box;

  &__popup {
    width: 296px;
    background-color: #fff;
    border-radius: 20px;
    padding: 8px 16px;
    box-sizing: border-box;
  }

  &__title {
    text-align: center;
    margin: 24px 0;
    font-weight: 500;
    font-size: 20px;
    height: 28px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.87);
  }

  &__btnWrapper {
    margin-top: 8px;
  }

  &__button {
    box-sizing: border-box;
    background: #fff;
    padding: 0;
    width: 100%;
    outline: none;
    border: 2px solid #ff9900;
    border-radius: 20px;
    text-align: center;
    box-sizing: border-box;
  }

  &__btnText {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    color: #ff9900;
  }

  &__content {
    margin-bottom: 80px;
  }

  &__description {
    margin: 0;
    padding: 0 8px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__inputWrapper {
    width: 120px;
    margin-bottom: 16px;

    &--charge {
      width: 100%
    }
  }

  &__input {
    outline: none;
    padding: 16px 8px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border: solid 1px #efeeea;
    font-size: 20px;
  }

  &__subtitle {
    margin: 0;
    height: 28px;
    line-height: 24px;
    font-size: 16px;
    color: #000000;
  }
}
</style>
