<template lang="pug">
.hailingStatusModal__popup.hailingStatusModal__popup--fail
  BaseConfirmModal(
    :title="'目前無車輛可以提供'"
    :cancelBtnText="'取消'"
    :confirmBtnText="'再試一次'"
    @cancel="$emit('cancelNextPage')"
    @confirm="$emit('hailingAgain')"
  )
    template(v-slot:content)
      HailingDetailTable.hailingStatusModal__detail(
        :pickUpName="pickUpName"
        :dropOffName="dropOffName"
        :taxiType="taxiType"
        :price="price"
        :payment="payment"
        :extraServices="extraServices"
        :note="note"
      )
</template>
<script>
import BaseConfirmModal from '@/components/BaseComponent/BaseConfirmModal';
import HailingDetailTable from './HailingDetailTable';

export default {
  props: {
    pickUpName: {
      type: String,
      required: true,
    },
    dropOffName: {
      type: String,
      required: true,
    },
    taxiType: {
      type: String,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    price: {
      type: String,
      /* required: true, */
    },
    note: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseConfirmModal,
    HailingDetailTable,
  },
}
</script>
<style lang="scss" scoped>
  .hailingStatusModal {

    &__detail {
      margin: 0 auto;
      margin-bottom: 60px;
      width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
    }
  }
</style>
