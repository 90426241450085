<template lang="pug">
.reservationList
  PageHeader(@toLastPage="toLastPage")
  .reservationList__modeSelector
    .reservationList__mode(
      @click="modeChangeHandler('reservation')"
      :class="{'reservationList__mode--selected': mode === 'reservation'}"
    ) 預約行程
    .reservationList__mode(
      @click="modeChangeHandler('history')"
      :class="{'reservationList__mode--selected': mode === 'history'}"
    ) 立即叫車
  ReservationList.reservationList__list(
    v-if="mode==='reservation'"
    :reservationRecords="reservationRecords"
    @reservationSelected="reservationSelectHandler"
  )
  HailingRecordList.reservationList__list(
    v-else-if="mode==='history'"
    :tripRecords="tripRecords"
    @checkDetailPage="checkDetailPage"
  )
  Loading(
    v-if="isLoading"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import ReservationList from '@/components/ReservationList';
import HailingRecordList from '@/components/HailingRecordList';
import { getTripHistoryList } from '@/api/tripHistory';
import { getReservationList } from '@/api/reservationService';
import Loading from '@/components/common/Loading';

export default {
  name: 'ReservationRecords',
  data() {
    return {
      mode: 'reservation',
      tripRecords: [],
      reservationRecords: [],
      isLoading: true
    };
  },
  methods: {
    toLastPage() {
      this.$router.back();
    },
    modeChangeHandler(mode) {
      this.mode = mode;
    },
    reservationSelectHandler(id) {
      this.$router.push({
        path: '/ReservationDetail',
        query: { id }
      });
    },
    checkDetailPage(id) {
      //console.log(id)
      this.$router.push(`/HailingHistoryDetail/?id=${id}`);
    }
  },
  components: {
    PageHeader,
    ReservationList,
    HailingRecordList,
    Loading
  },
  async mounted() {
    try {
      const reservations = await getReservationList(1);
      const historys = await getTripHistoryList(1);
      this.isLoading = false
      this.reservationRecords = [...reservations.data.result];
      this.tripRecords = [...historys.data.result];
    } catch (err) { console.error(err) }
  },
};
</script>
<style lang="scss" scoped>
  .reservationList {
    width: 100vw;
    height: 100vh;

    &__modeSelector {
      width: 100%;
      padding: 0 36px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }

    &__mode {
      width: 140px;
      height: 36px;
      line-height: 34px;
      font-size: 20px;
      text-align: center;
      box-sizing: border-box;
      font-weight: lighter;

      &--selected {
        font-weight: normal;
        border-bottom: 2px solid  #ff9900;
      }
    }

    &__list {
      max-height: calc(100vh - 140px);
      margin-top: 24px;
      padding-bottom: 24px;
      overflow-y: auto;
    }
  }
</style>
