export const SET_HAILING_MODE = 'SET_HAILING_MODE';
export const SET_HAILING_PICK_UP_NAME = 'SET_HAILING_PICK_UP_NAME';
export const SET_HAILING_PICK_UP_LOCATION = 'SET_HAILING_PICK_UP_LOCATION';
export const SET_HAILING_PICK_UP_POSTAL_NUMBER = 'SET_HAILING_PICK_UP_POSTAL_NUMBER';
export const SET_HAILING_DROP_OFF_NAME = 'SET_HAILING_DROP_OFF_NAME';
export const SET_HAILING_DROP_OFF_LOCATION = 'SET_HAILING_DROP_OFF_LOCATION';
export const SET_HAILING_DROP_OFF_POSTAL_NUMBER = 'SET_HAILING_DROP_OFF_POSTAL_NUMBER';
export const SET_HAILING_PAYMENT = 'SET_HAILING_PAYMENT';
export const SET_HAILING_PASSENGER = 'SET_HAILING_PASSENGER';
export const SET_EXTRA_SERVICES = 'SET_EXTRA_SERVICES';
export const SET_EXTRA_SERVICES_NAME = 'SET_EXTRA_SERVICES_NAME';
export const SET_HAILING_CAR_TYPE = 'SET_HAILING_CAR_TYPE';
export const SET_PATH_DIRECTIONS = 'SET_PATH_DIRECTIONS';
export const SET_RESERVATION_TIME = 'SET_RESERVATION_TIME';
export const SET_CUSTOMER_NOTE = 'SET_CUSTOMER_NOTE';
export const SET_IS_SEARCHING = 'SET_IS_SEARCHING';
export const SET_IS_HAILING_SUCCESS = 'SET_IS_HAILING_SUCCESS';
export const SET_TAXI_LIST = 'SET_TAXI_LIST';
export const SET_DISTANCE = 'SET_DISTANCE';
export const SET_DURATION = 'SET_DURATION';
export const SET_TEST_CAR_TEAM = 'SET_TEST_CAR_TEAM';
export const SET_REPEATED = 'SET_REPEATED';
export const SET_REPEATED_MSG = 'SET_REPEATED_MSG';
