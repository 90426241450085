<template lang="pug">
.taxiStatusMap
  BaseBtnMenu.taxiStatusMap__btn.taxiStatusMap__btn--menu(
    v-if="task"
    @click="$emit('openMenu')"
  )
  GmapMap(
    ref="map"
    :center="mapCenter"
    :zoom="14"
    map-type-id="terrain"
    style="width: 100%; height: 100%"
    :options="mapOptions"
  )
    GmapMarker(
      :position="marker.position"
      :clickable="false"
      :draggable="false"
      :icon="marker"
    )
    GmapMarker(
      :position="pickOff"
      :clickable="false"
      :draggable="false"
      :icon="offMarker"
    )
    GmapMarker(
      v-if="taxiLocation !== null"
      :position="taxiLocation"
      :clickable="false"
      :draggable="false"
      :icon="carIcon"
    )
  BaseBtnCenterMap.taxiStatusMap__btn.taxiStatusMap__btn--centerMap(
    @click="centerMap"
  )
</template>
<script>
import BaseBtnMenu from '@/components/BaseComponent/BaseBtnMenu';
import BaseBtnCenterMap from '@/components/BaseComponent/BaseBtnCenterMap';

export default {
  props: {
    task: {
      type: Boolean
    },
    pickUp: {
      type: Object
    },
    pickOff:{
      type: Object
    },
    taxiLocation: {
      required: true,
    }
  },
  data() {
    return {
      center: { 
        lat: 25.033671, 
        lng: 121.564427 
      },
      mapOptions: {
        disableDefaultUI: true,
        clickableIcons: false,
      },
      marker: {
        position: { 
          lat: 25.033671, 
          lng: 121.564427 
        },
        url: require('@/assets/images/maker/wave_person.png'),
      },
      offMarker: {
        url: require('@/assets/images/maker/location.png'),
      },
      carIcon: {
        url: require('@/assets/images/maker/car-1.png'),
      },
    };
  },
  computed: {
    mapCenter() {
      if(this.pickUp === null && this.taxiLocation === null) {
        let pickUpItem = JSON.parse(localStorage.getItem('item'))
        this.marker.position.lat = pickUpItem.lat,
        this.marker.position.lng = pickUpItem.lng
        return {
          lat: pickUpItem.lat,
          lng: pickUpItem.lng
        }
      }
      if(this.pickUp === null && this.taxiLocation !== null) 
      return {
        lat: this.taxiLocation.lat,
        lng: this.taxiLocation.lng,
      };
      if (this.taxiLocation === null) {
        localStorage.setItem('item', JSON.stringify(this.pickUp))
        let setPosition = JSON.parse(JSON.stringify(this.pickUp))
        this.marker.position.lat = setPosition.lat
        this.marker.position.lng = setPosition.lng
        return {
          lat: this.pickUp.lat(),
          lng: this.pickUp.lng()
        }
      }
      // } else {
      //   return {
      //       lat: (this.pickUp.lat() + this.taxiLocation.lat) / 2,
      //       lng: (this.pickUp.lng() + this.taxiLocation.lng) / 2,
      //     };
      // }
    },
  },
  methods: {
    getLocation() {
      navigator.geolocation.watchPosition((position) => {
        console.log(position.coords.latitude)
        this.center.lat = position.coords.latitude
        this.center.lng = position.coords.longitude;
      })
    }
  },
  mounted () {
  },
  components: {
    BaseBtnMenu,
    BaseBtnCenterMap,
  },
};
</script>
<style lang="scss" scoped>
  .taxiStatusMap {
    position: relative;

    &__btn {
      position: absolute;
      z-index: 1;

      &--menu {
        top: 8px;
        left: 8px;
      }

      &--centerMap {
        right: 10px;
        bottom: 150px;
      }
    }
  }
</style>
