import {
  SET_IS_GEO_ENABLED,
  SET_CURRENT_LAT_LNG,
  SET_GEO_ERROR_STATUS,
} from './mutation-types';

export default {
  initCurrentLatLnt({ commit, dispatch }) {
    const geoOptions = {
      enableHighAccuracy: false,
      timeout: 10000,
    };

    if ('geolocation' in navigator) {
      navigator.geolocation
        .getCurrentPosition(
          position => { dispatch('handleGetPositionSuccess', position.coords) },
          error => { dispatch('handleGetPositionFail', error) },
          geoOptions,
        );
    } else {
      commit(SET_IS_GEO_ENABLED, false);
      commit(SET_GEO_ERROR_STATUS, '您的裝置不支援 GPS 自動定位');
    }
  },
  handleGetPositionSuccess({ commit }, coords) {
    const { latitude, longitude } = coords;
    commit(SET_IS_GEO_ENABLED, true);
    commit(SET_GEO_ERROR_STATUS, '');
    commit(SET_CURRENT_LAT_LNG, {
      lat: latitude,
      lng: longitude
    });
  },
  handleGetPositionFail({ commit }, error) {
    commit(
      SET_GEO_ERROR_STATUS, 
      error.code == error.PERMISSION_DENIED
        ? '若要自動搜尋您的上車地點，請啟用定位服務'
        : '取得位置失敗，請開啟GPS功能或設定更高精確度',
    );
  },
};
