<template lang="pug">
.itineraryTable__table.itineraryTable__table--driverInfo
  .itineraryTable__description
    p.itineraryTable__subtitle 司機姓名
    p.itineraryTable__text {{ driverName }}
  .itineraryTable__description
    p.itineraryTable__subtitle 車隊名稱
    p.itineraryTable__text {{ teamName }}
  .itineraryTable__description
    p.itineraryTable__subtitle 司機編號
    p.itineraryTable__text {{ callNumber }}
  .itineraryTable__description
    p.itineraryTable__subtitle 司機電話
    p.itineraryTable__text.itineraryTable__text--phone(
      @click="$emit('callTaxiDriver')"
    ) {{ driverPhone }}
  .itineraryTable__description
    p.itineraryTable__subtitle 司機備註
    p.itineraryTable__text {{ driverNote }}
  .itineraryTable__description
    p.itineraryTable__subtitle 車牌號碼
    p.itineraryTable__text {{ carLicense }}
  .itineraryTable__description
    p.itineraryTable__subtitle 車輛型號
    p.itineraryTable__text {{ carBrand }}
  .itineraryTable__description
    p.itineraryTable__subtitle 車輛類型
    p.itineraryTable__text 4門/五人座(含司機)
  .itineraryTable__description
    p.itineraryTable__subtitle 客服專線
    p.itineraryTable__text.itineraryTable__text--phone(
      @click="$emit('callTaxiTeam')"
    ) {{ teamPhone }}
</template>
<script>
export default {
  props: {
    driverName: {
      type: String,
      required: true,
    },
    teamName: {
      type: String,
      required: true,
    },
    teamPhone: {
      type: String,
      required: true,
    },
    callNumber: {
      type: String,
      required: true,
    },
    driverPhone: {
      type: String,
      required: true,
    },
    driverNote: {
      type: String,
      required: true,
    },
    carBrand: {
      type: String,
      required: true,
    },
    carLicense: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_itineraryTable';

</style>
