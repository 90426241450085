<template lang="pug">
.baseModal
  .baseModal__popup
    slot(name="icon")
    h3.baseModal__title(
      v-if="title !== ''"
    ) {{ title }}
    p.baseModal__text(
      v-if="text !== ''"
    ) {{ text }}
    .baseModal__btnWrapper
      BaseBtnConfirm(
        :text="'確定'"
        @click="$emit('confirm')"
      )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>
  .baseModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__popup {
      width: 296px;
      border-radius: 20px;
      background-color: #ffffff;
      padding-top: 24px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    &__title {
      height: 28px;
      margin: 0;
      padding-bottom: 20px;
      line-height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }

    &__text {
      margin: 0;
      padding: 0 16px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);
      line-height: 20px;
    }

    &__btnWrapper {
      padding: 0 16px;
      margin-top: 48px;
    }
  }
</style>
