<template lang="pug">
.newCreditCard
  PageHeader(
    :title="'新增信用卡或簽帳金融卡'"
    @toLastPage="toLastPage"
  )
  p.newCreditCard__text 持卡人姓名和其他資訊必須與卡片上記載的完全相同
  .newCreditCard__inputWrapper
    p.newCreditCard__subtitle 信用卡卡號
    BaseInput(
      :value="cardId"
      :placeholder="'ex: 0000-0000-0000-0000'"
      @input="cardIdInputHandler"
    )
  .newCreditCard__inputWrapper
    p.newCreditCard__subtitle 有效日期
    BaseInput(
      :value="expiration"
      :placeholder="'ex: 0122'"
      @input="expirationInputHandler"
    )
  .newCreditCard__inputWrapper
    p.newCreditCard__subtitle 安全碼
    BaseInput(
      :value="securityCode"
      :placeholder="'ex: 000'"
      @input="securityCodeInputHandler"
    )
  .newCreditCard__btnWrapper
    BaseBtnConfirm(
      :text="'儲存'"
      @click="saveNewCreditCard"
    )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import BaseInput from '@/components/BaseComponent/BaseInput';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  data() {
    return {
      cardId: '',
      expiration: '',
      securityCode: '',
    };
  },
  methods: {
    toLastPage() {
      this.$router.back();
    },
    cardIdInputHandler(event) {
      const { value } = event.target;
      this.cardId = value;
    },
    expirationInputHandler(event) {
      const { value } = event.target;
      this.expiration = value;
    },
    securityCodeInputHandler(event) {
      const { value } = event.target;
      this.securityCode = value;
    },
    saveNewCreditCard() {
      //console.log('saveNewCreditCard');
    },
  },
  components: {
    BaseInput,
    PageHeader,
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>
  .newCreditCard {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__text {
      margin: 0;
      padding: 0 24px;
      font-size: 12px;
      line-height: 40px;
      color: #767676;
    }

    &__inputWrapper {
      padding: 4px 16px;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 8px;
      line-height: 16px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
    }

    &__btnWrapper {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 16px;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
</style>
