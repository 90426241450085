<template lang="pug">
.radioChecked
  checkIcon.radioChecked__icon.radioChecked__icon--checked(
    v-if="isChecked"
  )
  unCheckIcon.radioChecked__icon.radioChecked__icon--unchecked(
    v-else
  )
</template>

<script>
import checkIcon from '@/assets/images/check-circle-24-px.svg';
import unCheckIcon from '@/assets/images/uncheck-circle-24-px.svg';

export default {
  name: 'RadioCheckedUI',
  components: {
    checkIcon,
    unCheckIcon
  },
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .radioChecked {
    &__icon {
      border-radius: 50%;
    }
  }
</style>
