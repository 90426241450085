<template lang="pug">
.savePanel
  .savePanel__header
    BaseBtnLastPage(
      @click="$emit('savePanelClosed')"
    )
    h3.savePanel__title 儲存地點
  .savePanel__image
    BaseIconStarWithValue.savePanel__iconStar
  p.savePanel__subtitle 地點名稱
  .savePanel__inputWrapper
    BaseInputAddress(
      placeholder="例如：公司"
      :value="customizedName"
      @input="customizedNameInputHandler"
      @inputCleared="$emit('customizedNameCleared')"
    )
  p.savePanel__subtitle 地址
  .savePanel__inputWrapper
    BaseInputAddress(
      :value="locationName"
      :disabled="true"
    )
  .savePanel__btnWrapper
    BaseBtnConfirm(
      :text="'儲存'"
      :disabled="isButtonDisabled"
      @click="$emit('saveLocation')"
    )
</template>
<script>
import BaseBtnLastPage from '@/components/BaseComponent/BaseBtnLastPage';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import BaseInputAddress from '@/components/BaseComponent/BaseInputAddress';
import BaseIconStarWithValue from '@/components/BaseComponent/BaseIconStarWithValue';

export default {
  props: {
    locationName: {
      type: String,
      required: true,
    },
    customizedName: {
      type: String,
      required: true,
    },
  },
  computed: {
    isButtonDisabled() {
      return this.customizedName === '';
    },
  },
  methods: {
    customizedNameInputHandler(event) {
      const { value } = event.target;
      this.$emit('customizedNameChanged', value);
    }
  },
  components: {
    BaseBtnConfirm,
    BaseBtnLastPage,
    BaseInputAddress,
    BaseIconStarWithValue,
  },
};
</script>
<style lang="scss" scoped>
  .savePanel {
    position: relative;
    width: 100vw;
    height: 100vh;

    &__header {
      padding: 8px;
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }

    &__title {
      margin: 0;
      margin-left: 16px;
      font-size: 20px;
      font-weight: normal;
    }

    &__image {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin: 24px auto;
      background: #ff9900;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__iconStar {
      width: 30px;
      height: 30px;
      background-color: #ffffff;
    }

    &__subtitle {
      margin: 0;
      margin-bottom: 8px;
      padding: 0 16px;
      height: 16px;
      line-height: 16px;
      font-size: 12px;
    }

    &__inputWrapper {
      margin-bottom: 16px;
      padding: 0 16px;
    }

    &__btnWrapper {
      position: absolute;
      left: 0;
      bottom: 16px;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
</style>
