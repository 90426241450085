<template lang="pug">
.serviceOptions
  PathDirectionsMap.serviceOptions__map(
    :class="{'serviceOptions__map--reservation': isReservationMode || carTypeList.length === 0}"
    :pickUp="pickUpLocation"
    :dropOff="dropOffLocation"
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :pathDirections="pathDirections"
  )
  .serviceOptions__mask(
    v-if="showCarTypeList"
  )
  ServiceOptionsForm.serviceOptions__form(
    :class="optionsFormStyleObj"
    :showCarTypeList="showCarTypeList"
    :payment="payment"
    :passenger="passenger"
    :extraServices="extraServices"
    :carType="carType"
    :carTypeList="carTypeList"
    :taxiList="taxiList"
    :customerNote="customerNote"
    :isReservationMode="isReservationMode"
    :distance="distance"
    :duration="duration"
    :steps="steps"
    :currentStep="currentStep"
    @paymentChanged="setHailingPayment"
    @passengerChanged="setHailingPassenger"
    @extraServicesChanged="setExtraServices"
    @carTypeChanged="carTypeChangeHandler"
    @setShowCarTypeList="setShowCarTypeList"
    @customerNoteChanged="setCustomerNote"
    @hailingConfirmed="openConfirmBillModel"
    @extraServicesChangedApi="extraServicesChangedApi"
    @nextStep="nextStep"
    @preStep="preStep"
    @setStep="setStep"
    @options="serviceOptionsfun"
  )
  SuccessModal.serviceOptions__modal(
    v-if="showSuccessModal"
    :title="'成功送出預約單'"
    :text="'請至「我的預約行程」選擇您的司機送出。'"
    :icon="success"
    @confirm="reservationConfirmHandler"
  )
  SuccessModal.serviceOptions__modal(
    v-if="showFailureModal"
    :title="'預約單傳送失敗'"
    :text="''"
    :icon="success"
    @confirm="reservationConfirmHandler"
  )
  ConfirmBillInformation.serviceOptions__modal(
    v-if="isShowConfirmModal"
    :pickUpName="pickUpName"
    :dropOffName="dropOffName"
    :taxiType="taxiType"
    :payment="payment"
    :extraServices="extraServices"
    :price="'100'"
    :note="customerNote"
    :reservationTime="reservationTime"
    @modify="closeConfirmBillModal"
    @confirm="hailingConfirmHandler"
  )
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PathDirectionsMap from "@/components/common/PathDirectionsMap";
import ServiceOptionsForm from "@/components/ServiceOptionsForm";
import SuccessModal from "@/components/common/SuccessModal";
import { postReservation } from "@/api/reservationService";
import {
  postNewHailing,
  getHailingDriverInfo,
  getExtraServiceOptions
} from "@/api/hailingService";
import { getReservationFormatString } from "@/utils/timeUtils";
import ConfirmBillInformation from "@/components/common/ConfirmBillInformation";

import { carFilterList } from "@/utils/CarFilterUtils";
export default {
  data() {
    return {
      isSubmit: false,
      showCarTypeList: false,
      showSuccessModal: false,
      showFailureModal: false,
      isShowConfirmModal: false,
      carTypeList: carFilterList(),
      success: false,
      currentStep: 1,
      options: null
    };
  },
  computed: {
    ...mapGetters({
      mode: "hailingInfo/getHailingMode",
      pickUpName: "hailingInfo/getPickUpName",
      pickUpLocation: "hailingInfo/getPickUpLocation",
      pickUpPostalNumber: "hailingInfo/getPickUpPostalNumber",
      dropOffName: "hailingInfo/getDropOffName",
      dropOffLocation: "hailingInfo/getDropOffLocation",
      dropOffPostalNumber: "hailingInfo/getDropOffPostalNumber",
      payment: "hailingInfo/getPayment",
      passenger: "hailingInfo/getPassenger",
      extraServices: "hailingInfo/getExtraServices",
      carType: "hailingInfo/getCarType",
      taxiList: "hailingInfo/getTaxiList",
      customerNote: "hailingInfo/getCustomerNote",
      pathDirections: "hailingInfo/getPathDirections",
      reservationTime: "hailingInfo/getReservationTime",
      isHailingSuccess: "hailingInfo/getIsHailingSuccess",
      distance: "hailingInfo/getDistance",
      duration: "hailingInfo/getDuration",
      repeated: "hailingInfo/getRepeated",
      testCarTeam: "hailingInfo/getTestCarTeam"
    }),
    optionsFormStyleObj() {
      return {
        "serviceOptions__form--extended": this.showCarTypeList,
        "serviceOptions__form--reservation":
          this.isReservationMode || this.carTypeList.length === 0
      };
    },
    isReservationMode() {
      return this.mode === "reservation";
    },
    taxiType() {
      const { carType } = carFilterList()[this.carType];
      return carType;
    },
    steps() {
      // if (this.isReservationMode === false
      //   && this.carTypeList.length !== 0) {
      //     return [1, 2, 3];
      //   } else {
      //     return [1, 2];
      //   }

      // update from 3 steps to 2 steps
      return [1, 2];
    }
  },
  methods: {
    ...mapActions({
      updateTaxiList: "hailingInfo/updateTaxiList",
      updatePathDirections: "hailingInfo/updatePathDirections",
      setHailingPayment: "hailingInfo/setHailingPayment",
      setHailingPassenger: "hailingInfo/setHailingPassenger",
      setExtraServices: "hailingInfo/setExtraServices",
      setHailingCarType: "hailingInfo/setHailingCarType",
      setCustomerNote: "hailingInfo/setCustomerNote",
      setIsSearching: "hailingInfo/setIsSearching",
      setIsHailingSuccess: "hailingInfo/setIsHailingSuccess",
      setOrderID: "orderInfo/setOrderID",
      updateOrderInfo: "orderInfo/updateOrderInfo",
      setEstimatedTime: "orderInfo/setEstimatedTime",
      setRepeated: "hailingInfo/setRepeated",
      setRepeatedMsg: "hailingInfo/setRepeatedMsg"
    }),
    closeConfirmBillModal() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選修改(派車單畫面)"
      });
      this.saveBehavior({
        event: "點擊",
        type: 1,
        note: `點選修改(派車單畫面)`
      });
      this.isShowConfirmModal = false;
    },
    openConfirmBillModel() {
      this.isShowConfirmModal = true;
    },
    setShowCarTypeList(status) {
      this.showCarTypeList = status;
    },
    carTypeChangeHandler(idx) {
      //console.log(idx);
      this.setHailingCarType(idx);
      this.setShowCarTypeList(false);
    },
    extraServicesChangedApi(service) {
      this.updateTaxiList();
    },
    hailingConfirmHandler() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選確認(派車單畫面)"
      });
      this.saveBehavior({
        event: "點擊",
        type: 1,
        note: `點選確認(派車單畫面)`
      });
      this.closeConfirmBillModal();
      if (!this.isSubmit) {
        this.isSubmit = true;
        this.isReservationMode
          ? this.reservationHailing()
          : this.immediateHailing();
        setTimeout(() => {
          this.isSubmit = false;
        }, 2000);
      }
    },
    serviceOptionsfun(options) {
      this.options = options;
    },
    async immediateHailing() {
      let commet = "";
      for (let j = 0; j < this.extraServices.length; j++) {
        for (let i = 0; i < this.options.optionsMultiple.length; i++) {
          if (this.options.optionsMultiple[i].id === this.extraServices[j]) {
            commet = commet + `,${this.options.optionsMultiple[i].name}`;
          }
        }
        for (let i = 0; i < this.options.optionsNoMultiple.length; i++) {
          if (this.options.optionsNoMultiple[i].id === this.extraServices[j]) {
            commet = commet + `,${this.options.optionsNoMultiple[i].name}`;
          }
        }
      }
      const { seatNumber, taxiSort } = carFilterList()[this.carType];
      const hailingInfo = {
        taxiSort: taxiSort,
        seatNumber: seatNumber,
        onLocation: this.pickUpName,
        onLocationLng: this.pickUpLocation.lng(),
        onLocationLat: this.pickUpLocation.lat(),
        passenNum: this.passenger,
        passenComment: "LINE-叫車|" + this.customerNote + commet,
        payType: this.payment,
        serviceList: this.extraServices.join(","),
        repeated: this.repeated
      };
      if (this.payment === 1) {
        // Line pay
        // hailingInfo.serviceList = hailingInfo.serviceList === '' ? '9':hailingInfo.serviceList+',9'
        hailingInfo.passenComment =
          hailingInfo.passenComment + ",付款方式: Line Pay";
      } else if (this.payment === 2) {
        // 信用卡
        // hailingInfo.serviceList = hailingInfo.serviceList === '' ? '10':hailingInfo.serviceList+',10'
        hailingInfo.passenComment =
          hailingInfo.passenComment + ",付款方式: 信用卡付款";
      }

      const hailingConfig =
        this.dropOffName === ""
          ? { ...hailingInfo }
          : {
              ...hailingInfo,
              offLocation: this.dropOffName,
              offLocationLng: this.dropOffLocation.lng(),
              offLocationLat: this.dropOffLocation.lat(),
              repeated: this.repeated
            };

      try {
        this.setIsSearching(true);
        this.$router.push("/HailingStatus");
        const { data } = await postNewHailing(hailingConfig);
        if (data.success) {
          this.setRepeated(0);
          if (data.result === "無車輛可派") {
            this.setIsHailingSuccess(false);
          } else {
            this.hailingSuccessHandler(data.result.orderID);
            this.setEstimatedTime(data.result.estimatedTime);
          }
        } else {
          /* console.log("ServiceOptions data.success false"); */
          /* 僅針對大豐車隊開放重複叫車提示功能 */
          if (
            (document.URL.indexOf("dihon.line.gladmobile.com") > -1 ||
              this.testCarTeam === "dihon") &&
            (data.message.includes("尋找司機中，是否要再叫一輛") ||
              data.message.includes("已有司機接單，是否要再叫一輛"))
          ) {
            /* console.log("ServiceOptions repeated msg", data.message); */
            this.setRepeated(1);
            this.setRepeatedMsg(data.message);
          } else {
            this.setRepeated(0);
            this.setIsHailingSuccess(false);
          }
        }
        /* if( result === '無車輛可派') {
          this.setIsHailingSuccess(false);
        }
        else {
          this.hailingSuccessHandler(result.orderID);
          this.setEstimatedTime(result.estimatedTime)
        } */
      } catch (err) {
        console.error(err);
        this.setIsHailingSuccess(false);
      } finally {
        this.setIsSearching(false);
      }
    },
    async hailingSuccessHandler(orderID) {
      //console.log(orderID)
      this.setOrderID(orderID);
      try {
        const { data } = await getHailingDriverInfo(orderID, "instant");
        const { result } = data;
        this.updateOrderInfo({ ...result });
        this.setIsSearching(false);
        this.setIsHailingSuccess(true);
      } catch (err) {
        console.error(err);
      }
    },
    async reservationHailing() {
      try {
        let payload = {
          reservationTime: getReservationFormatString(this.reservationTime),
          passengerNote: this.customerNote,
          paymentMethod: this.payment,
          userNumber: this.passenger,
          onLocation: this.pickUpName,
          onLat: this.pickUpLocation.lat(),
          onLng: this.pickUpLocation.lng(),
          onPostalCode: this.pickUpPostalNumber,
          offLocation: this.dropOffName,
          offLat: this.dropOffLocation.lat(),
          offLng: this.dropOffLocation.lng(),
          offPostalCode: this.dropOffPostalNumber,
          serviceList: this.extraServices.join(","),
          reservationType: 0
        };
        if (this.payment === 1) {
          // Line pay
          payload.serviceList =
            payload.serviceList === "" ? "9" : payload.serviceList + ",9";
        } else if (this.payment === 2) {
          // 信用卡
          payload.serviceList =
            payload.serviceList === "" ? "10" : payload.serviceList + ",10";
        }
        let result = await postReservation(payload);
        if (result.data.success) {
          this.success = result.data.success;
          this.showSuccessModal = true;
        } else {
          this.success = false;
          this.showFailureModal = true;
        }
      } catch (err) {
        console.error(err);
      }
    },
    initReservationInfo() {
      this.updatePathDirections();
    },
    async initHailingInfo() {
      if (this.dropOffLocation !== null) {
        await this.updatePathDirections();
      } else {
        await this.updateTaxiList();
      }
    },
    reservationConfirmHandler() {
      this.vue.gtm.trackEvent({
        event: "click",
        clickEvent: "點選前往預約畫面"
      });
      this.$router.push("ReservationRecords");
    },
    initPassengerInfo() {
      this.setHailingPassenger(1);
    },
    nextStep() {
      // if(this.currentStep === 3) return;
      // this.currentStep += 1;

      // change to only two step
      if (this.currentStep === 2) return;
      this.currentStep += 1;
    },
    preStep() {
      if (this.currentStep === 1) return;
      this.currentStep--;
    },
    setStep(step) {
      //console.log('step', step);
      this.currentStep = step;
    }
  },
  components: {
    SuccessModal,
    PathDirectionsMap,
    ServiceOptionsForm,
    ConfirmBillInformation
  },
  created() {
    this.initPassengerInfo();
  },
  mounted() {
    this.isReservationMode
      ? this.initReservationInfo()
      : this.initHailingInfo();
  }
};
</script>
<style lang="scss" scoped>
.serviceOptions {
  height: 100vh;
  position: relative;

  &__map {
    height: calc(100vh - 327px);

    &--reservation {
      height: calc(100vh - 304px);
    }
  }

  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  &__form {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    height: 327px;

    &--extended {
      height: 95vh;
    }

    &--reservation {
      height: 304px;
    }
  }

  &__modal {
    z-index: 1;
  }
}
</style>
