function letterOrNumberOnly(value) {
  const regExp = /^[A-Za-z\d]+$/;
  return regExp.test(value);
}

function positiveInteger(value) {
  const regExp = /^[\d]+$/;
  return regExp.test(value);
}

export {
  letterOrNumberOnly,
  positiveInteger
}