<template lang="pug">
.hailingStatusModal__popup.hailingStatusModal__popup--fail
  BaseConfirmModal(
    :title="repeatedMsg"
    :confirmBtnText="'確定'"
    :cancelBtnText="'取消'"
    @confirm="$emit('confirmRepeated')"
    @cancel="$emit('cancelRepeated')"
  )
</template>
<script>
import { mapGetters } from "vuex";
import BaseConfirmModal from "@/components/BaseComponent/BaseConfirmModal";
import HailingDetailTable from "./HailingDetailTable";

export default {
  components: {
    BaseConfirmModal,
    HailingDetailTable
  },
  computed: {
    ...mapGetters({
      repeatedMsg: "hailingInfo/getRepeatedMsg",
    })
  },
};
</script>
<style lang="scss" scoped>
.hailingStatusModal {
  &__detail {
    margin: 0 auto;
    margin-bottom: 60px;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
  }
}
</style>
