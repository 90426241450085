import axiosConfig from './axiosConfig';
import { sub_domain } from './default'

export const creditCardPay = event => axiosConfig
  .post(`/line/api/${sub_domain}/credit_card/check`, event);

export const linePay = event => axiosConfig
  .post(`/line/api/${sub_domain}/linepay/redirect`, event);

export const immediateLinePay = event => axiosConfig
  .post(`/line/api/web/${sub_domain}/passenger/immediate/line_pay`, event);

export const checkLinePay = code => axiosConfig
  .get(`/line/api/web/${sub_domain}/passenger/check/line_pay/${code}`);