import Vue from 'vue'
import Vuex from 'vuex'
import geolocation from './geolocation';
import hailingInfo from './hailingInfo';
import autoComplete from './autoComplete';
import orderInfo from './orderInfo';
import billSigning from './billSigning';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isReload: false
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    geolocation,
    hailingInfo,
    autoComplete,
    orderInfo,
    billSigning
  },
});
