export default {
  enterpriseOptions(state, enterpriseOptions) {
    state.enterpriseOptions = enterpriseOptions;
  },
  driverID(state, driverID) {
    state.driverID = driverID;
  },
  onLocation(state, onLocation) {
    state.onLocation = onLocation;
  },
  offLocation(state, offLocation) {
    state.offLocation = offLocation;
  },
  actualFare(state, actualFare) {
    state.actualFare = actualFare;
  },
  enterpriseID(state, enterpriseID) {
    state.enterpriseID = enterpriseID;
  },
  enterpriseName(state, enterpriseName) {
    state.enterpriseName = enterpriseName;
  },
  employeeID(state, employeeID) {
    state.employeeID = employeeID;
  },
  jobNumber(state, jobNumber) {
    state.jobNumber = jobNumber;
  },
  password(state, password) {
    state.password = password;
  },
  passengerNote(state, passengerNote) {
    state.passengerNote = passengerNote;
  },
  employeeName(state, employeeName) {
    state.employeeName = employeeName;
  },
  driverName(state, driverName) {
    state.driverName = driverName;
  }
};