<template lang="pug">
button.timeSelector(
  @click="$emit('click')"
)
  span.timeSelector__text {{ dateString }}
  span.timeSelector__text {{ meridiemValue }} {{ timeString }} 
</template>
<script>
import { getDateString, getTimeString } from '@/utils/timeUtils';
import moment from 'moment'
export default {
  props: {
    reservationTime: {
      required: true,
    },
  },
  computed: {
    dateString() {
      //console.log(moment(this.reservationTime).format('YYYY-MM-DD HH:mm:ss'))
      return getDateString(this.reservationTime);
    },
    timeString() {
      return getTimeString(moment(this.reservationTime).format('YYYY-MM-DD HH:mm:ss'));
    },
    meridiemValue() {
      return moment(this.reservationTime).get('hour') > 12? '下午': '上午'
    },
  },
};
</script>
<style lang="scss" scoped>
  .timeSelector {
    width: 100%;
    border: none;
    outline: none;
    height: 48px;
    line-height: 48px;
    border-radius: 12px;
    background-color: #efeeea;
    text-align: center;
    

    &__text {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.87);

      &:last-of-type {
        margin-left: 8px;
      }
    }
  }
</style>
