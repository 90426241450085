import {
  SET_PREDICT_LOCATIONS,
  CLEAR_PREDICT_LOCATIONS,
  SET_CURRENT_INPUT,
} from './mutation-types';

export default {
  setPredictLocations({ commit }, locations) {
    commit(SET_PREDICT_LOCATIONS, locations);
  },
  clearPredictLocations({ commit }) {
    commit(CLEAR_PREDICT_LOCATIONS);
  },
  setCurrentInput({ commit }, currentInput) {
    commit(SET_CURRENT_INPUT, currentInput);
  },
};
