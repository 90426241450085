import {webUrl} from '../../api/default'

const defaultLatLng = {
  lat: 22.997195,
  lng: 120.212343,
};

export default {
  getIsGeoEnabled: state => state.isGeoEnabled,
  getCurrentLatLnt: state => state.currentLatLng || defaultLatLng,
  geoErrorStatus: state => state.geoErrorStatus,
};
