<template lang="pug">
.addressSetup
  PageHeader(
    @toLastPage="toPreviousPage"
  )
  AddressSetupForm.addressSetup__form(
    :class="{ 'addressSetup__form--gmap': showGoogleMap }"
    :pickUp="pickUpName"
    :dropOff="dropOffName"
    :isPickUpMode="isPickUpMode"
    :pickUpLacation="pickUpLacation"
    :showLocationSetupMapLink="showLocationSetupMapLink"
    @addressNameChange="addressNameChangeHandler"
    @addressNameCleared="addressNameClearHandler"
    @autoCompleteQuery="autoCompleteQueryHandler"
    @currentInputChange="setCurrentInput"
    @clearPredictLocations="clearPredictLocationsHandler"
  )
  LocationConfirmMap.addressSetup__map(v-if="showGoogleMap"
    :center="dropOffLocation"
    @confirm="toServiceOptions"
  )
  ListItemSave.addressSetup__listItem--save(
    v-else-if="showToSavedLocations"
    :title="'已儲存的地點'"
    @click="toSavedLocations"
  )
  LocationList.addressSetup__list(v-else
    :class="{'addressSetup__list--showBtn': showConfirmBtn }"
    :locationItems="predictLocations"
    @locationSelected="locationSelectedHandler"
  )
  .addressSetup__btnWrapper(v-if="showConfirmBtn")
    BaseBtnConfirm(
      :text="'上車再跟司機講'"
      @click="toServiceOptions"
    )
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import PageHeader from '@/components/common/PageHeader';
import AddressSetupForm from '@/components/AddressSetupForm';
import LocationList from '@/components/LocationList';
import ListItemSave from '@/components/common/ListItemSave';
import LocationConfirmMap from '@/components/common/LocationConfirmMap';
import { extractLocationsToListFormat } from '@/utils/googleUtils';

export default {
  computed: {
    ...mapGetters({
      mode: 'hailingInfo/getHailingMode',
      pickUpName: 'hailingInfo/getPickUpName',
      dropOffName: 'hailingInfo/getDropOffName',
      pickUpLacation: 'hailingInfo/getPickUpLocation',
      dropOffLocation: 'hailingInfo/getDropOffLocation',
      predictLocations: 'autoComplete/getPredictLocations',
      currentInput: 'autoComplete/getCurrentInput',
    }),
    isReservationMode() {
      return this.mode === 'reservation';
    },
    isPickUpMode() {
      //console.log()
      return this.currentInput === 'pickUp';
    },
    showToSavedLocations() {
      return this.predictLocations.length === 0;
    },
    showGoogleMap() {
      return this.pickUpLacation !== null
        && this.dropOffLocation !== null;
    },
    showLocationSetupMapLink() {
      return this.showGoogleMap === false;
    },
    showConfirmBtn() {
      return this.isReservationMode === false
        && this.pickUpLacation !== null
        && this.dropOffLocation === null;
    },
  },
  methods: {
    ...mapActions({
      setHailingPickUpName: 'hailingInfo/setHailingPickUpName',
      setHailingDropOffName: 'hailingInfo/setHailingDropOffName',
      setHailingPickUpLocation: 'hailingInfo/setHailingPickUpLocation',
      setHailingDropOffLocation: 'hailingInfo/setHailingDropOffLocation',
      setHailingPickUpPostalNumber: 'hailingInfo/setHailingPickUpPostalNumber',
      setHailingDropOffPostalNumber: 'hailingInfo/setHailingDropOffPostalNumber',
      clearPredictLocations: 'autoComplete/clearPredictLocations',
      setPredictLocations: 'autoComplete/setPredictLocations',
      setCurrentInput: 'autoComplete/setCurrentInput',
    }),
    autoCompleteQueryHandler(locations) {
      // 當按input 的時候會觸發
      this.clearPredictLocationsHandler(false);
      this.updataPredictLocations(locations);
    },
    updataPredictLocations(locations) {
      const predictLocations = extractLocationsToListFormat(locations);
      this.setPredictLocations(predictLocations);
    },
    addressNameChangeHandler(name) {
      //console.log('addressNameChangeHandler')
      if(this.isPickUpMode){
        this.setHailingPickUpName(name)
        this.setHailingPickUpLocation(null)
      }else{
        this.setHailingDropOffName(name);
      }
    },
    addressNameClearHandler(item) {
      item === 'pickUp'
        ? this.setHailingPickUpName('')
        : this.setHailingDropOffName('');
      this.setPredictLocations([]);
    },
    async locationSelectedHandler(place_id) {
      const { title } = this.predictLocations
        .filter(location => location.place_id === place_id)
        .pop();

      try {
        let geocoder =  new google.maps.Geocoder()
        geocoder.geocode({ placeId: place_id }, (results, status) => {
          if (status !== "OK") {
            window.alert("Geocoder failed due to: " + status);
            return;
          }
          const { address_components, geometry } = results[0];
          const { long_name } = address_components
            .filter(({ types }) => types.includes('postal_code'))
            .pop();
          if (this.isPickUpMode) {
            this.saveBehavior({
              event: '點擊',
              type: 1,
              note: `上車地點: ${title}`
            })
            this.setHailingPickUpName(title);
            this.setHailingPickUpLocation(geometry.location);
            this.setHailingPickUpPostalNumber(long_name);
          } else {
            this.saveBehavior({
              event: '點擊',
              type: 1,
              note: `下車地點: ${title}`
            })
            this.setHailingDropOffName(title);
            this.setHailingDropOffLocation(geometry.location);
            this.setHailingDropOffPostalNumber(long_name);
          }

          this.setPredictLocations([]);
        });
      } catch(err) {
        window.alert(err);
      }
    },
    clearPredictLocationsHandler(isClear) {
      //console.log('this.isPickUpMode', this.isPickUpMode)
      this.clearPredictLocations();
      if(isClear){
        this.isPickUpMode
        ? this.setHailingPickUpLocation(null)
        : this.setHailingDropOffLocation(null);
      }
    },
    toServiceOptions() {
      this.vue.gtm.trackEvent({
          event: 'click',
          clickEvent: '點擊上車再跟司機說'
      })
      this.saveBehavior({
          event: '點擊',
          type: 1,
          note: `點擊上車再跟司機說`
        })
      this.$router.push('/ServiceOptions');
    },
    toPreviousPage() {
      this.saveBehavior({
          event: '點擊',
          type: 1,
          note: `回上一頁`
        })
      this.$router.back();
    },
    toSavedLocations() {
      this.saveBehavior({
        event: '點擊',
        type: 1,
        note: `選擇儲存地址頁面畫面`
      })
      this.$router.push('/SavedLocations');
    },
  },
  components: {
    AddressSetupForm,
    BaseBtnConfirm,
    PageHeader,
    LocationList,
    ListItemSave,
    LocationConfirmMap,
  },
};
</script>
<style lang="scss" scoped>
  .addressSetup {
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    box-sizing: border-box;
    position: relative;

    &__form {
      &--gmap {
        padding-bottom: 20px;
      }
    }

    &__map {
      width: 100vw;
      height: calc(100vh - 220px);
    }

    &__listItem {

      &--save {
        margin-top: 8px;
      }
    }

    &__list {
      overflow-y: auto;
      height: calc(100vh - 240px);

      &--showBtn {
        height: calc(100vh - 320px);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.16);
      }
    }

    &__btnWrapper {
      position: absolute;
      left: 0;
      bottom: 16px;
      width: 100%;
      padding: 0 8px;
      box-sizing: border-box;
    }
  }
</style>
