import {
  paymentOptions,
  // extraServiceOptions,
} from '@/constant/optionConstants';

import {getExtraServiceOptions} from '../api/hailingService' 

export function getPaymentName(value) {
  const payment = paymentOptions
    .filter(option => option.value === value)
    .pop();
  
  return payment.description;
};

export function getExtraServiceName(id) {

   return getExtraServiceOptions()
    .then(res => res.data.result)
    .then(res => {
       let result = res.filter(option => option.id === id).pop().name 
       console.log(typeof result)
       return result
    })
  // console.log(extraServiceOptions)
  //
  // const extraService = extraServiceOptions
  //   .filter(option => option.id === id)
  //   .pop();
  //
  // return extraService.name;
};

