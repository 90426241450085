<template lang="pug">
button.centerMap(
  @click="$emit('click')"
)
  centerMapIcon.centerMap__icon
</template>

<script>
import centerMapIcon from '@/assets/images/location-searching-24-px.svg';

export default {
  name: 'BaseBtnCenterMap',
  components: {
    centerMapIcon
  }
}
</script>

<style lang="scss" scoped>
  .centerMap {
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    padding: 8px;
    &__icon {
      vertical-align: top;
    }
  }
</style>
