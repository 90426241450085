import {
  fetchEnterpiseOptions,
  fetchTemporaryBill,
  fetchEmployeeIfExist,
  addBillSigning 
} from '@/api/billSigning.js';

export default {
  updateBillSigning({ commit }, billSigning ) {
    const {
      driver_id,
      on_location,
      off_location,
      actual_fare,
      driverName
    } = billSigning;

    commit('driverID', driver_id);
    commit('onLocation', on_location);
    commit('offLocation', off_location);
    commit('actualFare', actual_fare);
    commit('driverName', driverName);
  },

  async fetchEnterpiseOptions({ commit }, code) {
    const options = await fetchEnterpiseOptions(code);
    console.log('options', options)
    commit('enterpriseOptions', options);
  },
  async fetchTemporaryBill({ dispatch }, authCode) {
    const temperaryBill = await fetchTemporaryBill(authCode);
    console.log('temperaryBill', temperaryBill);
    dispatch('updateBillSigning', temperaryBill);
  },
  async fetchEmployeeIfExist(context, employeeData) {
    const result = await fetchEmployeeIfExist(employeeData);
    return result;
  },
  async addBillSigning(context, enterpriseFromData) {
    const payload = {...enterpriseFromData, passengerNote: enterpriseFromData.passengerNote + ',Line'}
    const result = await addBillSigning(payload);
    return result;
  }
};
