// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyDuym5oMWkTL5OEDutp2sbhzVIbThtwn74",
  authDomain: "driver-message.firebaseapp.com",
  databaseURL: "https://driver-message.firebaseio.com",
  projectId: "driver-message",
  storageBucket: "driver-message.appspot.com",
  messagingSenderId: "470677438835",
  appId: "1:470677438835:web:68398277e517faadd2c506"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// singleton 單體模式 http://cythilya.blogspot.com/2015/06/javascript-design-pattern-singleton.html
let db = null
if (db == null) db = firebase.firestore();

function dateNow() {
  const now = firebase.firestore.FieldValue.serverTimestamp();
  //console.log(now);
  return now;
}

export default {
  db,
  dateNow
}
// export const db = firebase.firestore()
// export const firebase = firebase