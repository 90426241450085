<template lang="pug">
.serviceOptionsForm
  StepProgress(
    :steps="steps"
    :currentStep="currentStep"
    @setStep="$emit('setStep', $event)"
  )
  .serviceOptionsForm__stepOne(
    v-if="currentStep === 1"
  )
    <!-- .serviceOptionsForm__titleWrapper -->
    <!--   p.serviceOptionsForm__importantTitleRight( -->
    <!--     v-if="false" -->
    <!--   ) 路程: {{Math.ceil(distance/1000)}} 公里/{{Math.ceil(duration/60)}}分鐘 -->
    <!--   //- p.serviceOptionsForm__importantTitleLeft 時間: {{Math.ceil(duration/60)}} 分鐘 -->
    <!--   p.serviceOptionsForm__importantTitleLeft 預估金額: {{ taxiList.length !== 0 ?  `${taxiList[0].minFare} ~ ${taxiList[0].maxFare}` : `---` }}  -->
      
    PaymentOptionSelector.serviceOptionsForm__paymentOption(
      :payment="payment"
      @paymentChanged="paymentChangeHandler"
    )
    PassengerOptionSelector.serviceOptionsForm__passengerOption(
      :passenger="passenger"
      :showCarTypeSelector="showCarTypeSelector"
      @passengerChanged="passengerChangeHandler"
      @carTypeChanged="carTypeChangeHandler"
    )
  .serviceOptionsForm__stepTwo(
    v-if="showCarTypeSelector ? currentStep === 2 : false"
  )
    .serviceOptionsForm__hideCarTypes(
      v-if="showCarTypeList"
      @click="$emit('setShowCarTypeList', false)"
    )
      //- .serviceOptionsForm__angleDownIcon
    CarTypeSelector.serviceOptionsForm__carType(
      v-if="showCarTypeSelector"
      :carType="carType"
      :taxiList="taxiList"
      :showCarTypeList="showCarTypeList"
      :passenger="passenger"
      @carTypeChanged="carTypeChangeHandler"
    )
    //- .serviceOptionsForm__showCarTypes(
    //-   v-if="showCarTypesToggle"
    //-   @click="$emit('setShowCarTypeList', true)"
    //- )
      //- p.serviceOptionsForm__text 看更多車型
      //- .serviceOptionsForm__angleUpIcon
  .serviceOptionsForm__stepTwo(
    v-if="showCarTypeSelector ? currentStep === 3 : currentStep === 2"
  )
    .serviceOptionsForm__titleWrapper
      p.serviceOptionsForm__importantTitleLeft 預估金額: {{ this.handleMultiple() }} 
    ExtraServiceSelector.serviceOptionsForm__extraService(
      :extraServices="extraServices"
      @extraServicesChanged="extraServicesChangeHandler"
    )
    p.serviceOptionsForm__title 其他備註
    .serviceOptionsForm__penIcon
    BaseInput.serviceOptionsForm__note(
      :placeholder="'請填寫備註'"
      :value="customerNote"
      @input="customerNoteChangeHandler"
    )
  .serviceOptionsForm__btn(
    :class="{'serviceOptionsForm__btn--reservation': !showCarTypesToggle}"
  )
    BaseBtnPreStep.serviceOptionsForm__btn__preStep(
      v-if="currentStep !== 1"
      :text="'上一步'"
      @click="$emit('preStep')"
    )
    BaseBtnNextStep.serviceOptionsForm__btn__nextStep(
      v-if="showCarTypeSelector ? currentStep < 3 : currentStep < 2"
      :text="'下一步'"
      @click="$emit('nextStep')"
    )
    BaseBtnConfirm(
      v-if="showCarTypeSelector ? currentStep === 3 : currentStep === 2"
      :text="'開始叫車'"
      @click="$emit('hailingConfirmed')"
    )
</template>
<script>
import PaymentOptionSelector from './PaymentOptionSelector';
import PassengerOptionSelector from './PassengerOptionSelector';
import ExtraServiceSelector from './ExtraServiceSelector';
import CarTypeSelector from './CarTypeSelector';
import StepProgress from '@/components/common/StepProgressBar';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';
import BaseBtnNextStep from '@/components/BaseComponent/BaseBtnNextStep';
import BaseBtnPreStep from '@/components/BaseComponent/BaseBtnPreStep';
import BaseInput from '@/components/BaseComponent/BaseInput';
import arrowDownIcon from '@/assets/images/keyboard-arrow-down-24-px.svg';
import arrowUpIcon from '@/assets/images/keyboard-arrow-up-24-px.svg';
import penIcon from '@/assets/images/pen.svg';

import {finalPrice, distinguishMultiple} from '../../utils/multipleValue.js'
import {getExtraServiceOptions} from '../../api/hailingService.js'

export default {
  name: 'ServiceOptionFrom',
  data(){
    return{
      options:null
    }
  },
  props: {
    duration:{
      type: Number,
      required: false,
    },
    distance:{
      type: Number,
      required: false,
    },
    payment: {
      type: Number,
      required: true,
    },
    passenger: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    carType: {
      type: Number,
      required: true,
    },
    taxiList: {
      type: Array,
      required: true,
    },
    showCarTypeList: {
      type: Boolean,
      required: true,
    },
    customerNote: {
      type: String,
      required: true,
    },
    isReservationMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    carTypeList: {
      type: Array,
      required: true,
    },
    steps: {
      type: Array,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  }, 
  computed: {
    showCarTypeSelector() {
      // return this.isReservationMode === false
      //   && this.carTypeList.length !== 0
      return false
    },
    showCarTypesToggle() {
      return this.showCarTypeList === false
        && this.isReservationMode === false
        && this.carTypeList.length !== 0;
    },
    showExtraServiceSelector() {
      // if(this.showCarTypeSelector) {
      //   return this.currentStep === 2; 
      // } else {
      //   return this.currentStep === 3; 
      // }

      return this.currentStep === 2
    },
    
  },
  mounted() {
    getExtraServiceOptions()
      .then(res => res.data.result)   
      .then(result => {
        this.options = distinguishMultiple(result)
        this.$emit('options', this.options);
      })
      .catch(error => console.error(error))
  },
  methods: {
    paymentChangeHandler(payment) {
      this.$emit('paymentChanged', payment);
    },
    passengerChangeHandler(passenger) {
      this.$emit('passengerChanged', passenger);
    },
    extraServicesChangeHandler(extraServices) {
      this.$emit('extraServicesChanged', extraServices);
      this.$emit('extraServicesChangedApi', extraServices)
    },
    carTypeChangeHandler(idx) {
      this.$emit('carTypeChanged', idx);
    },
    customerNoteChangeHandler(event) {
      const { value } = event.target;
      this.$emit('customerNoteChanged', value);
    },
    isObjNull(obj){
      Object.values(obj).every(value => {
        if (value === null) {
          return true;
        }

      return false;
      });
    },
    handleMultiple(){
      const optionsMultipleId = this.options.optionsMultiple.map(item => item.id) 
      const multipleId = this.extraServices.filter(id => optionsMultipleId.indexOf(id) != -1)[0] 
      const multiple = multipleId ? 
        this.options.optionsMultiple.find(obj => obj.id === multipleId) : null
      const now = new Date()
      return this.taxiList.length !== 0 ? 
             finalPrice(this.taxiList[0].minFare, this.taxiList[0].maxFare, multiple, now) : 
             `---`
    }
  },
  components: {
    PaymentOptionSelector,
    PassengerOptionSelector,
    ExtraServiceSelector,
    CarTypeSelector,
    StepProgress,
    BaseBtnConfirm,
    BaseBtnNextStep,
    BaseBtnPreStep,
    BaseInput
  },
};
</script>
<style lang="scss" scoped>
  .serviceOptionsForm {
    padding: 12px 16px 16px 16px;
    width: 100vw;
    border-radius: 20px 20px 0 0;
    box-sizing: border-box;
    background-color: #ffffff;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12);

    &__stepOne,
    &__stepTwo,
    &__stepThree {
      margin-bottom: 20px;
    }

    &__extraService,
    &__carType,
    &__paymentOption,
    &__passengerOption {
      margin-top: 12px;
    }

    &__titleWrapper {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      margin-top: 12px;
    }

    &__text {
      margin: 0;
      font-size: 14px;
      height: 16px;
      line-height: 16px;
      text-align: center;
    }

    &__title {
      margin: 0;
      color: #767676;
      font-size: 12px;
      line-height: 16px;
    }

    &__importantTitleRight {
      margin: 0;
      color:  #767676;
      font-size: 16px;
      line-height: 16px;
      margin-bottom: 10px;
    }
    &__importantTitleLeft {
      margin: 0;
      color:  #4F4F4F;
      margin-bottom: 10px;
      font-family: Arial;
      font-size: 16px;
      font-weight: 700;
    }

    &__penIcon {
      margin-left: 5px;
      transform: scale(1.2);
    }

    &__angleDownIcon {
      text-align: center;
    }

    &__showCarTypes {
      margin-top: 8px;
      margin-bottom: 4px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__btn {
      display: flex;
      padding: 0 15px;

      &__preStep {
        margin-right: 20px;
      }

      &--reservation {
        margin-top: 16px;
      }
    }
  }
</style>
