<template lang="pug">
button.lastPage(
  v-if="lastPageBtnIsShow"
  @click="$emit('click')"
)
  leftArrowIcon.lastPage__icon
</template>

<script>
import leftArrowIcon from '@/assets/images/arrow-back-24-px.svg';

export default {
  name: 'BaseBtnLastPage',
  components: {
    leftArrowIcon
  },
  props: {
    lastPageBtnIsShow: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .lastPage {
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.16);
    padding: 8px;

    &__icon {
      vertical-align: top;
    }
  }
</style>
