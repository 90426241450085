export default {
  getOrderID: state => state.orderID,
  getOrderTime: state => state.orderTime,
  getRideStatus: state => state.rideStatus,
  getPassengerName: state => state.passengerName,
  getOnLocation: state => state.onLocation,
  getOffLocation: state => state.offLocation,
  getDriverGPS: state => state.driverGPS,
  getDriverName: state => state.driverName,
  getDriverImg: state => state.driverImg,
  getDriverStar: state => state.driverStar,
  getDriverPhone: state => state.driverPhone,
  getDriverNote: state => state.driverNote,
  getCarLicense: state => state.carLicense,
  getCarBrand: state => state.carBrand,
  getCallNumber: state => state.callNumber,
  getTeamName: state => state.teamName,
  getTeamPhone: state => state.teamPhone,
  getEstimatedTime: state => state.estimatedTime
};
