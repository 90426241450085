import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const orderInfo = {
  namespaced: true,
  state: {
    orderID: '',
    driverGPS: null,
    passengerName: '',
    rideStatus: '',
    onLocation: '',
    offLocation: '',
    driverName: '',
    driverImg: '',
    driverStar: 0,
    driverPhone: "---------",
    driverNote: '',
    carLicense: "-------",
    carBrand: "----",
    callNumber: "---",
    teamName: "------",
    teamPhone: "--------",
    orderTime: "------",
    estimatedTime: "---"
  },
  getters,
  mutations,
  actions,
};

export default orderInfo;
