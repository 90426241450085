import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const billSigning = {
  namespaced: true,
  state: {
    enterpriseOptions: [],
    driverID: null,
    onLocation: '',
    offLocation: '',
    actualFare: 0,
    enterpriseID: 0,
    enterpriseName: '',
    employeeID: null,
    jobNumber: '',
    password: '',
    passengerNote: '',
    singingNote: '',
    employeeName: '',
    driverName: ''
  },
  getters,
  mutations,
  actions,
};

export default billSigning;