<template lang="pug">
.itineraryTable__table.itineraryTable__table--hailingInfo
  .itineraryTable__description
    p.itineraryTable__subtitle 叫車時間
    p.itineraryTable__text {{ dateString }} {{ timeString }}
  .itineraryTable__description
    p.itineraryTable__subtitle 行程狀態
    p.itineraryTable__text {{ rideStatus }}
  .itineraryTable__description
    p.itineraryTable__subtitle 上車地點
    p.itineraryTable__text {{ onLocationName }}
  .itineraryTable__description(
    v-if="offLocationName"
  )
    p.itineraryTable__subtitle 下車地點
    p.itineraryTable__text {{ offLocationName }}
</template>
<script>
import { getDateString, getTimeString } from '@/utils/timeUtils';

export default {
  props: {
    orderTime: {
      type: String,
      required: true,
    },
    rideStatus: {
      type: String,
      required: true,
    },
    onLocationName: {
      type: String,
      required: true,
    },
    offLocationName: {
      type: String
    },
  },
  computed: {
    dateString() {
      return getDateString(this.orderTime);
    },
    timeString() {
      return getTimeString(this.orderTime);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_itineraryTable';

</style>
