<template lang="pug">
.reservationAlertModal
  .reservationAlertModal__popup
    h3.reservationAlertModal__title 提醒您，您只能預約15分鐘後行程
    .reservationAlertModal__radioWrapper(
      @click="$emit('toggleCheckPreventAlert')"
    )
      RadioCheckedUI.reservationAlertModal__radio(
        :isChecked="isChecked"
      )
      p.reservationAlertModal__text 下次不再提醒
    .reservationAlertModal__btnWrapper
      BaseBtnConfirm(
        :text="'確定'"
        @click="$emit('alertConfirmed')"
      )
</template>
<script>
import RadioCheckedUI from '@/components/common/RadioCheckedUI';
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  props: {
    isChecked: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    BaseBtnConfirm,
    RadioCheckedUI,
  },
};
</script>
<style lang="scss" scoped>
  .reservationAlertModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    &__popup {
      width: 296px;
      height: 208px;
      border-radius: 20px;
      background-color: #ffffff;
      padding-top: 24px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    &__radioWrapper {
      margin-top: 8px;
      padding: 0 8px;
      height: 56px;
      display: flex;
      align-items: center;
    }

    &__radio {
      margin-left: 26px;
    }

    &__title {
      margin: 0;
      padding: 0 20px;
      line-height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
    }

    &__text {
      font-size: 14px;
      margin-left: 24px;
    }
    
    &__btnWrapper {
      margin-top: 8px;
      padding: 0 16px;
    }
  }
</style>
