import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const autoComplete = {
  namespaced: true,
  state: {
    currentInput: 'pickUp',
    predictLocations: [],
  },
  getters,
  mutations,
  actions,
};

export default autoComplete;
