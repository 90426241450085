<template lang="pug">
ul.locationList
  ListItemLocation(
    v-for="location in locationItems"
    :key="location.place_id"
    :title="location.title"
    :text="location.text"
    @click="selectLocation(location.place_id)"
  )
</template>
<script>
import ListItemLocation from '@/components/common/ListItemLocation';

export default {
  props: {
    locationItems: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  methods: {
    selectLocation(place_id) {
      this.$emit('locationSelected', place_id);
    },
  },
  components: {
    ListItemLocation,
  },
};
</script>
<style lang="scss" scoped>
  .locationList {
    margin: 0;
    padding: 0;
  }
</style>;
