<template lang="pug">
.hailingHistoryDetail
  PageHeader(
    :title="'行程詳細資訊'"
    @toLastPage="toLastPage"
  )
  HistoryDetailTable.hailingHistoryDetail__table(
    :tripRecord="tripRecord"
  )
</template>
<script>
import PageHeader from '@/components/common/PageHeader';
import HistoryDetailTable from '@/components/HistoryDetailTable';
import { getTripHistoryDetail } from '@/api/tripHistory';
export default {
  data(){
    return {
      tripRecord:{
        orderTime: null,
        rideStatus: null,
        price: null,
        teamInfo: {
          teamName: null,
          teamPhone: null
        },
        offLocation: null,
        driverInfo: {
          callNumber: null,
          carBrand: null,
          carLicense: null,
          driverName: null,
          driverNote: null,
          driverPhone: null,
          driverStar: null,
          availableSeat: null
        },
        onLocation: null,
        orderID: null
      }
    }
  },
  methods: {
    toLastPage() {
      this.$router.back();
    },
  },
  async created() {
    try {
      const { data } = await getTripHistoryDetail(this.$route.query.id);
      //console.log(data)
      this.tripRecord = data.result
    } catch (err) {
      console.error(err);
    }
  },
  components: {
    PageHeader,
    HistoryDetailTable,
  },
};
</script>
<style lang="scss" scoped>
  .hailingHistoryDetail {
    width: 100vw;
    height: 100vh;

    &__table {
      margin-top: 24px;
    }
  }
</style>
