<template lang="pug">
BaseModal.successModal(
  :title="title"
  :text="text"
  @confirm="$emit('confirm')"
) 
  template(v-slot:icon v-if="icon")
    div.successModal__icon
      successIcon
  template(v-slot:icon v-else)
    div.successModal__icon
      failureIcon
</template>
<script>
import BaseModal from '@/components/BaseComponent/BaseModal';
import successIcon from '@/assets/images/success.svg';
import failureIcon from '@/assets/images/failure.svg';

export default {
  name: 'SucessModal',
  components: {
    BaseModal,
    successIcon,
    failureIcon
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: Boolean,
      default: false,
    }
  }
};
</script>
<style lang="scss" scoped>
  .successModal {

    &__icon {
      margin-bottom: 16px;
      text-align: center;
    }
  }
</style>
