<template lang="pug">
.baseConfirmModal
  .baseConfirmModal__popup
    h3.baseConfirmModal__title(
      v-if="title !== ''"
    ) {{ title }}
    slot(name="content")
    .baseConfirmModal__btnGroup
      BaseBtnConfirm.baseConfirmModal__btn.baseConfirmModal__btn--cancel(
        :text="cancelBtnText"
        @click="$emit('cancel')"
      )
      BaseBtnConfirm.baseConfirmModal__btn(
        :text="confirmBtnText"
        @click="$emit('confirm')"
      )
</template>
<script>
import BaseBtnConfirm from '@/components/BaseComponent/BaseBtnConfirm';

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    cancelBtnText: {
      type: String,
      required: true,
    },
    confirmBtnText: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseBtnConfirm,
  },
};
</script>
<style lang="scss" scoped>
  .baseConfirmModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;

    &__popup {
      width: 296px;
      border-radius: 20px;
      background-color: #ffffff;
      padding-top: 24px;
      padding-bottom: 8px;
      box-sizing: border-box;
    }

    &__title {
      height: 28px;
      margin: 0;
      padding-bottom: 24px;
      line-height: 28px;
      font-size: 20px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
    }

    &__btnGroup {
      padding: 0 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__btn {
      width: 124px;

      &--cancel {
        background-color: #b7b7b7;
      }
    }
  }
</style>
