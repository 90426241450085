<template lang="pug">
.iconStarWithValue
  starIcon.iconStarWithValue__icon
  span.iconStarWithValue__value {{ value }}
</template>

<script>
import starIcon from '@/assets/images/Star.svg';

export default {
  name: 'BaseIconStarWithValue',
  components: {
    starIcon
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
  .iconStarWithValue {
    text-align: center;
    font-size: 12px;
    color: #fff;
    &__icon {
      vertical-align: top;
    }
    &__value {
      position: absolute;
      top: 8px;
      left: 14px;
    }
  }
</style>
