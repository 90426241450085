<template lang="pug">
.hailingStatusModal__detailTable
  .hailingStatusModal__detailItem
    p.hailingStatusModal__item(v-if="reservationTime !== null") 預約時間
    p.hailingStatusModal__value {{getTime()}}
  .hailingStatusModal__detailItem
    p.hailingStatusModal__item 車型
    p.hailingStatusModal__value {{ taxiType }}
  .hailingStatusModal__detailItem(
    v-if="price !== '無下車地點'"
  )
    p.hailingStatusModal__item 預估車資
    p.hailingStatusModal__value 跳錶為主
  .hailingStatusModal__detailItem
    p.hailingStatusModal__item 付費方式
    p.hailingStatusModal__value {{ paymentName }}
  .hailingStatusModal__detailItem(
    v-if="extraServicesRequired"
  )
    p.hailingStatusModal__item 偏好服務
    .hailingStatusModal__service
      p.hailingStatusModal__value.hailingStatusModal__value--service(
        v-for="service in extraServicesNames"
      ) {{ service }}
  .hailingStatusModal__detailItem
    p.hailingStatusModal__item 上車地點
    p.hailingStatusModal__value {{ pickUpName }}
  .hailingStatusModal__detailItem(
    v-if="dropOffName !== ''"
  )
    p.hailingStatusModal__item 下車地點
    p.hailingStatusModal__value {{ dropOffName }}
  .hailingStatusModal__detailItem(
    v-if="dropOffName !== ''"
  )
    p.hailingStatusModal__item 乘客備註
    p.hailingStatusModal__value {{ note }}
</template>
<script>
import { getExtraServiceName, getPaymentName } from '@/utils/hailingInfoUtils';
import {getExtraServiceOptions} from '../../api/hailingService.js' 
import moment from 'moment'

export default {
  props: {
    pickUpName: {
      type: String,
      required: true,
    },
    dropOffName: {
      type: String,
      required: true,
    },
    payment: {
      type: Number,
      required: true,
    },
    extraServices: {
      type: Array,
      required: true,
    },
    taxiType: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      /* required: true, */
    },
    note: {
      type: String,
      required: true
    },
    reservationTime: {
      type: String,
      /* required: true */
    }
  },
  data(){
    return {
      extraServicesNames:null
    }
  },
  methods:{
    getTime(){
      if(this.reservationTime === null) return ''
      //console.log(this.reservationTime)
      return moment(this.reservationTime).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  mounted() {
    getExtraServiceOptions()
      .then(res => res.data.result)
      .then(res => {
        this.extraServicesNames = this.extraServices.map(serviceId => {
          return res.filter(option => option.id === serviceId).pop().name
        })  
      })
  },
  computed: {
    extraServicesRequired() {
      return this.extraServices.length !== 0;
    },
    paymentName() {
      return getPaymentName(this.payment);
    }
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/_hailingStatusModal';

</style>
