<template lang="pug">
.paymentServiceSelector
  p.paymentServiceSelector__title {{ title }}
  .paymentServiceSelector__list
    .paymentServiceSelector__item(
      v-for="(item, index) in options"
      :key="index"
      :class="{'paymentServiceSelector__item--selected': payment === item.value}"
      @click="optionSelectedHandler(item.value)"
    ) {{ item.description }}
</template>
<script>
import { paymentOptions } from '@/constant/optionConstants';

export default {
  props: {
    payment: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      title: '支付方式',
      options: [
        ...paymentOptions,
      ],
    };
  },
  methods: {
    optionSelectedHandler(value) {
      this.$emit('paymentChanged', value);
    },
  }
};
</script>

<style lang="scss" scoped>
  .paymentServiceSelector {
    width: calc(100vw - 30px);
    overflow: hidden;

    &__title {
      margin: 0;
      color: #767676;
      font-size: 12px;
    }

    &__list {
      margin-top: 4px;
      -ms-overflow-style: none;
      width: calc(100vw - 30px);
      list-style: none;
      display: inline-flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    
    &__item {
      margin-left: 8px;
      display: inline-block;
      width: 75px;
      height: 36px;
      line-height: 36px;
      border-radius: 18px;
      border: solid 2px #efeeea;
      text-align: center;
      flex-shrink: 0;
      font-size: 14px;

      &:first-child {
        margin-left: 0;
      }

      &--selected {
        color: #ff9900;
        border: solid 2px #ff9900;
      }
    }
  }
</style>
